import React from "react";
import { Modal, Button, message } from "antd";
import { BiSolidCopy } from "react-icons/bi";

function TransactionDetailsModal({ isVisible, onClose, withdrawResponse }) {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    message.success("Copied to clipboard!");
  };

  return (
    <Modal
      title="Transaction Details"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
        <Button key="ok" type="primary" onClick={onClose}>
          OK
        </Button>,
      ]}
      width={600} // Adjust the modal width if necessary
      className="custom-modal"
    >
      <div className="bg-white shadow-md rounded-lg p-6 space-y-4">
        {withdrawResponse === "Your request is being processed!" ? (
          <div className="text-center text-gray-500">{withdrawResponse}</div>
        ) : withdrawResponse ? (
          <>
            {Object.entries(withdrawResponse).map(([key, value]) => (
              <div
                key={key}
                className="flex justify-between items-start border-b pb-3 last:border-none last:pb-0"
              >
                <span className="font-semibold text-gray-600 capitalize text-sm">
                  {key.replace(/_/g, " ")}:
                </span>
                <div className="flex items-center space-x-2 text-sm">
                  <span
                    className="text-gray-800 font-medium break-all"
                    style={{ wordBreak: "break-word" }}
                  >
                    {value}
                  </span>
                  {key === "transaction_id" && (
                    <BiSolidCopy
                      className="text-gray-500 cursor-pointer"
                      onClick={() => handleCopy(value)}
                    />
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="text-center text-gray-500">
            No transaction details available.
          </div>
        )}
      </div>
    </Modal>
  );
}

export default TransactionDetailsModal;
