import RepositoryFactory from "repository/RepositoryFactory";
import {
    FETCH_LG_CURRENT_ORDER_REQUEST,
    FETCH_LG_CURRENT_ORDER_SUCCESS,
    FETCH_LG_ORDER_HISTORY_REQUEST,
    FETCH_PROJECT_REQUEST,
    FETCH_PROJECT_SUCCESS,
    FETCH_LG_CURRENT_ORDER_FAILURE,
    FETCH_PROJECT_FAILURE,
} from "../types";
const lgRepository = RepositoryFactory.get("laungeGuard");

export const fetchCurrentOrders = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_LG_CURRENT_ORDER_REQUEST });
    try {
        const response = await lgRepository.currentOrder(userId, token);
        const lgCurrentOrder = response.data.data;
        if (response.data.status === 1) {
            dispatch({
                type: FETCH_LG_CURRENT_ORDER_SUCCESS,
                payload: lgCurrentOrder,
            });
        } else {
            dispatch({
                type: FETCH_LG_CURRENT_ORDER_FAILURE,
                payload: lgCurrentOrder,
            });
        }
    } catch (error) {
        dispatch({
            type: FETCH_LG_CURRENT_ORDER_FAILURE,
            payload: error.message,
        });
    }
};

export const fetchOrderHistory = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_LG_ORDER_HISTORY_REQUEST });
    try {
        const response = await lgRepository.orderHistory(userId, token);
        const lgOrderHistory = response.data.data;
        if (response.data.status === 1) {
            dispatch({
                type: FETCH_LG_CURRENT_ORDER_SUCCESS,
                payload: lgOrderHistory,
            });
        } else {
            dispatch({
                type: FETCH_LG_CURRENT_ORDER_FAILURE,
                payload: lgOrderHistory,
            });
        }
    } catch (error) {
        dispatch({
            type: FETCH_LG_CURRENT_ORDER_FAILURE,
            payload: error.message,
        });
    }
};

export const fetchLgProjects = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_PROJECT_REQUEST });
    try {
        const response = await lgRepository.lgProjects(userId, token);
        const lgProjects = response.data.data;
        console.log("lgProjects", lgProjects);
        if (response.data.status === 1) {
            dispatch({
                type: FETCH_PROJECT_SUCCESS,
                payload: lgProjects,
            });
        } else {
            dispatch({
                type: FETCH_PROJECT_SUCCESS,
                payload: lgProjects,
            });
        }
    } catch (error) {
        dispatch({
            type: FETCH_PROJECT_FAILURE,
            payload: error.message,
        });
    }
};

