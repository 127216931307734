import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TbFileTime, TbCirclesRelation } from "react-icons/tb";
import { Spinner } from 'reactstrap';
import { FaCaretDown, FaCaretUp } from "react-icons/fa6";

import CryptoConvertForm from 'components/cryptoConvertComponents/cryptoConvertForm';
import FaQs from 'components/FAQs';
import { doTrade, fetchCoinBalance,getConvertedAmount } from 'store/actions/cryptoConvertAction';
import { fetchAssetsData } from "store/actions/AssetsUserAction";

import coin_1 from 'assets/img/any-pair-convert.svg';
import coin_2 from 'assets/img/gurranted.svg';
import coin_3 from 'assets/img/instant-price.svg';
import debounce from "lodash.debounce";
import { fetchBalanceTypes } from 'store/actions/convertbalanceActions';
import WithdrawalRoutingNavbar from 'components/withdrawalRoutingNavbar';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';
import { IoInformationCircleSharp } from 'react-icons/io5';

const CryptoConvert = () => {
    const [selectedCoinFrom, setSelectedCoinFrom] = useState(null);
    const [selectedCoinType, setSelectedCoinType] = useState(null);
    const [selectedCoinTo, setSelectedCoinTo] = useState(null);
    const [valueFrom, setValueFrom] = useState("");
    const [valueTo, setValueTo] = useState("");
    const [selectedCoinToOptions, setSelectedCoinToOptions] = useState([]);
    const [selectedCoinFromOptions, setSelectedCoinFromOptions] = useState([]);
    const [currencyConversionRates, setCurrencyConversionRates] = useState({});
  const [showModal, setShowModal] = useState(false);
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;
    const dispatch = useDispatch();
    const history = useHistory();

    const { balanceTypes, loading, error } = useSelector((state) => state.balance);
    const { assetsData, loading: assetsLoading } = useSelector((state) => state.assetsData || {});

    const IMAGE_BASE_URL = "https://api.buycex.com/Upload/coin/";
    const handleValueChangeFrom = (e) => {
        setValueFrom(e.target.value);
    };
    
    // Fetch balance types for the user
    useEffect(() => {
        dispatch(fetchBalanceTypes(token, userId));
    }, [dispatch, token, userId]);
    
    // Fetch assets data
    useEffect(() => {
        if (!userId || !token) {
            history.push('/crypto-convert');
            return;
        }
        dispatch(fetchAssetsData(userId, token, "fund"));
    }, [dispatch, userId, token, history]);



   

    useEffect(() => {
        const fetchConversionRates = async () => {
            // Prepare the payload for fetching converted amount
            const payload = {
                from_coin: selectedCoinFrom?.value.toLowerCase() || '',
                to_coin: selectedCoinTo?.value.toLowerCase() || '',
                amount: valueFrom || 1, // Default to 1 for conversion rate
                user_id: userId,
            };
            console.log("Payload for API request: ", payload);
    
            // Check if valid coins are selected before dispatching
            if (payload.from_coin && payload.to_coin) {
                console.log("payload.from_coin e: ", payload.from_coin );
                try {
                    // Dispatch the action and wait for the response
                    const response = await dispatch(getConvertedAmount(payload, token));
                    console.log("API Response: ", response);
    
                    // Process the response and update state
                    if (response?.status === 1 && response?.data?.converted_amount) {
                        setCurrencyConversionRates(response.data);
                        setValueTo(response?.data?.converted_amount);
                        console.log("Updated currencyConversionRates: ", currencyConversionRates);
                    } else {
                        console.warn("Unexpected response format or status: ", response);
                       
                    }
                } catch (error) {
                    console.error("Error fetching conversion rates: ", error);
                    setCurrencyConversionRates("")
                }
            }
        };
    
        const debouncedFetch = debounce(fetchConversionRates, 500);

    debouncedFetch();

    return () => {
        debouncedFetch.cancel(); // Cleanup debounce on unmount
    };
    }, [dispatch, selectedCoinFrom, selectedCoinTo, valueFrom, userId, token]);
    
    
    

    // Handle assets data and prepare coin options
    useEffect(() => {
        if (assetsData?.cryptoList && assetsData?.fiatList) {
            const fromCoinsData = [];
            const toCoinsData = [];

            // Process cryptoList
            Object.keys(assetsData.cryptoList).forEach((coinKey) => {
                const coin = assetsData.cryptoList[coinKey];
                fromCoinsData.push({
                    value: coinKey.toUpperCase(),
                    ticker: coinKey.toUpperCase(),
                    label: coin.title,
                    icon: `${IMAGE_BASE_URL}${coin.img}`
                });
                toCoinsData.push({
                    value: coinKey.toUpperCase(),
                    ticker: coinKey.toUpperCase(),
                    label: coin.title,
                    icon: `${IMAGE_BASE_URL}${coin.img}`
                });
            });

            // Process fiatList
            Object.keys(assetsData.fiatList).forEach((fiatKey) => {
                const fiat = assetsData.fiatList[fiatKey];
                fromCoinsData.push({
                    value: fiatKey.toUpperCase(),
                    ticker: fiatKey.toUpperCase(),
                    label: fiat.title,
                    icon: `${IMAGE_BASE_URL}${fiat.img}`
                });
                toCoinsData.push({
                    value: fiatKey.toUpperCase(),
                    ticker: fiatKey.toUpperCase(),
                    label: fiat.title,
                    icon: `${IMAGE_BASE_URL}${fiat.img}`
                });
            });

            setSelectedCoinFrom(fromCoinsData[0] || null);
            setSelectedCoinTo(toCoinsData[1] || null);
            setSelectedCoinFromOptions(fromCoinsData);
            setSelectedCoinToOptions(toCoinsData);
        }
    }, [assetsData]);



    // Reset input fields when selectedCoinFrom or selectedCoinTo changes
    useEffect(() => {
        setValueFrom("");
        setValueTo("");
    }, [selectedCoinFrom, selectedCoinTo]);

    // Fetch balance for selectedCoinFrom
    useEffect(() => {
        if (selectedCoinFrom) {
            dispatch(fetchCoinBalance({ user_id: userId, token: token, coinname: selectedCoinFrom.value.toLowerCase() }));
        }
    }, [selectedCoinFrom, userId, token, dispatch]);

    // Fetch balance for selectedCoinTo
    useEffect(() => {
        if (selectedCoinTo) {
            dispatch(fetchCoinBalance({ user_id: userId, token: token, coinname: selectedCoinTo.value.toLowerCase() }));
        }
    }, [selectedCoinTo, userId, token, dispatch]);

    const handleSwap = () => {
        setSelectedCoinFrom(selectedCoinTo);
        setSelectedCoinTo(selectedCoinFrom);
        setSelectedCoinFromOptions(selectedCoinToOptions);
        setSelectedCoinToOptions(selectedCoinFromOptions);
        // Reset input fields after swap
        setValueFrom("");
        setValueTo("");
    };

    const handleConvert = (e) => {
        e.preventDefault();
        if (!selectedCoinFrom || !selectedCoinTo) return;
        const payload = {
            user_id: userId,
            from_coin: selectedCoinFrom.value.toLowerCase(),
            to_coin: selectedCoinTo.value.toLowerCase(),
            amount: valueFrom,
        };
        dispatch(doTrade(payload, token,setShowModal));
        setCurrencyConversionRates("");
    };

    const getConversionRateText = () => {
        if (!selectedCoinFrom || !selectedCoinTo) return "--";
    
        const rate = currencyConversionRates?.from_coin_price;
        console.log("rate", rate)
        // Ensure rate is a valid number before calling toFixed
        const validRate = parseFloat(rate); // Convert to number
        if (!isNaN(validRate)) {
            return `1 ${selectedCoinFrom.value} ≈ ${validRate.toFixed(6)} ${selectedCoinTo.value}`;
        }
    
        return "--";
    };
    

  

    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState({ key: '', label: '' });

    const handleSelect = (value, label) => {
        setSelected({ value, label });
        setSelectedCoinType({ value, label });
        setIsOpen(false);
    };
    return (
        <div>
            <div className='mt-6'>
                <WithdrawalRoutingNavbar />
            </div>
            <section className="relative sm:px-8 px-4 items-center justify-center w-[100%]">
                <div className="relative top-[2.25rem] mt-3 sm:mt-0 z-10 flex justify-end text-[#f4f4f4] w-full gap-8 font-medium">
                    <button className='bg-[#EFB81C] hidden px-3 py-2 rounded-lg sm:inline-flex items-center text-sm'>
                        <TbFileTime className='mr-1' />Conversion history
                    </button>

                </div>
                <div className="relative z-10 text-center text-[#f4f4f4] dark:text-[#151415]">
                    <p className="sm:text-5xl font-semibold uppercase text-[#EFB81C] text-2xl text-ellipsis whitespace-nowrap">Convert</p>
                    <p className="text-xs text-gray-500 sm:text-[14px] font-medium pt-1 pb-8">
                        Instant Convert Between Crypto Pair
                    </p>
                </div>

                {/* {!(assetsLoading || (!selectedCoinFrom || !selectedCoinTo)) ? ( */}
                {/* <div className="relative z-10 bg-neutral-50 dark:bg-zinc-900 p-4 flex items-center justify-center rounded-3xl shadow-lg lg:w-2/5"> */}
                {/* <Spinner color="primary" /> */}
                {/* </div> */}
                {/* ) : ( */}
                <div className="relative z-10 dark:bg-zinc-900 py-6 px-4 sm:px-3 rounded-2xl mx-auto border-2 w-full sm:w-2/6 min-w-full sm:min-w-[400px]">
                    <div className="flex justify-start">
                    </div>
                    <div className="flex justify-between items-center w-full mb-4">
                        <div className="flex items-center gap-1">
                            <p className="text-sm text-gray-400 font-semibold">Wallet</p>
                            <IoInformationCircleSharp className="text-gray-400" />
                        </div>
                        <div className="flex items-center justify-end w-full sm:w-full p-2 rounded">
                            {loading ? (
                                <div className="text-xs text-gray-500">Loading...</div>
                            ) : error ? (
                                <div className="text-xs text-red-500">Error: {error}</div>
                            ) : (
                                ["Spot", "Funding"].map((option) => (
                                    <div
                                        key={option}
                                        className="flex font-medium items-center cursor-pointer mx-2"
                                        onClick={() =>
                                            handleSelect(option.toLowerCase(), option)
                                        }
                                    >
                                        {/* Checkbox icon toggles based on selection */}
                                        {selected.value === option.toLowerCase() ? (
                                            <FaCheckSquare className="text-[#EFB81C]" size={16} /> // Filled Checkbox Icon
                                        ) : (
                                            <FaRegSquare className="text-gray-500" size={16} /> // Empty Checkbox Icon
                                        )}
                                        <span className="ml-1 text-xs text-neutral-900 dark:text-white">
                                            {option}
                                        </span>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>


                    <CryptoConvertForm
                        selectedCoinFrom={selectedCoinFrom}
                        selectedCoinFromOptions={selectedCoinFromOptions}
                        selectedCoinTo={selectedCoinTo}
                        selectedCoinToOptions={selectedCoinToOptions}
                        handleConvert={handleConvert}
                        valueFrom={valueFrom}
                        setValueFrom={setValueFrom}
                        valueTo={valueTo}
                        handleValueChangeFrom={handleValueChangeFrom}
                        handleSwap={handleSwap}
                        getConversionRateText={getConversionRateText}
                        excludeValuesTo={[selectedCoinTo?.value]}
                        excludeValuesFrom={[selectedCoinFrom?.value]}
                        setSelectedCoinFrom={setSelectedCoinFrom}
                        setSelectedCoinTo={setSelectedCoinTo}
                        currencyConversionRates={currencyConversionRates}
                        showModal={showModal}
                        setShowModal={setShowModal}
                    />
                </div>
                {/* )} */}
                <div className=''>
                    <div className='flex justify-center w-full sm:w-[400px] mx-auto'>
                        <div className='flex mt-[40px] w-full justify-between items-center'>
                            <div className='flex flex-col gap-2 items-center'>
                                <img src={coin_3} alt='small_coin_1' className='w-[48px] h-[48px]' />
                                <p className='text-xs sm:text-sm text-ellipsis whitespace-nowrap'>Instant Price</p>
                            </div>
                            <div className='flex flex-col gap-2 items-center'>
                                <img src={coin_2} alt='small_coin_2' className='w-[48px] h-[48px]' />
                                <p className='text-xs sm:text-sm text-ellipsis whitespace-nowrap'>Guaranteed Price</p>
                            </div>
                            <div className='flex flex-col gap-2 items-center'>
                                <img src={coin_1} alt='small_coin_2' className='w-[48px] h-[48px]' />
                                <p className='text-xs sm:text-sm text-ellipsis whitespace-nowrap'>Any Pair</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='container'>
                <FaQs />
            </section>
        </div>
    );
}

export default CryptoConvert;
