import React from 'react'
import TotalValue from './TotalValue'
import MainTable from './MainTable'

function BcxTokenEarn() {
    return (
        <div className='max-w-[1400px] mx-auto !p-0'>
            <div className='px-0 lg:px-3'>
                <TotalValue />
                <button
                    className="relative py-2 text-ellipsis whitespace-nowrap text-md font-semibold my-3"
                >
                    Your BCX Token
                    <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                </button>
                <MainTable/>
            </div>
        </div>
    )
}

export default BcxTokenEarn