import React, { useState, useEffect, useRef } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const CoinSelect = ({ coins, selectedCrypto, setSelectedCrypto, handleImageClick }) => {
    const [loading, setLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredCoins, setFilteredCoins] = useState(coins);
    const dropdownRef = useRef(null);

    useEffect(() => {
        // Simulate API call
        setTimeout(() => {
            setLoading(false);
        }, 800);
    }, []);

    useEffect(() => {
        // Filter coins based on the search query
        const filtered = coins.filter((coin) =>
            coin.label.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredCoins(filtered);
    }, [searchQuery, coins]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSelect = (coin) => {
        setSelectedCrypto(coin);
        setIsDropdownOpen(false); // Close the dropdown after selection
        setSearchQuery(""); // Reset search query
    };

    return (
        <>
            <label className="block mb-4 text-[20px] font-medium text-gray-900 dark:text-white">
                Select Coin
            </label>
            <div className="relative" ref={dropdownRef}>
                {loading ? (
                    <div className="animate-pulse">
                        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
                    </div>
                ) : (
                    <div className="relative">
                        <div
                            className="flex items-center justify-between border border-black hover:ring-1 ring-[#EFB81C] hover:border-[#EFB81C] rounded-lg px-4 py-4 cursor-pointer bg-white dark:bg-gray-800 dark:text-white"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        >
                            <div className="flex items-center">
                                {selectedCrypto?.icon && (
                                    <img
                                        src={selectedCrypto.icon}
                                        alt={`${selectedCrypto.name}-coin`}
                                        className="w-6 h-6 mr-2"
                                    />
                                )}
                                <span className="text-sm">
                                    {selectedCrypto?.label || "Select Crypto"}
                                </span>
                            </div>
                            <span className="text-sm">
                                {isDropdownOpen ? <FaAngleUp /> : <FaAngleDown />}
                            </span>
                        </div>

                        {isDropdownOpen && (
                            <div className="absolute mt-2 w-full bg-white border rounded shadow-lg dark:bg-gray-800 dark:text-white z-10">
                                <div className="p-2">
                                    <input
                                        type="text"
                                        placeholder="Search coins"
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#EFB81C] dark:bg-gray-700 dark:text-white"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                                <ul className="max-h-64 overflow-auto">
                                    {filteredCoins.length > 0 ? (
                                        [...filteredCoins]
                                            .sort((a, b) => a.label.localeCompare(b.label)) // Sort alphabetically
                                            .map((coin) => (
                                                <li
                                                    key={coin.value}
                                                    className="flex gap-1 items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                                                    onClick={() => handleSelect(coin)}
                                                >
                                                    <img
                                                        src={coin.icon}
                                                        alt={`${coin.label}-coin`}
                                                        className="w-6 h-6 mr-2"
                                                    />
                                                    <span className="text-sm">{coin.label}</span>
                                                </li>
                                            ))
                                    ) : (
                                        <li className="px-4 py-2 text-center text-gray-500 dark:text-gray-400">
                                            No results found
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}

                    </div>
                )}
            </div>

            <div className="flex gap-2 mt-4 flex-wrap">
    {loading
        ? Array(5)
            .fill()
            .map((_, index) => (
                <div
                    key={index}
                    className="flex items-center rounded border-2 border-slate-200 dark:text-white dark:border-1 pl-1 pr-1 gap-1 cursor-pointer animate-pulse"
                >
                    <div
                        className="bg-gray-200 p-1 w-7"
                        style={{ width: "27px", height: "27px" }}
                    ></div>
                    <div className="bg-gray-200 h-5 w-10"></div>
                </div>
            ))
        : coins
              .filter((coin) =>
                  ['usdt', 'cvm', 'bnb', 'btc', 'eth', 'matic'].includes(
                      coin.value.toLowerCase()
                  )
              )
              .map((coin, index) => (
                  <div
                      key={index}
                      className="flex items-center rounded border border-slate-200 dark:text-white pl-2 pr-2 gap-1 cursor-pointer"
                      onClick={() => handleImageClick(coin)}
                  >
                      <img
                          src={coin.icon}
                          alt={`${coin.value}-coin`}
                          className="p-1 w-7"
                          style={{ width: "27px" }}
                      />
                      {coin.value}
                  </div>
              ))}
</div>

        </>
    );
};

export default CoinSelect;
