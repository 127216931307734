// cryptoDepositActions.js
import { FETCH_CRYPTO_DEPOSIT_REQUEST, FETCH_CRYPTO_DEPOSIT_SUCCESS, FETCH_CRYPTO_DEPOSIT_FAILURE } from "../types";
import toast from "react-hot-toast";
import cryptoDepositRepository from "repository/cryptodepositRepository"; 

export const fetchCryptoDeposit = (selectedCrypto, token, userId) => async (dispatch) => {
    dispatch({ type: FETCH_CRYPTO_DEPOSIT_REQUEST });

    try {
        console.log("Fetching deposit for coin:", selectedCrypto);
        const response = await cryptoDepositRepository.fetchCryptoDeposit(selectedCrypto, token, userId); 


        if (response.status === 1) {
           
            dispatch({
                type: FETCH_CRYPTO_DEPOSIT_SUCCESS,
                payload: response.data, 
            });
            return response;
        } else {
         
            dispatch({
                type: FETCH_CRYPTO_DEPOSIT_FAILURE,
                payload: response,
            });
            return response;
        }
    } catch (error) {
        console.error("Error fetching crypto deposit:", error);
        toast.error("Failed to fetch crypto deposit. Please try again later.");
        dispatch({
            type: FETCH_CRYPTO_DEPOSIT_FAILURE,
            payload: error.message, 
        });
        throw error;
    }
};
