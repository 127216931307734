import UserProfileRepository from "repository/UserRepository";
import {
    FETCH_USER_PROFILE_FAILURE,
    FETCH_USER_PROFILE_REQUEST,
    FETCH_USER_PROFILE_SUCCESS,
    UPDATE_USER_NICK_NAME_SUCCESS,
    FETCH_USER_P2P_FAILURE,
} from "../types";

// actions/userProfileAction.js
export const fetchUserProfile = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_USER_PROFILE_REQUEST });
    try {
        const response = await UserProfileRepository.getUserInfo(userId, token);

        const userProfile = response.data.data[0];
        if (response.data.status === 1) {
            dispatch({
                type: FETCH_USER_PROFILE_SUCCESS,
                payload: userProfile,
            });
        } else {
            dispatch({
                type: FETCH_USER_PROFILE_FAILURE,
                payload: "Failed to fetch user profile",
            });
        }
    } catch (error) {
        dispatch({ type: FETCH_USER_PROFILE_FAILURE, payload: error.message });
    }
};

export const fetchUserP2PName = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_USER_PROFILE_REQUEST });
    try {
        const response = await UserProfileRepository.getUserP2PInfo(
            userId,
            token
        );

        const userP2P = response.data.data;

        console.log("userP2P response", userP2P);
        if (response.data.status === 1) {
            dispatch({
                type: UPDATE_USER_NICK_NAME_SUCCESS,
                payload: userP2P,
            });
        } else {
            dispatch({
                type: FETCH_USER_P2P_FAILURE,
                payload: "Failed to fetch P2P Name.",
            });
        }
    } catch (error) {
        dispatch({ type: FETCH_USER_PROFILE_FAILURE, payload: error.message });
    }
};

export const fetchUserDashboard = (userId, token) => async (dispatch) => {
    dispatch({ type: "FETCH_USER_DASHBOARD_REQUEST" });
    try {
        const response = await UserProfileRepository.getUserDashboard(
            userId,
            token
        );

        const userDashboard = response.data.data;
        console.log("respone", userDashboard);
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_USER_DASHBOARD_SUCCESS",
                payload: userDashboard,
            });
        } else {
            dispatch({
                type: "FETCH_USER_DASHBOARD_FAILURE",
                payload: "Failed to fetch user dashboard",
            });
        }
    } catch (error) {
        dispatch({
            type: "FETCH_USER_DASHBOARD_FAILURE",
            payload: error.message,
        });
    }
};
