import {
    WITHDRAW_REQUEST,
    WITHDRAW_SUCCESS,
    WITHDRAW_FAILURE,
    UPDATE_WALLET_REQUEST,
    UPDATE_WALLET_SUCCESS,
    UPDATE_WALLET_FAILURE,
    CRYPTO_WITHDRAW_REQUEST, 
    CRYPTO_WITHDRAW_SUCCESS, 
    CRYPTO_WITHDRAW_FAILURE,
    FETCH_WITHDRAWALS_REQUEST,
    FETCH_WITHDRAWALS_SUCCESS,
    FETCH_WITHDRAWALS_FAILURE,
} from "../types";
import toast from "react-hot-toast";

import cryptowithdrawRepository from "repository/cryptowithdrawRepository";

export const doWithdraw = (token, userId, withdrawalInputs) => async (dispatch) => {
    dispatch({ type: WITHDRAW_REQUEST }); 

    try {
        const response = await cryptowithdrawRepository.doWithdraw(
            withdrawalInputs,
            token,
            userId
        );

        if (response.data.status === 1) {
            toast.success(response.data.data); 
            dispatch({
                type: WITHDRAW_SUCCESS,
                payload: response.data, 
            });
            return response; 
        } else {
            toast.error(response.data.data); 
            dispatch({
                type: WITHDRAW_FAILURE,
                payload: response.data, 
            });
            return response; 
        }
    } catch (error) {
        console.error("Withdrawal API failed:", error);
        toast.error("Withdrawal failed. Please try again later."); 
        dispatch({
            type: WITHDRAW_FAILURE,
            error,
        });
        throw error;
    }
};
export const updateWallet = (token, userId, walletInputs) => async (dispatch) => {
    dispatch({ type: UPDATE_WALLET_REQUEST }); 

    try {
        const response = await walletRepository.upWallet(walletInputs, token, userId);

        if (response.data.status === 1) {
            toast.success(response.data.info); 
            dispatch({
                type: UPDATE_WALLET_SUCCESS,
                payload: response.data,
            });
            return response; 
        } else {
            toast.error(response.data.info);
            dispatch({
                type: UPDATE_WALLET_FAILURE,
                payload: response.data, 
            });
            return response; 
        }
    } catch (error) {
        console.error("Wallet update API failed:", error);
        toast.error("Wallet update failed. Please try again later."); 
        dispatch({
            type: UPDATE_WALLET_FAILURE,
            error,
        });
        throw error; 
    }
};

export const upCryptoWithdrawal = (token, userId, withdrawalInputs) => async (dispatch) => {
    dispatch({ type: CRYPTO_WITHDRAW_REQUEST });

    try {
        const response = await cryptowithdrawRepository.upCryptoWithdrawal(
            withdrawalInputs,
            token,
            userId
        );
        console.log("data response",response);
        if (response.data.status === 1) {
            toast.success(response.data.info);
            dispatch({
                type: CRYPTO_WITHDRAW_SUCCESS,
                payload: response.data,
            });
            return response;
        } else {
            toast.error(response.data.info);
            dispatch({
                type: CRYPTO_WITHDRAW_FAILURE,
                payload: response.data,
            });
            return response;
        }
    } catch (error) {
        console.error("Crypto Withdrawal API failed:", error);
        toast.error("Crypto withdrawal failed. Please try again later.");
        dispatch({
            type: CRYPTO_WITHDRAW_FAILURE,
            error,
        });
        throw error;
    }
};

export const fetchWithdrawals = (token, userId, coin) => async (dispatch) => {
    dispatch({ type: FETCH_WITHDRAWALS_REQUEST });

    try {
        console.log("coin",coin)
        const response = await cryptowithdrawRepository.fetchWithdrawals(coin, token, userId);
        console.log("Response data ", response)
        if (response.status === 1) {
        
            dispatch({
                type: FETCH_WITHDRAWALS_SUCCESS,
                payload: response.data,
            });
            return response;
        } else {
           
            dispatch({
                type: FETCH_WITHDRAWALS_FAILURE,
                payload: response,
            });
            return response;
        }
    } catch (error) {
        console.error("Fetching withdrawals failed:", error);
        toast.error("Failed to fetch withdrawals. Please try again later.");
        dispatch({
            type: FETCH_WITHDRAWALS_FAILURE,
            error,
        });
        throw error;
    }
};