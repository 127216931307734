import { toast } from "react-hot-toast";
import * as types from "../types";
import userUpdateRepository from "repository/userUpdateRespository";

export const updateUsername = (newUsername, token, id) => async (dispatch) => {
    dispatch({ type: types.UPDATE_USERNAME_REQUEST });

    try {
        const response = await userUpdateRepository.updateUsername(
            newUsername,
            token,
            id
        );

        if (response && response.status === 1) {
            dispatch({
                type: types.UPDATE_USERNAME_SUCCESS,
                payload: newUsername,
            });
            toast.success(response.data);
        } else {
            dispatch({
                type: types.UPDATE_USERNAME_FAILURE,
            });
            toast.error(response.data);
        }
    } catch (error) {
        dispatch({
            type: types.UPDATE_USERNAME_FAILURE,
            payload: error.message,
        });
        toast.error("An error occurred: " + error.message);
    }
};

export const updateUserNickName =
    (newUsername, token, id) => async (dispatch) => {
        dispatch({ type: types.UPDATE_USERNAME_REQUEST });

        try {
            const response = await userUpdateRepository.updateUserNickName(
                newUsername,
                token,
                id
            );

            if (response && response.status === 1) {
                dispatch({
                    type: types.UPDATE_USER_NICK_NAME_SUCCESS,
                    payload: newUsername,
                });
                toast.success(response.data);
            } else {
                dispatch({
                    type: types.UPDATE_USER_NICK_NAME_FAILURE,
                });
                toast.error(response.data);
            }
        } catch (error) {
            dispatch({
                type: types.UPDATE_USER_NICK_NAME_FAILURE,
                payload: error.message,
            });
            toast.error("An error occurred: " + error.message);
        }
    };

export const updaterCurrency = (currency) => async (dispatch) => {
    dispatch({
        type: types.UPDATE_USER_CURRENCY,
        payload: currency,
    });
};

export const updaterLanguage = (lang) => async (dispatch) => {
    dispatch({
        type: types.UPDATE_LANGUAGE,
        payload: lang,
    });
};
