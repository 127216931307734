import {
    WITHDRAW_REQUEST,
    WITHDRAW_SUCCESS,
    WITHDRAW_FAILURE,
    UPDATE_WALLET_REQUEST,
    UPDATE_WALLET_SUCCESS,
    UPDATE_WALLET_FAILURE,
    CRYPTO_WITHDRAW_REQUEST, 
    CRYPTO_WITHDRAW_SUCCESS, 
    CRYPTO_WITHDRAW_FAILURE,
    FETCH_WITHDRAWALS_REQUEST,
    FETCH_WITHDRAWALS_SUCCESS,
    FETCH_WITHDRAWALS_FAILURE,
} from "../types";

const initialState = {
    loading: false,
    data: null,
    error: null,
};

// Withdraw Reducer
export const withdrawReducer = (state = initialState, action) => {
    switch (action.type) {
        case WITHDRAW_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case WITHDRAW_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };

        case WITHDRAW_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error || action.payload,
            };

        default:
            return state;
    }
};

// Wallet Reducer
export const updatewalletReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_WALLET_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case UPDATE_WALLET_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };

        case UPDATE_WALLET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error || action.payload,
            };

        default:
            return state;
    }
};

export const cryptoWithdrawReducer = (state = initialState, action) => {
    switch (action.type) {
        case CRYPTO_WITHDRAW_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case CRYPTO_WITHDRAW_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };

        case CRYPTO_WITHDRAW_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error || action.payload,
            };

        default:
            return state;
    }
};

export const withdrawalHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WITHDRAWALS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_WITHDRAWALS_SUCCESS:
            return { ...state, loading: false, withdrawals: action.payload, error: null };
        case FETCH_WITHDRAWALS_FAILURE:
            return { ...state, loading: false, error: action.error, withdrawals: [] };
        default:
            return state;
    }
};
