import React, { useState } from 'react';
import { MdAccountBalanceWallet } from 'react-icons/md';
import EarnProduct from './earnProduct';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function Navbar() {
    const [activeComponent, setActiveComponent] = useState('earn');

    return (
        <>
            <div className="border-b bg-[#EFB81C] flex justify-between w-full h-10">
                <div className="container flex justify-between w-full">
                    <div>
                        <button
                            className={`relative text-sm h-9 font-semibold mr-7 ${
                                activeComponent === 'earn' ? 'text-gray-600' : 'text-gray-400'
                            }`}
                            onClick={() => setActiveComponent('earn')}
                        >
                            Earn Product
                            {activeComponent === 'earn' && (
                                <span className="absolute left-1/2 transform -translate-x-1/2 bottom-0 w-1/2 h-1 bg-white rounded-full" />
                            )}
                        </button>
                        <Link to="/user/earn/flexible-earn"> 
                        <button
                            className={`relative text-sm h-9 font-semibold text-gray-600`}
                            onClick={() => setActiveComponent('historical')}
                        >
                            My Subscriptions
                            {activeComponent === 'historical' && (
                                <span className="absolute left-1/2 transform -translate-x-1/2 bottom-0 w-1/2 h-1 bg-white rounded-full" />
                            )}
                        </button>
                        </Link>
                    </div>
                    <div className="flex gap-4">
                        <div
                            className="flex"
                        >
                            <button className="flex gap-1 font-medium items-center text-sm text-ellipsis whitespace-nowrap text-gray-500">
                                <MdAccountBalanceWallet size={18}/>
                                User Guide
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {activeComponent === 'earn' && <EarnProduct />}
        </>
    );
}

export default Navbar;
