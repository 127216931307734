import React, { useState, useEffect, useRef } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderHistory } from "store/actions/OrderUserAction";

function OrderHistory() {
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("All");

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);

    const orderHistory = useSelector(
        (state) => state.lgData?.orderHistory?.data
    );

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        dispatch(fetchOrderHistory(userID, userToken));
    }, [dispatch, userID, userToken]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const options = ["Order 1", "Order 2", "Order 3", "Order 4"];

    return (
        <>
            <div className="relative w-64 mt-5" ref={dropdownRef}>
                {/* Dropdown Header */}
                <p className="mb-1">Coin</p>
                <div
                    className="flex items-center justify-between border px-4 py-2 rounded-md cursor-pointer"
                    onClick={toggleDropdown}
                >
                    <p className="font-semibold text-gray-700">
                        {selectedOption}
                    </p>
                    <span>
                        {isDropdownOpen ? (
                            <IoChevronUp className="text-gray-500" size={16} />
                        ) : (
                            <IoChevronDown
                                className="text-gray-500"
                                size={16}
                            />
                        )}
                    </span>
                </div>

                {/* Dropdown Options */}
                {isDropdownOpen && (
                    <div className="absolute top-full mt-2 w-full bg-white border rounded-md shadow-lg z-10">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-gray-700"
                                onClick={() => handleOptionClick(option)}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="w-full mt-5">
                <div className="flex justify-between bg-gray-100 p-3">
                    <p className="flex-1 text-sm text-gray-500 text-start">
                        Coin
                    </p>
                    <p className="flex-1 text-sm text-gray-500 text-start">
                        Token Price
                    </p>
                    <p className="flex-1 text-sm text-gray-500 text-start">
                        Amount
                    </p>
                    <p className="flex-[2] text-sm text-gray-500 text-start">
                        Total Cost(USDT)
                    </p>
                    <p className="flex-1 text-sm text-gray-500 text-start">
                        Redeem Date
                    </p>
                    <p className="flex-1 text-sm text-gray-500 text-center">
                        Refund Last Date
                    </p>
                </div>
                <div className="h-96 overflow-y-auto">
                    {orderHistory && orderHistory.length > 0 ? (
                        orderHistory.map((item, index) => (
                            <div
                                key={index}
                                className="flex justify-between w-full border-b p-3"
                            >
                                <p className="flex-1 text-sm text-gray-700 text-start">
                                    {item.coin}
                                </p>
                                <p className="flex-1 text-sm text-gray-700 text-start">
                                    {item.tokenPrice}
                                </p>
                                <p className="flex-1 text-sm text-gray-700 text-start">
                                    {item.amount}
                                </p>
                                <p className="flex-[2] text-sm text-gray-700 text-start">
                                    {item.totalCost}
                                </p>
                                <p className="flex-1 text-sm text-gray-700 text-start">
                                    {item.redeemDate}
                                </p>
                                <p className="flex-1 text-sm text-gray-700 text-center">
                                    {item.refundLastDate}
                                </p>
                            </div>
                        ))
                    ) : (
                        <div className="w-full flex justify-center items-center h-96">
                            <p className="text-gray-500 text-sm font-medium">
                                No data
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default OrderHistory;
