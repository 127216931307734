import React from 'react';
import hero from '../../assets/img/derivates-hero.avif';

const Hero = () => {
  return (
   <>

     <section className="hero-section border-b relative "> {/* Added mt-12 for top margin */}
  <div className="bg-black pt-5 container flex items-center justify-between gap-8 w-full max-h-[80vh] m-auto max-md:flex-col">
    {/* Left Section */}
    <div className="hero-title-wrapper flex flex-col gap-4 w-[60%] mb-16">
      <h1 className="hero-title flex flex-col gap-2 text-[48px] font-[600] leading-tight">
        <span className="text-[48px] font-[900] text-[#EFB81C]"> Best-in-Class Crypto Derivatives for Everyone</span>
        <span className="text-[28px] text-gray-400"> Empowering Your Trading Journey with Advanced Tools</span>
            </h1>
    
 {/* Stats Section */} 
 <div className="stats-wrapper flex items-center text-[#EFB81C] rounded-lg space-x-8"> {/* Adjust the space-x value */}
  <div className="stat-item text-center">
    <p className="text-2xl font-bold">$15,221,842,050</p>
    <p className="text-gray-400">24H Trading Vol. (USD)</p>
  </div>
  <div className="stat-item text-center">
    <p className="text-2xl font-bold">55,114,803</p>
    <p className="text-gray-400">Number of Users Who Trust Us</p>
  </div>
 
</div>
      <div className="hero-btn-wrap flex items-center gap-4 mt-2 mb-12">
        <button className="bg-[#EFB81C] text-white py-1 px-4 rounded-md">
        Trade Now
        </button>
        <button className="border border-[#EFB81C] text-[#EFB81C] py-1 px-4 rounded-md">
        Demo Trading
        </button>
      </div>
    </div>

    {/* Right Section: Hero Image */}
       {/* Right Side Image */}
      

        <div className="hero-image-wrapper relative w-[40%] min-h-[400px] rounded-xl overflow-hidden">
     
    

     <img src={hero} alt="" className="w-full h-full" />
        
         </div>
  </div>
  <div className='h-[40px] z-50 bg-[#EFB81C]'>
  </div>
</section>

</>
  
  );
};

export default Hero;
