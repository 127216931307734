import React, { useEffect, useState } from "react";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaCircleNotch } from "react-icons/fa6";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ConvertBcxPopup from './ConvertBcxPopup';
import { useDispatch, useSelector } from "react-redux";
import { getLatestProfit } from "store/actions/latestProfitAction";

function TotalValue() {
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(false);
    const [activeButton, setActiveButton] = useState('Earning Item');
    const [activePopup, setActivePopup] = useState(false);

    const showPopup = () => {
        setActivePopup(true);
    }

    const handleOk = () => {
        setActivePopup(false);
    }
    const handleCancel = () => {
        setActivePopup(false);
    }

    const latestProfit = useSelector(
        (state) => state.latestProfit.latestProfit
    );

    useEffect(() => {
        dispatch(getLatestProfit());
    }, [dispatch]);

    const totalBalance = isVisible ? "0.000" : "****";

    const handleButtonClick = (buttonType) => {
        showPopup();
        setActiveButton(buttonType);
        // setConvertBCXModalVisible(true);
    };

    const getButtonClass = (buttonType) => {
        return buttonType === activeButton
            ? "bg-[#EFB81C] px-3 py-2 rounded-md text-sm font-semibold"
            : "border border px-3 py-2 rounded-md text-sm font-semibold";
    };

    const toggleVisibility = () => {
        setIsVisible((prev) => !prev);
    };

    return (
        <div className="mt-0 lg:mt-6 p-3 sm:p-6 rounded-2xl border">
            <div>
                <div className="flex flex-col md:flex-row justify-normal md:justify-between w-full">
                    <div>
                        <div className="flex items-center gap-1 w-full">
                            <div className="flex w-full justify-between">
                                <div className="flex items-center gap-1">
                                    <p className="text-[20px] font-semibold">
                                        Total Value
                                    </p>
                                    <button
                                        onClick={toggleVisibility}
                                        className="mt-1"
                                    >
                                        {isVisible ? (
                                            <FaRegEyeSlash className="text-xl" />
                                        ) : (
                                            <FaRegEye className="text-xl" />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-baseline gap-2">
                            <p className="text-[32px] font-semibold">
                                {isVisible ? totalBalance : "****"}
                            </p>
                            <span className="text-sm flex items-center gap-1 font-semibold">
                                USDT
                                <FaAngleDown className="text-[12px] opacity-40 mt-1" />
                            </span>
                            <p className="border px-3 py-1 rounded-md font-semibold text-sm flex gap-2 items-center">
                                <FaCircleNotch />
                                All Assets
                            </p>
                        </div>
                        <p>{isVisible ? `≈ ${totalBalance}` : "*****"}</p>
                    </div>
                    <div>
                        <button
                            onClick={() => handleButtonClick("Earning Item")}
                            className={getButtonClass("Earning Item")}
                        >
                            Convert To BCX
                        </button>
                        <Link to='/user/history/bcx-history'>
                            <button
                                onClick={() => handleButtonClick('History')}
                                className={`ml-4 ${getButtonClass('History')}`}
                            >
                                History
                            </button>
                        </Link>
                    </div>
                </div>
                <ConvertBcxPopup
                    activePopup={activePopup}
                    onOk={handleOk}
                    onCancel={handleCancel}
                />
                <div className='flex w-full justify-between flex-wrap'>
                    <div className='flex flex-col gap-1 mt-6'>
                        <div className='flex items-center gap-1 text-ellipsis whitespace-nowrap'><p className='text-sm'>Your Profits (USDT)</p> <MdKeyboardArrowRight className='text-[#707a8a]' /></div>
                        <p className='font-semibold text-lg'>{isVisible ? '0' : '****'}</p>
                        <p className='text-[#707a8a]'>{isVisible ? '≈ $0.00' : '*****'}</p>
                    </div>
                    <div className="flex flex-col gap-1 mt-6">
                        <div className="flex items-center gap-1 text-ellipsis whitespace-nowrap">
                            <p className="text-sm">
                                Last Day Exchange Profits (USDT)
                            </p>{" "}
                            <MdKeyboardArrowRight className="text-[#707a8a]" />
                        </div>
                        <p className="font-semibold text-lg">
                            {isVisible
                                ? latestProfit?.last_day_exchange_profit
                                : "****"}
                        </p>
                        <p className="text-[#707a8a]">
                            {isVisible
                                ? `≈ $${parseFloat(
                                      latestProfit?.last_day_exchange_profit
                                  ).toFixed(2)}`
                                : "*****"}
                        </p>
                    </div>
                    <div className="flex flex-col gap-1 mt-6">
                        <div className="flex items-center gap-1 text-ellipsis whitespace-nowrap">
                            <p className="text-sm">
                                30 Day Exchange Profits (USDT)
                            </p>{" "}
                            <MdKeyboardArrowRight className="text-[#707a8a]" />
                        </div>
                        <p className="font-semibold text-lg">
                            {isVisible
                                ? latestProfit?.thirty_day_exchange_profit
                                : "****"}
                        </p>

                        <p className="text-[#707a8a]">
                            {isVisible
                                ? `≈ $${parseFloat(
                                      latestProfit?.thirty_day_exchange_profit
                                  ).toFixed(2)}`
                                : "*****"}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TotalValue;
