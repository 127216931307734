import React, { useState } from 'react';
import { FaBitcoin } from 'react-icons/fa';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import SubscribePopup from './subscribePopup';

function EarnProduct() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedDurations, setSelectedDurations] = useState({}); // Track duration for each row
    const [openDropdownId, setOpenDropdownId] = useState(null); // Track which row's dropdown is open

    const showModal = () => {
        setIsPopupOpen(true);
    };

    const handleCancel = () => {
        setIsPopupOpen(false);
    };

    const handleOk = () => {
        setIsPopupOpen(false);
    };

    const toggleDropdown = (id) => {
        setOpenDropdownId((prevId) => (prevId === id ? null : id)); // Toggle dropdown for specific row
    };

    const handleDurationSelect = (id, duration) => {
        setSelectedDurations((prev) => ({ ...prev, [id]: duration })); // Set duration for specific row
        setOpenDropdownId(null); // Close dropdown after selection
    };

    const products = [
        {
            id: 1,
            icon: <FaBitcoin fill="#f6921a" size={20} />,
            name: 'BTC',
            rate: '12.16%',
            max: 'Max',
            type: 'Fixed',
        },
        {
            id: 2,
            icon: <RiMoneyDollarCircleFill fill="#2775ca" size={20} />,
            name: 'USDC',
            rate: '12.16%',
            max: 'Max',
            type: 'Fixed',
        },
        {
            id: 3,
            icon: <FaBitcoin fill="#ffbf00" size={20} />,
            name: 'BNB',
            rate: '12.16%',
            max: 'Max',
            type: 'Fixed',
        },
        {
            id: 4,
            icon: <RiMoneyDollarCircleFill fill="#31a4d5" size={20} />,
            name: 'USDT',
            rate: '12.16%',
            max: 'Max',
            type: 'Fixed',
        },
    ];

    return (
        <div className="my-16 container">
            <p className="text-3xl font-semibold">All products</p>
            <p className="font-medium text-[16px] py-4">Simple Earn</p>

            {products.map((product) => (
                <div
                    key={product.id}
                    className="flex justify-between w-full border-b mt-5 py-2"
                >
                    <div className="flex flex-1 gap-2 items-center">
                        {product.icon}
                        <p className="font-medium">{product.name}</p>
                    </div>
                    <div className="flex gap-2 flex-1 items-center">
                        <p className="text-[#30bd86] font-medium">{product.rate}</p>
                        <p className="font-medium text-gray-600">{product.max}</p>
                    </div>
                    <div className="flex gap-2 flex-1 items-center">
                        <p className="font-medium text-gray-600">{product.type}</p>
                        <div className="relative">
                            <div
                                className="border border-gray-300 font-medium text-gray-600 rounded-md p-1 text-sm cursor-pointer"
                                onClick={() => toggleDropdown(product.id)}
                            >
                                {selectedDurations[product.id] || 30} days
                            </div>
                            {openDropdownId === product.id && (
                                <ul className="absolute z-10 bg-white border border-gray-300 rounded-md w-40 mt-1">
                                    {[30, 90, 180, 365].map((duration) => (
                                        <li
                                            key={duration}
                                            className="p-2 font-medium text-gray-600 text-sm hover:bg-gray-100 cursor-pointer"
                                            onClick={() => handleDurationSelect(product.id, duration)}
                                        >
                                            {duration} days
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <button
                        onClick={showModal}
                        className="bg-[#EFB81C] rounded-md px-4 py-2 font-medium text-sm"
                    >
                        Subscribe
                    </button>
                </div>
            ))}
            <SubscribePopup
                isPopupOpen={isPopupOpen}
                onOk={handleCancel}
                onCancel={handleOk}
            />
        </div>
    );
}

export default EarnProduct;
