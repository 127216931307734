import React from 'react'
import instant from 'assets/img/instant-price.svg'
import guranted from 'assets/img/gurranted.svg'
import anypair from 'assets/img/any-pair-convert.svg'
import Faq from './Faq'

function Pool() {
    return (
        <div className='mt-16'>
            <p className='text-[32px] font-bold py-6'>Buycex Pool</p>
            <p className='text-[#81858c]'>A comprehensive service platform dedicated to improving the income of miners</p>
            <div className='flex gap-24 justify-between py-8'>
            <div className='flex w-1/6 flex-col items-center gap-4'>
                    <img src={instant} />
                    <p className='text-xl font-semibold'>Secure and Transparent</p>
                    <p className='text-center text-sm text-[#81858c]'>Hashrate is displayed in real-time while the Buycex security team safeguards your assets.</p>
                </div>
                <div className='flex w-1/6 flex-col items-center gap-4'>
                    <img src={guranted} />
                    <p className='text-xl font-semibold'>Steady Earnings</p>
                    <p className='text-center text-sm text-[#81858c]'>Using the FPPS、PPS+、PPS model and instant settlements, you can avoid fluctuations in earnings.</p>
                </div>
                <div className='flex w-1/6 flex-col items-center gap-4'>
                    <img src={anypair} />
                    <p className='text-xl font-semibold'>Comprehensive Service</p>
                    <p className='text-center text-sm text-[#81858c]'>Our comprehensive service is committed to improving miners' income, closing the gap between mining and trading, and providing a one-stop mining ecosphere.</p>
                </div>
            </div>
            <Faq/>
        </div>
    )
}

export default Pool