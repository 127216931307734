import React, {  useState } from 'react';
import ReactPaginate from 'react-paginate';

import MarketCard from './MarketCard';


 const PaginatedItems=({ itemsPerPage,currentCoins, data }) =>{
  const [itemOffset, setItemOffset] = useState(0);
 


// fetching Api data 

 
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
 
 // Filtering the data based on currentCoins
 const filteredData = Array.isArray(data) ? data.filter(item => {
  return currentCoins === "available" ? item.zhuangtai === "Running" : item.zhuangtai === "Completed"; // Adjust filter condition as needed
}) : [];

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  
  const currentItems = filteredData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredData.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  
  return (
    <>
    {currentCoins==="past" && <div className="text-center text-green-600 p-20 text-2xl">No Past Coin Available...</div>}
    <MarketCard currentItems={currentItems}  />
     
      <ReactPaginate
      activeClassName={'item active '}
      breakClassName={'item break-me '}
      containerClassName={'pagination'}
        disabledClassName={'disabled-page'}
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageClassName={'item pagination-page '}
        previousClassName={"item previous"}
        nextClassName={"item "}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className=' flex items-center justify-end gap-4 mt-8 px-2 md:px-8'
      />
     
    </>
  );
}

export default PaginatedItems;