// AssetsUserAction
import toast from "react-hot-toast";
import { RepositoryFactory } from "repository/RepositoryFactory";
const earnStaking = RepositoryFactory.get("earnStaking");

export const fetchEarnStakingData = (userid, token) => async (dispatch) => {
    dispatch({ type: "FETCH_EARN_STAKING_DATA_REQUEST" });
    try {
        const response = await earnStaking.earnStaking(userid, token);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_EARN_STAKING_DATA_FAILURE", payload: error.message });
    }
};
export const fetchEarnStats = (userid, token) => async (dispatch) => {
    dispatch({ type: "FETCH_EARN_STAKING_DATA_REQUEST" });
    try {
        const response = await earnStaking.fetchEarnStats(userid, token);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
        }
        return response;
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_EARN_STAKING_DATA_FAILURE", payload: error.message });
    }
};
export const fetchuserproduct = (userid, token) => async (dispatch) => {
    dispatch({ type: "FETCH_EARN_STAKING_DATA_REQUEST" });
    try {
        const response = await earnStaking.fetchuserproduct(userid, token);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
        }
        return response;
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_EARN_STAKING_DATA_FAILURE", payload: error.message });
    }
};
export const productlist = (userid, token) => async (dispatch) => {
    dispatch({ type: "FETCH_EARN_STAKING_DATA_REQUEST" });
    try {
        const response = await earnStaking.productlist(userid, token);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
        }
        return response;
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_EARN_STAKING_DATA_FAILURE", payload: error.message });
    }
};

export const accountsummary = (userid, token) => async (dispatch) => {
    dispatch({ type: "FETCH_EARN_STAKING_DATA_REQUEST" });
    try {
        const response = await earnStaking.accountsummary(userid, token);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
        }
        return response;
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_EARN_STAKING_DATA_FAILURE", payload: error.message });
    }
};
export const Staking = (userid, token) => async (dispatch) => {
    dispatch({ type: "FETCH_EARN_STAKING_DATA_REQUEST" });
    try {
        const response = await earnStaking.Staking(userid, token);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_EARN_STAKING_DATA_FAILURE", payload: error.message });
    }
};

export const accountSummaryStats = (userid, token) => async (dispatch) => {
    dispatch({ type: "FETCH_EARN_STAKING_DATA_REQUEST" });
    try {
        const response = await earnStaking.accountSummaryStats(userid, token);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
            // return response
        } else {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
        }
        return response
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_EARN_STAKING_DATA_FAILURE", payload: error.message });
    }
};

export const createStaking = (userid, token, payload,setIsModalOpen,setAmount) => async (dispatch) => {
    dispatch({ type: "FETCH_STAKING_DATA_REQUEST" });
    try {
        console.log(userid, token,payload);
        const response = await earnStaking.createStaking(userid, token,payload);

        // console.log(response.data.data);
        
        
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
            toast.success(response.data.data);
            setIsModalOpen(false);
            setAmount("")
        } else {
            dispatch({
                type: "FETCH_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            toast.error(response.data.data);
            // console.log("Assets Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_STAKING_DATA_FAILURE", payload: error.message });
    }
};

export const saveuserproduct = (userid, token, payload) => async (dispatch) => {
    dispatch({ type: "FETCH_STAKING_DATA_REQUEST" });
    try {
        const response = await earnStaking.saveuserproduct(userid, token,payload);        
        
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
            toast.success(response.data.data);
            setAmount("")
        } else {
            dispatch({
                type: "FETCH_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            toast.error(response.data.data);
        }
        return response
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_STAKING_DATA_FAILURE", payload: error.message });
    }
};

export const Toggle = (userid, token, payload) => async (dispatch) => {
    dispatch({ type: "FETCH_STAKING_DATA_REQUEST" });
    try {
        const response = await earnStaking.Toggle(userid, token,payload);        
        
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
            toast.success(response.data.data);
        } else {
            dispatch({
                type: "FETCH_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            toast.error(response.data.data);
        }
        return response
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_STAKING_DATA_FAILURE", payload: error.message });
    }
};
export const Redeem = (userid, token, payload) => async (dispatch) => {
    dispatch({ type: "FETCH_STAKING_DATA_REQUEST" });
    try {
        const response = await earnStaking.Redeem(userid, token,payload);        
        
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
            toast.success(response.data.data);
        } else {
            dispatch({
                type: "FETCH_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            toast.error(response.data.data);
        }
        return response
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_STAKING_DATA_FAILURE", payload: error.message });
    }
};

export default fetchEarnStakingData;
