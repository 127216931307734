import { Modal } from "antd";
import React, { useState, useEffect } from "react";
import { BsExclamationCircleFill } from "react-icons/bs";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { TbBrandGoogle } from "react-icons/tb";
import { verifyG2faCode } from "store/actions/authAction";
import { useDispatch } from "react-redux";
import ResetPasswordGuide from "components/auth/validations/change-password/ResetPasswordGuide";
const LoginPasswordModal = ({ show, onClose, onSubmit }) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState([false, false, false]);

    const [inputValues, setInputValues] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        gVerificationCode: "",
    });

    const [enableSubmitButton, setEnaableSubmitButton] = useState(false);

    useEffect(() => {
        const {
            oldPassword,
            newPassword,
            confirmNewPassword,
            gVerificationCode,
        } = inputValues;
        if (
            oldPassword &&
            newPassword &&
            confirmNewPassword &&
            gVerificationCode
        ) {
            setEnaableSubmitButton(true);
        } else {
            setEnaableSubmitButton(false);
        }
    }, [inputValues]);

    const togglePasswordVisibility = (index) => {
        const updatedShowPassword = [...showPassword];
        updatedShowPassword[index] = !updatedShowPassword[index];
        setShowPassword(updatedShowPassword);
    };

    const inputChangeHandler = async (value, field) => {
        await setInputValues({
            ...inputValues,
            [field]: value,
        });
    };

    const onSubmitHandler = async () => {
        const payload = {
            user_id: localStorage.getItem("user_id_2fa") || "740",
            ga: parseInt(inputValues?.gVerificationCode),
        };
        dispatch(verifyG2faCode(payload, onSuccessHandler));
    };

    const onSuccessHandler = async () => {
        const {
            oldPassword,
            newPassword,
            confirmNewPassword,
            gVerificationCode,
        } = inputValues;

        onSubmit({
            oldPassword,
            newPassword,
            confirmNewPassword,
            gVerificationCode,
        });

        setInputValues({
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            gVerificationCode: "",
        });
        onClose();
    };

    return (
        <Modal
            title={<p>Change Password</p>}
            visible={show}
            // onOk={onSubmit}
            footer={null}
            onCancel={onClose}
        >
            <div>
                <div className="flex gap-2 bg-yellow-50 rounded-md p-2 items-start">
                    <BsExclamationCircleFill
                        fill="#EFB81C"
                        className="mt-[5px]"
                        size={16}
                    />
                    <span className="font-medium w-96">
                        After changing your account password, on-chain
                        withdrawals, internal transfers, fiat withdrawals, P2P
                        transactions, and BuyCex Card transactions will be
                        temporarily suspended for 24 hours.
                    </span>
                </div>
                <div className="mt-6">
                    {/* Old Password Field */}
                    <div>
                        <p className="mb-2">Old Password</p>
                        <div className="relative">
                            <input
                                type={showPassword[0] ? "text" : "password"}
                                className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2"
                                placeholder="Please enter your current password"
                                onChange={(e) =>
                                    inputChangeHandler(
                                        e.target.value,
                                        "oldPassword"
                                    )
                                }
                                value={inputValues?.oldPassword}
                            />
                            <button
                                type="button"
                                onClick={() => togglePasswordVisibility(0)}
                                className="absolute right-3 top-2/4 transform -translate-y-2/4"
                            >
                                {showPassword[0] ? (
                                    <AiOutlineEyeInvisible
                                        className="opacity-35"
                                        size={20}
                                    />
                                ) : (
                                    <AiOutlineEye
                                        className="opacity-35"
                                        size={20}
                                    />
                                )}
                            </button>
                        </div>
                    </div>

                    {/* New Password Field */}
                    <div className="mt-4">
                        <p className="mb-2">New Password</p>
                        <div className="relative">
                            <input
                                type={showPassword[1] ? "text" : "password"}
                                className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2"
                                placeholder="Please enter your new password"
                                onChange={(e) =>
                                    inputChangeHandler(
                                        e.target.value,
                                        "newPassword"
                                    )
                                }
                                value={inputValues?.newPassword}
                            />
                            <button
                                type="button"
                                onClick={() => togglePasswordVisibility(1)}
                                className="absolute right-3 top-2/4 transform -translate-y-2/4"
                            >
                                {showPassword[1] ? (
                                    <AiOutlineEyeInvisible
                                        className="opacity-35"
                                        size={20}
                                    />
                                ) : (
                                    <AiOutlineEye
                                        className="opacity-35"
                                        size={20}
                                    />
                                )}
                            </button>
                        </div>
                    </div>

                    {/* Confirm New Password Field */}
                    <div className="mt-4">
                        <p className="mb-2">Confirm New Password</p>
                        <div className="relative">
                            <input
                                type={showPassword[2] ? "text" : "password"}
                                className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2"
                                placeholder="Please re-enter your new password again"
                                onChange={(e) =>
                                    inputChangeHandler(
                                        e.target.value,
                                        "confirmNewPassword"
                                    )
                                }
                                value={inputValues?.confirmNewPassword}
                            />
                            <button
                                type="button"
                                onClick={() => togglePasswordVisibility(2)}
                                className="absolute right-3 top-2/4 transform -translate-y-2/4"
                            >
                                {showPassword[2] ? (
                                    <AiOutlineEyeInvisible
                                        className="opacity-35"
                                        size={20}
                                    />
                                ) : (
                                    <AiOutlineEye
                                        className="opacity-35"
                                        size={20}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="flex items-center gap-1 mb-2">
                            <TbBrandGoogle /> <p>Google verification code</p>
                        </div>
                        <input
                            type="number"
                            className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2 no-arrows"
                            placeholder="Please enter your Google Authenticator code"
                            style={{ appearance: "textfield" }}
                            onChange={(e) =>
                                inputChangeHandler(
                                    e.target.value,
                                    "gVerificationCode"
                                )
                            }
                            value={inputValues?.gVerificationCode}
                        />
                        <p className="text-end">
                        <p className="my-2 text-sm cursor-pointer font-medium inline-block" onClick={() => setShowModal(true)}>
                            Having problems with verification?
                        </p>
                        </p>
                         {/* ModalVerification Component */}
            <ResetPasswordGuide
                visible={showModal}
                onClose={() => setShowModal(false)}
            />
                        <button
                            onClick={onSubmitHandler}
                            className="bg-[#EFB81C] mt-5 py-2 text-center rounded-md w-full font-semibold"
                            disabled={!enableSubmitButton}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
                <style>
                    {`
                .no-arrows::-webkit-inner-spin-button,
                .no-arrows::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .no-arrows {
                    -moz-appearance: textfield; /* For Firefox */
                }
                `}
                </style>
            </div>
        </Modal>
    );
};

export default LoginPasswordModal;
