import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { FaCircleNotch } from "react-icons/fa6";
import { MdKeyboardArrowRight } from "react-icons/md";
import { accountSummaryStats } from "store/actions/EarnStaking";

function TotalValue({ onButtonClick }) {
    const [isVisible, setIsVisible] = useState(false);
    const [activeButton, setActiveButton] = useState("History");
    const [productData, setProductData] = useState([]);
    const userid = useSelector((state) => state.authUser?.user?.ID);
    const token = useSelector((state) => state.authUser?.user?.TOKEN);
    const dispatch = useDispatch();
    const conversionCoin = "USDT";

    useEffect(() => {
        const accountSummaryStatse = async () => {
            try {
                const response = await dispatch(
                    accountSummaryStats(userid, token)
                );
                if (response?.data?.status === 1) {
                    setProductData(response.data.data);
                } else {
                    console.error(
                        "Failed to fetch products:",
                        response?.data?.message
                    );
                }
            } catch (error) {
                console.error("Error fetching user product:", error);
            }
        };

        if (userid && token) {
            accountSummaryStatse();
        }
    }, [dispatch, userid, token]);

    useEffect(() => {
        const storedVisibility = localStorage.getItem("totalValueVisibility");
        if (storedVisibility !== null) {
            setIsVisible(storedVisibility === "true");
        }
    }, []);

    const toggleVisibility = () => {
        setIsVisible((prev) => {
            const newVisibility = !prev;
            localStorage.setItem("totalValueVisibility", newVisibility);
            return newVisibility;
        });
    };

    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
        onButtonClick(buttonType);
    };

    const getButtonClass = (buttonType) => {
        return buttonType === activeButton
            ? "bg-[#EFB81C] px-3 py-2 rounded-md text-sm font-semibold"
            : "border border px-3 py-2 rounded-md text-sm font-semibold";
    };

    const totalAmount = productData.reduce((acc, item) => {
        return acc + parseFloat(item.total_to_deposit || 0);
    }, 0).toFixed(2);

    const totalDeposit = productData.reduce((acc, product) => acc + parseFloat(product.total_to_deposit || 0), 0);
    const deposit_completed = productData.reduce((acc, product) => acc + parseFloat(product.deposit_completed || 0), 0);
    const deposit_missed = productData.reduce((acc, product) => acc + parseFloat(product.deposit_missed || 0), 0);
    const totalInstallments = productData.reduce((acc, product) => acc + parseInt(product.total_installments || 0), 0);
    const total_installment = productData.reduce((acc, product) => acc + parseInt(product.total_installment || 0), 0);

    const missedAmount = deposit_completed * totalAmount;
    return (
        <div className="mt-0 lg:mt-6 p-3 sm:p-6 rounded-2xl border">
            <div>
                <div className="flex flex-col md:flex-row justify-normal md:justify-between w-full">
                    <div>
                        <div className="flex items-center gap-1 w-full">
                            <div className="flex w-full justify-between">
                                <div className="flex items-center gap-1">
                                    <p className="text-[20px] font-semibold">
                                        Total Value
                                    </p>
                                    <button
                                        onClick={toggleVisibility}
                                        className="mt-1"
                                    >
                                        {isVisible ? (
                                            <FaRegEyeSlash className="text-xl" />
                                        ) : (
                                            <FaRegEye className="text-xl" />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-baseline gap-2">
                            <p className="text-[32px] font-semibold">
                                {isVisible ? totalAmount : "****"}
                            </p>
                            <span className="text-sm flex items-center gap-1 font-semibold">
                                {conversionCoin}
                                <FaAngleDown className="text-[12px] opacity-40 mt-1" />
                            </span>
                            <p className="border px-3 py-1 rounded-md font-semibold text-sm flex gap-2 items-center">
                                <FaCircleNotch />
                                All Assets
                            </p>
                        </div>
                        <p>{isVisible ? `≈ ${totalAmount}` : "*****"}</p>
                    </div>
                    <div>
                        <button
                            onClick={() => handleButtonClick("Deposit")}
                            className={getButtonClass("Deposit")}
                        >
                            Deposit
                        </button>
                        <button
                            onClick={() => handleButtonClick("History")}
                            className={`ml-4 ${getButtonClass("History")}`}
                        >
                            History
                        </button>
                    </div>
                </div>
                <div className="flex w-full justify-between flex-wrap">
                    <div className="flex flex-col gap-1 mt-6">
                        <div className="flex items-center gap-1 text-ellipsis whitespace-nowrap">
                            <p className="font-semibold text-lg">
                                Deposit Count
                            </p>
                            <MdKeyboardArrowRight className="text-[#707a8a]" />
                        </div>
                        <div className="flex gap-1 text-[#707a8a] font-medium">
                            <p className="text-xs">Total Instalment</p>
                            <p className="text-xs">
                                {isVisible
                                    ? `= ${totalInstallments}`
                                    : "****"}
                            </p>
                        </div>
                        <div className="flex gap-1 text-[#707a8a] font-medium">
                            <p className="text-xs">Completed</p>
                            <p className="text-xs">
                                {isVisible
                                    ? `= ${total_installment}`
                                    : "****"}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 mt-6">
                        <div className="flex items-center gap-1 text-ellipsis whitespace-nowrap">
                            <p className="font-semibold text-lg">
                                Deposit Amount
                            </p>
                            <MdKeyboardArrowRight className="text-[#707a8a]" />
                        </div>
                        <div className="flex gap-1 text-[#707a8a] font-medium">
                            <p className="text-xs">Total Deposit</p>
                            <p className="text-xs">
                                {isVisible
                                    ? `= ${totalDeposit}`
                                    : "****"}
                            </p>
                        </div>
                        <div className="flex gap-1 text-[#707a8a] font-medium">
                            <p className="text-xs">Deposited</p>
                            <p className="text-xs">
                                {isVisible
                                    ? `= ${deposit_completed}`
                                    : "****"}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 mt-6">
                        <div className="flex items-center gap-1 text-ellipsis whitespace-nowrap">
                            <p className="font-semibold text-lg">
                                Missing Details
                            </p>
                            <MdKeyboardArrowRight className="text-[#707a8a]" />
                        </div>
                        <div className="flex gap-1 text-[#707a8a] font-medium">
                            <p className="text-xs">Missed Count</p>
                            <p className="text-xs">
                                {isVisible
                                    ? `= ${deposit_missed}`
                                    : "****"}
                            </p>
                        </div>
                        <div className="flex gap-1 text-[#707a8a] font-medium">
                            <p className="text-xs">Missed Amount</p>
                            <p className="text-xs">
                                {isVisible
                                    ? `= ${missedAmount}`
                                    : "****"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TotalValue;
