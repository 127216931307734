import React, { useState } from 'react';
import { SiDogecoin, SiLitecoin } from 'react-icons/si';
import vip from 'assets/img/vip.png';

const pools = [
    {
        id: 1,
        title: 'FLOCK Pool',
        description: 'Stake FLOCK to Earn FLOCK',
        prize: '2,000,000',
        apr: '800.00%',
        vipApr: '960.00%',
        totalStaked: '-- FLOCK',
        totalParticipants: '--',
        timeRemaining: '67:28:08',
        icons: [<SiLitecoin size={18} key="1" />, <SiLitecoin size={18} key="2" className="ml-[-2px]" />],
    },
    {
        id: 2,
        title: 'MNT Pool',
        description: 'Stake MNT to Earn FLOCK',
        prize: '3,000,000',
        apr: '800.00%',
        vipApr: '960.00%',
        totalStaked: '-- MNT',
        totalParticipants: '--',
        timeRemaining: '67:28:08',
        icons: [<SiDogecoin size={18} key="1" />, <SiLitecoin size={18} key="2" className="ml-[-2px]" />],
    },
    {
        id: 3,
        title: 'USDT Pool',
        description: 'Stake USDT to Earn FLOCK',
        prize: '5,000,000',
        apr: '800.00%',
        vipApr: '960.00%',
        totalStaked: '-- USDT',
        totalParticipants: '--',
        timeRemaining: '67:28:08',
        icons: [
            <SiLitecoin fill="#10d173" size={18} key="1" />,
            <SiLitecoin size={18} key="2" className="ml-[-2px]" />,
        ],
    },
];

const stakingData = [
    {
        icon: <SiLitecoin size={44} />,
        title: "Flock",
        description: "Federated Learning on Blockchain (FLock), which anchors our business goals and the one",
        tags: ["Website", "Whitepaper", "Rules"],
        prizePool: "10,00,000",
        apr: "960.00%",
        stakingPeriod: "12-31 12:00 ~ 01-07 12:00 UTC",
    },
    {
        icon: <SiDogecoin size={44} />,
        title: "MNT",
        description: "MNT token staking for rewarding FLOCK users and liquidity providers.",
        tags: ["Website", "Whitepaper", "Rules"],
        prizePool: "20,00,000",
        apr: "1000.00%",
        stakingPeriod: "01-01 00:00 ~ 01-15 12:00 UTC",
    },
    {
        icon: <SiLitecoin fill="#10d173" size={44} />,
        title: "USDT",
        description: "Stablecoin staking for earning FLOCK with low volatility.",
        tags: ["Website", "Whitepaper", "Rules"],
        prizePool: "15,00,000",
        apr: "850.00%",
        stakingPeriod: "01-02 10:00 ~ 01-20 12:00 UTC",
    },
    {
        icon: <SiLitecoin size={44} />,
        title: "Flock 4",
        description: "Flock 4 description",
        tags: ["Website", "Whitepaper", "Rules"],
        prizePool: "40,00,000",
        apr: "960.00%",
        stakingPeriod: "12-31 12:00 ~ 01-07 12:00 UTC",
    },
    {
        icon: <SiLitecoin size={44} />,
        title: "Flock 5",
        description: "Flock 5 description",
        tags: ["Website", "Whitepaper", "Rules"],
        prizePool: "50,00,000",
        apr: "960.00%",
        stakingPeriod: "12-31 12:00 ~ 01-07 12:00 UTC",
    },
];

const StakingCard = ({ stakingData, pools }) => {
    return (
        <div className="rounded-lg bg-white px-6 pb-6 my-8">
            <p className="relative left-4 mb-2 rounded-bl-md flex justify-end self-end">
                <span className="bg-gray-400 bg-opacity-10 font-medium text-gray-600 px-2">Ended</span>
            </p>
            <div className="flex gap-3 w-full">
                <div className="w-1/4">
                    {stakingData.map((item, index) => (
                        <div key={index}>
                            <div className="flex gap-3 items-center">
                                {item.icon}
                                <p className="text-[24px] font-semibold">{item.title}</p>
                            </div>
                            <p className="line-clamp-2 text-sm pt-2">{item.description}</p>
                            <div className="flex gap-1 my-3">
                                {item.tags.map((tag, idx) => (
                                    <span key={idx} className="border rounded-full px-2 text-[#81858c]">
                                        {tag}
                                    </span>
                                ))}
                            </div>
                            <div>
                                <span className="text-[#81858c]">Prize Pool (FLOCK)</span>
                                <p className="text-lg font-semibold">{item.prizePool}</p>
                            </div>
                            <div className="my-4">
                                <span className="underline underline-offset-2 text-[#81858c] decoration-dashed">APR</span>
                                <p className="text-lg font-semibold">{item.apr}</p>
                            </div>
                            <span className="text-[#81858c]">Completed</span>
                            <div className="flex gap-3 mt-4">
                                <span className="underline underline-offset-2 text-[#81858c] decoration-dashed">
                                    Staking Period
                                </span>
                                <span>{item.stakingPeriod}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="w-full flex border rounded-2xl p-[24px]">
                    {pools.map((pool) => (
                        <div key={pool.id} className="w-1/3 border-r px-4 last:border-r-0">
                            <div className="flex flex-col items-center">
                                <p className="text-xl font-semibold">{pool.title}</p>
                                <span className="text-[#81858c]">{pool.description}</span>
                                <div className="bg-gray-100 h-12 w-12 justify-center p-1 mt-2 flex items-center rounded-full">
                                    {pool.icons}
                                </div>
                            </div>
                            <div className="flex flex-col items-center mt-4">
                                <span className="text-[#81858c]">Prize Pool (FLOCK)</span>
                                <p className="text-xl font-semibold">{pool.prize}</p>
                            </div>
                            <div className="flex justify-between w-full mt-6">
                                <span className="underline underline-offset-2 text-[#81858c] decoration-dashed">APR</span>
                                <div className="flex gap-1 underline underline-offset-4 text-[#81858c] decoration-dashed">
                                    <span className="text-black font-medium">{pool.apr}</span>
                                    <span className="font-medium">( </span>
                                    <span className="flex font-medium items-center gap-1">
                                        <img src={vip} className="w-[28px] h-[14px]" alt="VIP" />
                                        <span>{pool.vipApr}</span>
                                    </span>
                                    <span className="font-medium">)</span>
                                </div>
                            </div>
                            <div className="flex justify-between w-full py-3">
                                <span className="text-[#81858c]">Total Staked</span>
                                <span className="font-medium">{pool.totalStaked}</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <span className="text-[#81858c]">Total Participants</span>
                                <span className="font-medium">{pool.totalParticipants}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

function PastPerformance() {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;

    const coinList = stakingData;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const paginatedCoinList = coinList.slice(startIndex, endIndex);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div className="bg-[#F5F7FA]">
            <div className="container pt-8">
                <p className="text-[24px] font-semibold pt-4">Past Performance</p>
                <span className="text-[#81858c]">Earn new tokens – no purchases, payments or fees required!...</span>
                {paginatedCoinList.length > 0 && paginatedCoinList.map((data, index) => (
                    <StakingCard key={index} stakingData={[data]} pools={pools} />
                ))}
            </div>
            <div className="flex justify-center py-4">
                {Array.from({ length: Math.ceil(coinList.length / itemsPerPage) }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-3 py-1 mx-1 text-xs font-semibold border rounded ${currentPage === index + 1 ? 'bg-yellow-500 text-black' : 'bg-gray-100 text-gray-700'}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default PastPerformance;
