import React, { useEffect, useState } from "react";
import CoinCard from "./CoinCard";
import DPSTable from "./DPSTable";
import { useDispatch, useSelector } from "react-redux";
import { accountsummary, fetchEarnStats } from "store/actions/EarnStaking";
import { Link } from "react-router-dom/cjs/react-router-dom";

const CryptoDPS = () => {
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const [earnStats, setEarnStats] = useState({
        totalUsersJoined: 0,
        totalDeposits: "0",
        growthPercentage: 0,
        projects: 0,
        thisMonthJoined: "0",
        lastMonthJoined: "0",
    });
    const [depositData, setDepositData] = useState({
        deposit_completed: "0",
        deposit_missed: "0",
        auto_deduct: "On",
        total_installments: "0",
        monthly_deposit: "0",
        weekly_deposit: "0",
        total_to_deposit: "0",
        due: "0",
        coin: "cvm",
    });
    const [combinedMonths, setCombinedMonths] = useState(0); 

    const calculateCombinedMonths = () => {
        const totalMonths = parseFloat(depositData.monthly_deposit || 0);
        const totalWeeks = parseFloat(depositData.weekly_deposit || 0);
        const weeksAsMonths = totalWeeks / 4;
        const combined = totalMonths + weeksAsMonths;
        setCombinedMonths(combined.toFixed(2)); // Keeping two decimal places
    };

    useEffect(() => {
        calculateCombinedMonths();
    }, [depositData.monthly_deposit, depositData.weekly_deposit]);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(
                    fetchEarnStats(userID, userToken)
                );
                const data = response.data.data;

                setEarnStats({
                    totalUsersJoined: data.total_users_joined,
                    totalDeposits: data.total_deposits,
                    growthPercentage: data.growth_percentage,
                    projects: data.projects,
                    thisMonthJoined: data.this_month_joined,
                    lastMonthJoined: data.last_month_joined,
                });
            } catch (error) {
                console.error("Error fetching staking data:", error.message);
            }
        };

        if (userID && userToken) {
            fetchData();
        }
    }, [dispatch, userID, userToken]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(
                    accountsummary(userID, userToken)
                );
                const data = response.data.data;

                if (Array.isArray(data) && data.length > 0) {
                    const totals = data.reduce(
                        (acc, item) => {
                            acc.total_to_deposit += parseFloat(
                                item.total_to_deposit || 0
                            );
                            acc.deposit_completed += parseFloat(
                                item.deposit_completed || 0
                            );
                            acc.deposit_missed += parseFloat(
                                item.deposit_missed || 0
                            );
                            acc.total_installments += parseFloat(
                                item.total_installments || 0
                            );
                            acc.monthly_deposit += parseFloat(
                                item.monthly_deposit || 0
                            );
                            acc.weekly_deposit += parseFloat(
                                item.weekly_deposit || 0
                            );
                            acc.due += parseFloat(item.due || 0);
                            acc.coin = item.coin;
                            acc.auto_deduct;
                            return acc;
                        },
                        {
                            total_to_deposit: 0,
                            deposit_completed: 0,
                            deposit_missed: 0,
                            total_installments: 0,
                            monthly_deposit: 0,
                            weekly_deposit: 0,
                            due: 0,
                            coin: "cvm",
                            auto_deduct: "On",
                        }
                    );

                    setDepositData(totals);
                } else {
                    console.warn("Data is not in expected format or is empty.");
                }
            } catch (error) {
                console.error("Error fetching staking data:", error.message);
            }
        };

        if (userID && userToken) {
            fetchData();
        }
    }, [dispatch, userID, userToken]);

    return (
        <div className="bg-[#f5f7fa] pb-10">
            <div className="bg-[#f5f7fa] ">
                <div classname="earn-overview">
                    <div className="bg-black">
                        <div className="container h-full flex items-stretch justify-between !px-10 gap-10 pt-10 pb-6">
                            {/* Left Section */}
                            <div className="flex-1 text-black p-6 pt-12 rounded-[16px]">
                                <span className="text-[52px] font-[900] text-[#EFB81C]">
                                    CRYPTO DPS
                                </span>
                                <p className="text-[24px] font-medium">
                                    Deposit Monthly Get Back Double Amount
                                </p>
                                <div className="flex gap-6 text-[#EFB81C] items-center mt-4 flex-wrap">
                                    <div>
                                        <p className="text-[#adb1b8] text-[14px] font-semibold">
                                            Total Committed Amount (USD)
                                        </p>
                                        <p className="text-[18px] font-semibold">
                                            ${earnStats.totalDeposits}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-6">
                                        <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                                        <div>
                                            <p className="text-[#adb1b8] text-[14px] font-semibold">
                                                AVG. Growth
                                            </p>
                                            <p className="text-[18px] font-semibold">
                                            {parseFloat(earnStats.growthPercentage).toFixed(2)}%
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-6">
                                        <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                                        <div>
                                            <p className="text-[#adb1b8] text-[14px] font-semibold">
                                                Participants
                                            </p>
                                            <p className="text-[18px] font-semibold">
                                                {earnStats.totalUsersJoined}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-6">
                                        <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                                        <div>
                                            <p className="text-[#adb1b8] text-[14px] font-semibold">
                                                Projects
                                            </p>
                                            <p className="text-[18px] font-semibold">
                                                {earnStats.projects}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="hero-btn-wrap flex items-center gap-4 mt-2 mb-12"></div>

                                <div className="mt-4 flex items-center gap-4">
                                    <div className="flex items-center gap-1">
                                        <button className="bg-[#EFB81C] text-white py-1 px-4 rounded-md">
                                            Token Sale Records
                                        </button>
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <button className="border border-[#EFB81C] text-[#EFB81C] py-1 px-4 rounded-md">
                                            Vote History
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Separator Line */}
                            <div className="w-[2px] bg-[#adb1b8] h-full mx-4"></div>

                            {/* Right Section */}
                            <div className="w-[45%] text-black p-6 pt-12 rounded-[16px]">
                                <span className="text-[52px] font-[900] text-[#EFB81C]">
                                    Account Summary
                                </span>
                                <div className="flex gap-6 text-[#EFB81C] items-center mt-4 flex-wrap">
                                    <div>
                                        <p className="text-[#adb1b8] text-[14px] font-semibold">
                                            Total Count
                                        </p>
                                        <p className="text-[18px] font-semibold">
                                            {combinedMonths}{" "}
                                            Month
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-6">
                                        <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                                        <div>
                                            <p className="text-[#adb1b8] text-[14px] font-semibold">
                                                Auto Completed
                                            </p>
                                            <p className="text-[18px] font-semibold">
                                                {depositData.auto_deduct === "1"
                                                    ? "On"
                                                    : "Off"}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-6">
                                        <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                                        <div>
                                            <p className="text-[#adb1b8] text-[14px] font-semibold">
                                                Completed
                                            </p>
                                            <p className="text-[18px] font-semibold">
                                                {depositData.deposit_completed}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-6">
                                        <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                                        <div>
                                            <p className="text-[#adb1b8] text-[14px] font-semibold">
                                                Missed
                                            </p>
                                            <p className="text-[18px] font-semibold">
                                                {depositData.deposit_missed}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-2 border-[#EFB81C] text-[#fff] mt-4 rounded-[14px] shadow-lg p-4 flex items-center justify-between gap-8">
                                    <div className="flex-1 flex flex-col gap-2">
                                        <p className="text-[#adb1b8] text-[14px] font-semibold">
                                            Total Deposit
                                        </p>
                                        <div className="flex items-center gap-1">
                                            <p className="text-[18px] font-semibold text-[#20b26c]">
                                                {depositData.total_to_deposit}{" "}
                                                {depositData.coin}
                                            </p>
                                        </div>
                                        <Link to='/user/history/dps-history'>
                                        <button className="w-full bg-[#EFB81C] text-[12px] h-[32px] rounded-[8px] font-semibold">
                                            History
                                        </button>
                                        </Link>
                                    </div>
                                    <div className="flex-1 flex flex-col gap-2 items-end">
                                        <p className="text-[#adb1b8] text-[14px] font-semibold">
                                            Total Due
                                        </p>
                                        <div className="flex items-center gap-1">
                                            <p className="text-[18px] font-semibold text-red-500">
                                                {depositData.due === null
                                                    ? 0
                                                    : depositData.due}{" "}
                                                {depositData.coin}
                                            </p>
                                        </div>
                                        <button className="w-full h-[32px] text-[12px] text-[#EFB81C] font-semibold border-solid border-[1px] border-[#595d61] rounded-[8px]">
                                            Deposit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-[40px] z-50 bg-[#EFB81C]"></div>
                    {/* cards  */}
                    {/* <div className="container !px-10 ">
                        <h2 className="text-[#121214] text-[24px] mt-8 font-semibold">
                            Expiring Soon
                        </h2>
                        <p className="mt-1 text-[#81858c] text-[16px]">
                            T+0 or T+1-day expiry options. Affordable and highly
                            liquid options for traders.
                        </p>
                        <div className="mt-4 flex flex-wrap gap-6">
                            <CoinCard />
                            <CoinCard />
                            <CoinCard />
                        </div>
                    </div> */}
                    {/* otrher cards  */}
                    {/* <div className="container !px-10">
                        <h2 className="text-[#121214] text-[24px] mt-8 font-semibold">
                            Top Volume in Last 24H
                        </h2>
                        <p className="mt-1 text-[#81858c] text-[16px]">
                            Recently executed individual trades with the highest
                            trading volume.
                        </p>
                        <div className="mt-4 flex flex-wrap gap-6">
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                        </div>
                    </div> */}

                    <div className="mt-6 flex justify-between gap-4 container !px-10">
                        <div className="flex-1">
                            <DPSTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CryptoDPS;
