import React from 'react';
import { Link } from 'react-router-dom';

const Launchnavbar = () => {
  const currentPath = window.location.pathname;

  return (
    <nav className="flex bg-[#EFB81C] space-x-8 pl-8">
      {/* Startup Link */}
      <div className="flex flex-col mt-1 gap-3">
        <Link
          to="/trade/launch-guard"
          className={`font-semibold text-sm border-b-4 h-8 border-transparent hover:bg-[#EFB81C] transition duration-300 relative ${
            currentPath === "/trade/launch-guard" ? "text-gray-600" : "text-gray-600"
          }`}
        >
          Startup
          {currentPath === "/trade/launch-guard" && (
            <span className="absolute left-1/2 transform -translate-x-1/2 bottom-0 w-auto px-2 h-1 bg-white rounded-full" />
          )}
        </Link>
      </div>

      {/* Launch Guard Link */}
      <div className="flex flex-col mt-1 gap-3">
        <Link
          to="/trade/startup-mining"
          className={`font-semibold text-sm border-b-4 h-8 border-transparent hover:bg-[#EFB81C] transition duration-300 relative ${
            currentPath.startsWith("/trade/startup-mining") ? "text-gray-600" : "text-gray-600"
          }`}
        >
          Launch Guard
          {currentPath.startsWith("/trade/startup-mining") && (
            <span className="absolute left-1/2 transform -translate-x-1/2 bottom-0 w-auto px-2 h-1 bg-white rounded-full" />
          )}
        </Link>
      </div>
    </nav>
  );
};

export default Launchnavbar;
