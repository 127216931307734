import { MdMargin } from "react-icons/md";
import { MdReplayCircleFilled } from "react-icons/md";
import { FaMoneyBill } from "react-icons/fa";
import { MdAssignment, MdLiveHelp } from "react-icons/md";
import { RiAccountBoxFill } from "react-icons/ri";
import { FaUserFriends } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { RiFundsFill } from "react-icons/ri";
import { FaDropbox } from "react-icons/fa";
import { BiSolidCopyAlt } from "react-icons/bi";
import { FaRobot } from "react-icons/fa6";
import { MdHistoryEdu } from "react-icons/md";
import { MdOutlinePayments } from "react-icons/md";
import { HiOutlineIdentification } from "react-icons/hi";
import { MdSecurity } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { IoNotifications } from "react-icons/io5";
import { AiFillApi } from "react-icons/ai";
import { AiFillHome } from "react-icons/ai";
import { MdAccountBalanceWallet } from "react-icons/md";
import { RiFolderHistoryFill } from "react-icons/ri";
import { IoIosCash } from "react-icons/io";
import { FaHandHoldingUsd } from "react-icons/fa";
import { BiMoneyWithdraw } from "react-icons/bi";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import { MdWorkHistory } from "react-icons/md";
import { FaHistory } from "react-icons/fa";
import { MdOutlineManageHistory } from "react-icons/md";

const menuItems = [
    {
        name: "Dashboard",
        icon: <AiFillHome />,
        to: "/user/dashboard",
    },
    {
        name: "Spot",
        icon: <MdAssignment />,
        to: "/user/asset/spot-overview",
    },
    {
        name: "Funding",
        icon: <RiFundsFill />,
        to: "/user/assets/funding-overview",
    },
    {
        name: "Margin",
        icon: <MdMargin />,
        to: "/user/assets/margin",
    },
    {
        name: "Futures",
        icon: <FaDropbox />,
        to: "/user/assets/future",
    },
    {
        name: "Copy Trading",
        icon: <BiSolidCopyAlt />,
        to: "/user/assets/copy-trading",
    },
    {
        name: "Fiat Balance",
        icon: <MdAccountBalanceWallet />,
        to: "/user/assets/fiat-balance",
    },
    {
        name: "Earn",
        icon: <FaMoneyBill />,
        to: "/user/earn/flexible-earn",
        subItems: [
            {
                name: "Dashboard",
                icon: <AiFillHome />,
                to: "/user/dashboard",
            },
            {
                name: "BCX Token",
                icon: <HiOutlineIdentification />,
                to: "/user/earn/bcx-token",
            },
            {
                name: "Flexible Earn",
                icon: <MdSecurity />,
                to: "/user/earn/flexible-earn",
            },
            {
                name: "Fixed-Term Earn",
                icon: <FaUserFriends />,
                to: "/user/earn/fixed-term-earn",
            },
            {
                name: "Crypto DPS",
                icon: <FaHandHoldingUsd />,
                to: "/user/earn/crypto-dps",
            },
            {
                name: "Crypto Mining",
                icon: <BiMoneyWithdraw />,
                to: "#",
            },
            {
                name: "Reward HUB",
                icon: <i className="bi bi-wallet-fill"></i>,
                to: "#",
            },
        ],
    },
    {
        name: "History",
        icon: <MdReplayCircleFilled />,
        to: "/user/history/transfer-history",
        subItems: [
            {
                name: "Dashboard",
                icon: <AiFillHome />,
                to: "/user/dashboard",
            },
            {
                name: "Spot History",
                icon: <MdReplayCircleFilled />,
                to: "/user/history/spot-history",
            },
            {
                name: "P2P History",
                icon: <FaUserFriends />,
                to: "/user/history/p2p-history",
            },
            {
                name: "Margin History",
                icon: <RiFolderHistoryFill />,
                to: "/user/history/margin-history",
            },
            {
                name: "Crypto Deposit",
                icon: <FaHandHoldingUsd />,
                to: "/user/history/crypto-deposit",
            },
            {
                name: "Crypto Withdraw",
                icon: <BiMoneyWithdraw />,
                to: "/user/history/crypto-withdraw",
            },
            {
                name: "Future History",
                icon: <MdHistoryEdu />,
                to: "/user/history/futures-history",
            },
            {
                name: "Earn History",
                icon: <MdOutlinePayments />,
                to: "/user/history/earn-history",
            },
            {
                name: "Crypto Convert",
                icon: <MdOutlinePublishedWithChanges />,
                to: "/user/history/convert-history",
            },
            {
                name: "Crypto Transfer",
                icon: <BiMoneyWithdraw />,
                to: "/user/history/crypto-transfer",
            },
            {
                name: "Transfer History",
                icon: <IoIosCash />,
                to: "/user/history/transfer-history",
            },
            {
                name: "Payment",
                icon: <MdPayments />,
                to: "/user/history/payments-history",
            },
            {
                name: "Pre-List",
                icon: <FaHistory />,
                to: "/user/history/Pre-ListTrade-history",
            },
            {
                name: "OTC History",
                icon: <MdWorkHistory />,
                to: "/user/history/otc-history",
            },
            {
                name: "Referral History",
                icon: <MdOutlineManageHistory />,
                to: "/user/history/referral-history",
            },
            {
                name: "DPS History",
                icon: <MdOutlineManageHistory />,
                to: "/user/history/dps-history",
            }
        ],
    },
    {
        name: "Account",
        icon: <RiAccountBoxFill />,
        subItems: [
            {
                name: "Account Overview",
                icon: <HiOutlineIdentification />,
                to: "/user/account/overview",
            },
            {
                name: "Security Setting",
                icon: <MdSecurity />,
                to: "/user/account/security",
            },
            {
                name: "Payment Profile",
                icon: <ImProfile />,
                to: "/user/account/payment",
            },
            {
                name: "Notification",
                icon: <IoNotifications />,
                to: "/user/account/notification-center",
            },
            {
                name: "API Management",
                icon: <AiFillApi />,
                to: "#",
            },
        ],
    },
    {
        name: "Rewards Hub",
        icon: <i className="bi bi-wallet-fill"></i>,
        to: "/user/account/rewards-hub",
    },
    {
        name: "Settings",
        icon: <IoMdSettings />,
        to: "/user/settings",
    },
];

export default menuItems;
