import React from 'react'
import { RiArrowRightLine } from 'react-icons/ri'
import launchpool from 'assets/img/launchpool.png'
import StakeEarn from './StakeEarn'
import PastPerformance from './PastPerformance'
import Faq from './Faq'

function LaunchPool() {
    return (
        <>
        <div className='bg-black'>
            <div className='flex justify-between items-center w-full container h-96 py-12'>
                <section className=''>
                    <h2 className='text-[48px] text-white font-[700]'>Launchpool</h2>
                    <span className='text-[#ADB1B8] text-[16px]'>Earn new tokens by staking crypto on BuyCex</span>
                    <div className='flex w-full my-8'>
                        <div className='flex flex-col gap-1 pr-4 relative'>
                            <span className='text-[#ADB1B8] text-sm'>Total Prize Pool (in USD)</span>
                            <span className='text-white font-[700] text-lg'>$44,558,435</span>
                            <div className='absolute right-0 top-1/2 transform -translate-y-1/2 h-6 w-[0.5px] bg-gray-500'></div>
                        </div>
                        <div className='flex flex-col gap-1 px-4 relative'>
                            <span className='text-[#ADB1B8] text-sm'>Total Participants</span>
                            <span className='text-white font-[700] text-lg'>3,795,834</span>
                            <div className='absolute right-0 top-1/2 transform -translate-y-1/2 h-6 w-[1px] bg-gray-500'></div>
                        </div>
                        <div className='flex flex-col gap-1 pl-4'>
                            <span className='text-[#ADB1B8] text-sm'>Total Projects</span>
                            <span className='text-white font-[700] text-lg'>44</span>
                        </div>
                    </div>
                    <div className='flex items-center gap-4'>
                        <div className='flex items-center gap-1'>
                            <span className='text-[#EFB81C] font-semibold'>Staking Records</span>
                            <RiArrowRightLine fill='#EFB81C' />
                        </div>
                        <div className='flex items-center gap-1'>
                            <span className='text-[#EFB81C] font-semibold'>Referral History & Rules</span>
                            <RiArrowRightLine fill='#EFB81C' />
                        </div>
                    </div>

                </section>
                <img src={launchpool} alt='launchpool' className='w-[302px] h-[195px] mr-20' />
            </div>
            <div className='bg-[#EFB81C] h-[32px]'>

            </div>
        </div>
        <StakeEarn/>
        <PastPerformance/>
        <div className='container'>
        <Faq/>
        </div>
        </>
    )
}

export default LaunchPool