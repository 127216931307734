import { Modal } from "antd";
import React, { useState } from "react";
import {
    FaBitcoin,
    FaCaretDown,
    FaCheckSquare,
    FaRegSquare,
} from "react-icons/fa";
import { GoQuestion } from "react-icons/go";
import { GrFormCheckmark } from "react-icons/gr";

function SubscribePopup({ isPopupOpen, onOk, onCancel }) {
    const [activeComponent, setActiveComponent] = useState("Subscribe");
    const [isChecked, setIsChecked] = useState(false);
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    const handleConfirm = () => {
        onOk();
        setIsSuccessPopupOpen(true);
    };

    const closeSuccessPopup = () => {
        setIsSuccessPopupOpen(false);
    };

    return (
        <>
            <Modal
                open={isPopupOpen}
                onOk={onOk}
                onCancel={onCancel}
                footer={null}
                width={500}
            >
                <button
                    className={`relative text-sm h-12 font-semibold mr-7 ${activeComponent === "Subscribe"
                            ? "text-black"
                            : "text-gray-400"
                        }`}
                    onClick={() => setActiveComponent("Subscribe")}
                >
                    Subscribe
                    {activeComponent === "Subscribe" && (
                        <span className="absolute left-1/2 transform -translate-x-1/2 bottom-0 w-1/4 h-1 bg-[#EFB81C] rounded-full" />
                    )}
                </button>

                <div className="flex gap-2 p-3 flex-col w-auto justify-self-start mt-4 border rounded-xl self-end">
                    <p>Flexible</p>
                    <div className="flex gap-1">
                        <p className="text-[#30bd86] text-md font-medium">
                            12.16%
                        </p>
                        <p className="font-medium text-gray-600">Max</p>
                    </div>
                </div>
                <div className="mt-6 mb-3">
                    <p className="font-medium text-sm mb-1">Amount</p>
                    <div className="flex gap-4 items-center border p-2 rounded-md w-full">
                        <input
                            placeholder="Min 0.1 BTC"
                            className="w-full h-fit outline-none"
                        />
                        <div className="flex gap-1 items-center">
                            <FaBitcoin fill="#ffbf00" size={20} />
                            <p className="font-semibold">BTC</p>
                        </div>
                        <p className="text-[#EFB81C] cursor-pointer font-semibold">
                            Max
                        </p>
                    </div>
                </div>
                <div className="flex justify-between w-full">
                    <div className="flex gap-2 items-center opacity-75">
                        <p className="text-xs">Funding Account (0.0000 USDT)</p>
                        <FaCaretDown className="opacity-60" />
                    </div>
                    <div className="flex">
                        <p className="text-[#EFB81C] font-semibold border-r pr-2">
                            Deposit
                        </p>
                        <p className="text-[#EFB81C] font-semibold pl-2">
                            Transfer
                        </p>
                    </div>
                </div>
                <div className="mt-6 mb-3">
                    <p className="font-medium text-sm mb-1">Summary</p>
                    <div className="flex justify-between w-full py-2">
                        <p className="underline underline-offset-4 opacity-75 text-sm font-medium decoration-dotted">
                            Daily Real-Time Reward
                        </p>
                        <p className="opacity-75 text-sm text-gray-500 font-medium">
                            APR 6.82%
                        </p>
                    </div>
                    <div className="flex justify-between w-full">
                        <p className="opacity-75 text-sm font-medium py-2">
                            Est. Daily Yield
                        </p>
                        <p className="opacity-75 text-sm text-[#30bd86] py-2 font-medium">
                            -- BTC
                        </p>
                    </div>
                    <div className="flex justify-between w-full py-2">
                        <div className="flex items-center gap-1">
                            <p className="opacity-75 text-sm font-medium">
                                Auto Savings
                            </p>
                            <GoQuestion />
                        </div>
                        <div>
                            <label class="inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    class="sr-only peer"
                                />
                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#EFB81C]"></div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex items-start gap-1">
                    <p className="text-gray-400">*</p>
                    <p className="text-[13px] text-gray-400">
                        Real-Time APR is subject to change every minute and
                        rewards are accrued and directly accumulated in your
                        Earn Wallet every minute. APR does not mean the actual
                        or predicted returns in fiat currency.
                    </p>
                </div>
                <div className="flex items-center mt-7 mb-3 gap-1">
                    <div className="cursor-pointer" onClick={toggleCheckbox}>
                        {isChecked ? (
                            <FaCheckSquare className="text-[#EFB81C] text-xl" />
                        ) : (
                            <FaRegSquare className="text-gray-400 text-xl" />
                        )}
                    </div>
                    <p className="flex gap-1">
                        I have readed and agreed to{" "}
                        <p className="text-[#EFB81C] underline underline-offset-2">
                            Agreement For Buycex Savings
                        </p>
                    </p>
                </div>
                <button
                    onClick={handleConfirm}
                    className="bg-[#EFB81C] flex justify-self-end px-4 py-2 rounded-md font-medium"
                >
                    Confirm
                </button>
            </Modal>
            {/* Success Popup */}
            <Modal
                open={isSuccessPopupOpen}
                onCancel={closeSuccessPopup}
                footer={null}
                width={470}
            >
                <div className="text-center">
                    <div className="p-2 mt-10 bg-gradient-to-r from-white to-[#1ad08d] flex justify-center items-center self-center justify-self-center rounded-full">
                        <div className="bg-white rounded-full">
                            <GrFormCheckmark
                                className="bg-[#1ad08d] rounded-full text-white"
                                size={80}
                            />
                        </div>
                    </div>
                    <h2 className="text-xl font-semibold mb-1 mt-6">
                        Subscription Successful
                    </h2>
                    <p className="font-bold text-2xl">2,050 USDT</p>
                    <p className="text-gray-500 my-4">
                        Real-Time APR rewards accumulate directly in your Earn
                        Wallet every minute, while Bonus Tiered APR rewards are
                        distributed to your Spot Wallet daily.
                    </p>
                    <div className="flex flex-col mt-5 gap-4 w-full">
                    <div className="flex justify-between w-full">
                        <p className="text-[15px]">Subscription Date</p>
                        <p className="text-[15px]">2024-12-12 06:57</p>
                    </div>
                    <div className="flex justify-between w-full">
                        <p className="text-[15px]">First Disribution Date</p>
                        <p className="text-[15px]">2024-12-14 06:00</p>
                    </div>
                    </div>
                    <div className="flex w-full mt-6 gap-3">
                    <button
                        onClick={closeSuccessPopup}
                        className="bg-gray-200 px-4 py-2 w-1/2 text-[16px] rounded-md font-medium"
                    >
                        Close
                    </button>
                    <button
                        onClick={closeSuccessPopup}
                        className="bg-[#EFB81C] px-4 py-2 w-1/2 text-[16px] rounded-md font-medium"
                    >
                        View Holdings
                    </button>
                    </div>
                </div>
            </Modal>
            <style>
                {`.ant-modal-root .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.25) !important;
        }
        `}
            </style>
        </>
    );
}

export default SubscribePopup;
