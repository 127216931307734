import React from "react";
import { BsQuestionCircle, BsWallet2 } from "react-icons/bs";
import { GoBook } from "react-icons/go";
import { MdOutlineWatchLater } from "react-icons/md";
import Navbar from "./Navbar";

function LaunchPad() {
    return (
        <>
            <div>
                <div className="container bg-black">
                    <h1 className="text-5xl font-bold text-white pt-8">
                        Launch Guard
                    </h1>
                    <div className="flex justify-between max-w-fit gap-x-[3.8rem] py-4">
                        <p className="text-[#EFB81C] flex-1 text-ellipsis whitespace-nowrap font-semibold underline underline-offset-2">
                            Protected
                        </p>
                        <p className="text-[#EFB81C] flex-1 text-ellipsis whitespace-nowrap font-semibold underline underline-offset-2">
                            Guarded
                        </p>
                        <p className="text-[#EFB81C] flex-1 text-ellipsis whitespace-nowrap font-semibold underline underline-offset-2">
                            Refunded
                        </p>
                    </div>


                    <p className="text-white font-semibold w-1/2 mt-4">
                        Users can Deposit USDT to Buy Pre Token before it is
                        officially listed in Exchang User Deposit USDT Againist
                        a Token to get early exposure to the the token. After
                        The token is listed in exchange User can redeem the
                        Token if token price is high If token price is low user
                        can refund his USDT without any assets loss.
                    </p>
                    <div className="flex gap-6 py-8">
                        <button className="border px-3 py-0.5 font-medium rounded-full text-white text-xs"><p className="flex items-center gap-1"><BsWallet2 />Trading Rules</p></button>
                        <button className="border px-3 py-0.5 font-medium rounded-full text-white text-xs"><p className="flex items-center gap-1"><BsQuestionCircle />FAQ</p></button>
                        <button className="border px-3 py-0.5 font-medium rounded-full text-white text-xs"><p className="flex items-center gap-1"><GoBook />Trading Guide</p></button>
                        <button className="border px-3 py-0.5 font-medium rounded-full text-white text-xs"><p className="flex items-center gap-1"><MdOutlineWatchLater />Beginner Guide</p></button>
                    </div>
                </div>
                <Navbar />
            </div>
        </>
    );
}

export default LaunchPad;
