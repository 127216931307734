import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import { DatePicker } from "antd";
import { createGlobalStyle, styled } from "styled-components";

import NoData from "assets/img/user/No-data.svg";

import { getBCXFinanceHistory } from "store/actions/BCXAction";

//import moment from 'moment'; // For formatting time

const { RangePicker } = DatePicker;

function BcxHistory() {
    const [filterType, setFilterType] = useState("All");

    const dispatch = useDispatch();
    const {
        loading,
        data: bcxHistoryData,
        error,
    } = useSelector((state) => state.bcxHistory.records);

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const history = useHistory();

    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        dispatch(getBCXFinanceHistory(userID, userToken));
    }, [dispatch, userID, userToken, history]);

    useEffect(() => {
        if (bcxHistoryData) {
            setFilteredData(bcxHistoryData);
        }
    }, [bcxHistoryData]);

    const formatDate = (timestamp) => {
        const unixTime = parseInt(timestamp, 10);
        return moment?.unix(unixTime).isValid()
            ? moment?.unix(unixTime).format("YYYY-MM-DD HH:mm:ss")
            : "N/A";
    };

    const handleSearch = () => {
        let filtered = bcxHistoryData;

        // Ensure selectedDateRange is an array and not empty before checking
        if (
            Array.isArray(selectedDateRange) &&
            selectedDateRange.length === 2
        ) {
            const [startDate, endDate] = selectedDateRange;
            filtered = filtered.filter((item) => {
                const itemDate = moment.unix(item.addtime);
                return itemDate.isBetween(startDate, endDate, "day", "[]");
            });
        }

        // Filter by type
        if (filterType !== "All") {
            filtered = filtered.filter(
                (item) => item.type.toLowerCase() === filterType.toLowerCase()
            );
        }

        setFilteredData(filtered);
    };

    const handleReset = () => {
        setSelectedDateRange([]);
        setFilterType("All");
        setFilteredData(bcxHistoryData); // Reset to all data
    };

    return (
        <div className="mt-5 container !p-0">
            <div className="px-3 flex items-center gap-6 pt-2">
                <GlobalStyles />
                <StyledRangePicker
                    className="px-2 w-[600px] h-[40px] bg-white sm:w-1/4 py-[0.55rem] border-l"
                    value={selectedDateRange}
                    onChange={setSelectedDateRange}
                />
                <button
                    className="bg-[#EAECEF] px-4 py-2 rounded-lg font-medium"
                    onClick={handleReset}
                >
                    Reset
                </button>
                <button
                    className="bg-[#EFB81C] px-4 py-2 rounded-lg font-medium"
                    onClick={handleSearch}
                >
                    Search
                </button>
            </div>
            <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
                <div className="flex justify-between border-b pb-3 w-full">
                    <p className="flex-[2] text-start pl-5">Order Id</p>
                    <p className="flex-1 text-start">From Coin</p>
                    <p className="flex-[2] text-start">To Coin</p>
                    <p className="flex-1 text-start">From Amount</p>
                    <p className="flex-1 text-start">To Amount</p>
                    <p className="flex-1 text-center">Date/Time</p>
                </div>

                {filteredData && filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                        <div
                            key={index}
                            className="flex justify-between border-b w-full"
                        >
                            <p className="flex-[2] text-[14px] text-black py-6 text-start pl-5">
                                {item.id}
                            </p>
                            <p className="flex-1 text-[14px] text-black py-6 text-start">
                                {item.from_coin}
                            </p>
                            <p className="flex-[2] text-[14px] text-black py-6 text-start">
                                {item.to_coin}
                            </p>
                            <p className="flex-1 text-[14px] text-black py-6 text-start">
                                {item.from_amount
                                    ? parseFloat(item.from_amount).toFixed(8)
                                    : "N/A"}{" "}
                            </p>
                            <p className="flex-1 text-[14px] text-black py-6 text-start">
                                {item.to_amount
                                    ? parseFloat(item.to_amount).toFixed(8)
                                    : "N/A"}{" "}
                            </p>

                            <p className="flex-1 text-[14px] text-black py-6 text-center">
                                {formatDate(item?.created_at)}
                            </p>
                        </div>
                    ))
                ) : (
                    <div className="w-full flex justify-center items-center h-96">
                        <div className="flex flex-col gap-1 h-44 justify-center items-center">
                            <img src={NoData} alt="No Data" />
                            <p className="text-[#707a8a] text-sm">No records</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const GlobalStyles = createGlobalStyle`
  .ant-picker:hover {
    border-color: #EFB81C !important;
  }
  .ant-picker-focused {
    border-color: #EFB81C !important;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important;
  }
  .ant-picker-active-bar {
    background: #EFB81C !important;
  }
  .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #EFB81C !important;
    color: white !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #EFB81C !important;
  }
  .ant-picker-dropdown .ant-picker-header-view > button:hover {
    color: #EFB81C !important;
  }
`;

const StyledRangePicker = styled(RangePicker)`
    .ant-picker-input > input:focus {
        box-shadow: none !important;
        border-color: #efb81c !important;
    }
`;

export default BcxHistory;
