import { Modal } from "antd";
import styled from "styled-components";
import React, { useState } from "react";
import { FiBookOpen } from "react-icons/fi";
import { RiBitCoinFill } from "react-icons/ri";
import { CgArrowsExchange } from "react-icons/cg";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { tr } from "date-fns/locale";

function TradeNowPopup({ isPopupActive, onOk, onCancel, tradeValue }) {
    console.log("tradeValue:", tradeValue);
    const [isChecked, setIsChecked] = useState(false);

    const toogleCheckbox = () => {
        setIsChecked(!isChecked);
    };
    return (
        <div>
            <CustomModal
                open={isPopupActive}
                onOk={onOk}
                onCancel={onCancel}
                footer={null}
                width={1000}
            >
                <>
                    <div className="flex w-full justify-between mt-4">
                        <div className="flex gap-3 items-end">
                            <div className="flex gap-1 items-center">
                                <RiBitCoinFill size={30} />
                                <p className="text-2xl font-semibold">
                                    Pre-list
                                </p>
                            </div>
                            <p className="text-lg font-semibold">
                                {tradeValue?.title}
                                <span className="text-[16px] font-medium">
                                    ( {tradeValue?.subtitle} )
                                </span>
                            </p>
                        </div>
                        <a
                            href={`https://docs.google.com/document/d/1f54NbVmw4UCWZwSCk1KH7w5d03bL99y1vHWWpcF-tGU/edit?usp=sharing`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="border hover:text-[#EFB81C] rounded-full flex gap-1 items-center px-3 py-0"
                        >
                            <FiBookOpen />
                            <p className="text-xs">About CVM Guard</p>
                        </a>
                    </div>
                    <div className="flex gap-10 w-full">
                        <div className="w-1/2">
                            <div className="border border-[#EFB81C] mb-4 rounded-md mt-4 w-full p-4">
                                <div className="flex justify-between">
                                    <p className="text-gray-400">
                                        Mining Amount
                                    </p>
                                    <p className="text-[#EFB81C] cursor-pointer">
                                        Max
                                    </p>
                                </div>
                                <div className="flex mt-3">
                                    <div className="flex p-2 bg-[#f9f9fb] rounded-md items-center">
                                        {/* <RiBitCoinFill size={18} /> */}
                                        {tradeValue?.icon}
                                        <p>
                                            {tradeValue.title}(
                                            {tradeValue.subtitle})
                                        </p>
                                    </div>
                                    <input
                                        className="bg-transparent placeholder:text-xl w-full outline-none px-3"
                                        placeholder="Please enter the amount"
                                    />
                                </div>
                            </div>
                            <div className="flex justify-between w-full my-3">
                                <p className="text-gray-400 font-medium">
                                    Staking Amount
                                </p>
                                <p className="opacity-90 font-medium">0 USDT</p>
                            </div>
                            <div className="flex justify-between w-full my-3">
                                <p className="text-gray-400 font-medium">
                                    Staking Amount
                                </p>
                                <p className="opacity-90 font-medium">
                                    1 CVM = 0.00532 USDT
                                </p>
                            </div>
                            <div className="flex justify-between w-full my-3">
                                <p className="text-gray-400 font-medium">
                                    Available
                                </p>
                                <p className="opacity-90 font-medium flex gap-1 items-center">
                                    {tradeValue?.volume} USDT
                                    <CgArrowsExchange size={22} />
                                </p>
                            </div>
                            <div className="flex justify-between w-full my-3">
                                <p className="text-gray-400 font-medium">
                                    Available
                                </p>
                                <p className="opacity-90 font-medium">0 USDT</p>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <p className="text-[16px] my-3 opacity-90 font-medium">
                                Info
                            </p>
                            <div className="border-b pb-2">
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium">
                                        Trading Start Time
                                    </p>
                                    <p className="opacity-90 font-medium">
                                        pending
                                    </p>
                                </div>
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium">
                                        Trading End Time
                                    </p>
                                    <p className="opacity-90 font-medium">
                                        pending
                                    </p>
                                </div>
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium">
                                        Delivery Starts
                                    </p>
                                    <p className="opacity-90 font-medium">
                                        pending
                                    </p>
                                </div>
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium">
                                        Delivery Ends
                                    </p>
                                    <p className="opacity-90 font-medium">
                                        pending
                                    </p>
                                </div>
                            </div>

                            <p className="text-[16px] my-3 opacity-90 font-medium">
                                Overview
                            </p>
                            <div className="pb-4">
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium">
                                        Total Holded
                                    </p>
                                    <p className="opacity-90 font-medium">
                                        {tradeValue?.stats?.change} USDT
                                    </p>
                                </div>
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium">
                                        Total (PreTokens)
                                    </p>
                                    <p className="font-medium text-gray-400">
                                        {tradeValue?.stats?.volume} CVM
                                    </p>
                                </div>
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium flex gap-2">
                                        Price{" "}
                                        <p className="opacity-90 text-sm text-black font-medium">
                                            {tradeValue?.stats?.low}
                                        </p>
                                    </p>
                                    <p className="opacity-90 font-medium flex items-center text-[13px]">
                                        Trade Now
                                        <MdKeyboardArrowRight size={14} />
                                    </p>
                                </div>
                                <div className="flex items-center mt-7 mb-3 gap-1">
                                    <div
                                        className="cursor-pointer"
                                        onClick={toogleCheckbox}
                                    >
                                        {isChecked ? (
                                            <FaCheckSquare className="text-[#EFB81C] text-xl" />
                                        ) : (
                                            <FaRegSquare className="text-gray-400 text-xl" />
                                        )}
                                    </div>
                                    <p className="flex gap-1">
                                        I have readed and agreed to{" "}
                                        <p className="text-[#EFB81C] underline underline-offset-2">
                                            Service Agreement
                                        </p>
                                    </p>
                                </div>
                            </div>
                            <Link
                                to="/trade/launch-pad/my-order"
                                className="hover:text-black"
                            >
                                <button
                                    onClick={onOk}
                                    className="w-full rounded-full py-2 bg-[#EFB81C] font-medium"
                                >
                                    {" "}
                                    Trade & Hold
                                </button>
                            </Link>
                        </div>
                    </div>
                </>
            </CustomModal>
        </div>
    );
}

const CustomModal = styled(Modal)`
    .ant-modal-content {
        padding: 25px 38px !important; /* Override padding */
    }
`;

export default TradeNowPopup;
