import React from 'react';
import { SiDogecoin, SiLitecoin } from 'react-icons/si';
import vip from 'assets/img/vip.png';
import { FaCoins } from 'react-icons/fa';
import { RiShareBoxFill } from 'react-icons/ri';
import { MdNotificationsActive, MdOutlineNotifications } from 'react-icons/md';

const pools = [
    {
        id: 1,
        title: 'FLOCK Pool',
        description: 'Stake FLOCK to Earn FLOCK',
        prize: '2,000,000',
        apr: '800.00%',
        vipApr: '960.00%',
        totalStaked: '-- FLOCK',
        totalParticipants: '--',
        timeRemaining: '67:28:08',
        icons: [<SiLitecoin size={18} key="1" />, <SiLitecoin size={18} key="2" className="ml-[-2px]" />],
    },
    {
        id: 2,
        title: 'MNT Pool',
        description: 'Stake MNT to Earn FLOCK',
        prize: '3,000,000',
        apr: '800.00%',
        vipApr: '960.00%',
        totalStaked: '-- MNT',
        totalParticipants: '--',
        timeRemaining: '67:28:08',
        icons: [<SiDogecoin size={18} key="1" />, <SiLitecoin size={18} key="2" className="ml-[-2px]" />],
    },
    {
        id: 3,
        title: 'USDT Pool',
        description: 'Stake USDT to Earn FLOCK',
        prize: '5,000,000',
        apr: '800.00%',
        vipApr: '960.00%',
        totalStaked: '-- USDT',
        totalParticipants: '--',
        timeRemaining: '67:28:08',
        icons: [
            <SiLitecoin fill="#10d173" size={18} key="1" />,
            <SiLitecoin size={18} key="2" className="ml-[-2px]" />,
        ],
    },
];

const stakingData = [
    {
        icon: <SiLitecoin size={44} />,
        title: "Flock",
        description:
            "Federated Learning on Blockchain (FLock), which anchors our business goals and the one",
        tags: ["Website", "Whitepaper", "Rules"],
        prizePool: "10,00,000",
        apr: "960.00%",
        stakingStartsIn: { days: "02", hours: "18", minutes: "17", seconds: "55" },
        stakingPeriod: "12-31 12:00 ~ 01-07 12:00 UTC",
        listingStartsIn: { days: "02", hours: "18", minutes: "17", seconds: "55" },
    },
];

function StakeEarn() {
    return (
        <div className='bg-[#F5F7FA]'>
            <div className="container">
                <p className="text-[24px] font-semibold py-4">Stake and Earn Tokens for Free</p>
                <div className="rounded-lg bg-white border-[#EFB81C] border-t-[3px] px-6 pb-6">
                    <p className='relative left-4 mb-2 rounded-bl-md flex justify-end self-end'><p className='bg-[#EFB81c] bg-opacity-10 font-medium text-[#EFB81c] px-2'>Coming soon</p></p>
                    <div className="flex gap-3 w-full">
                        <div className="w-1/4">
                            {stakingData.map((item, index) => (
                                <div key={index}>
                                    <div className="flex gap-3 items-center">
                                        {item.icon}
                                        <p className="text-[24px] font-semibold">{item.title}</p>
                                    </div>
                                    <p className="line-clamp-2 text-sm pt-2">{item.description}</p>
                                    <div className="flex gap-1 my-3">
                                        {item.tags.map((tag, idx) => (
                                            <span
                                                key={idx}
                                                className="border rounded-full px-2 text-[#81858c]"
                                            >
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                    <div>
                                        <span className="text-[#81858c]">Prize Pool (FLOCK)</span>
                                        <p className="text-lg font-semibold">{item.prizePool}</p>
                                    </div>
                                    <div className="mt-4">
                                        <span className="underline underline-offset-2 text-[#81858c] decoration-dashed">
                                            APR
                                        </span>
                                        <p className="text-lg font-semibold">{item.apr}</p>
                                    </div>
                                    <div>
                                        <span className="text-[#81858c]">Staking Starts In</span>
                                        <div className="flex gap-2 my-2">
                                            {Object.entries(item.stakingStartsIn).map(([key, value]) => (
                                                <div key={key} className="flex">
                                                    <span className="font-medium text-sm bg-gray-100 px-1 rounded-sm">
                                                        {value}
                                                    </span>
                                                    <p className="opacity-70 font-medium">{key.charAt(0).toUpperCase()}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="flex gap-3 my-4">
                                        <span className="underline underline-offset-2 text-[#81858c] decoration-dashed">
                                            Staking Period
                                        </span>
                                        <span>{item.stakingPeriod}</span>
                                    </div>
                                    <span className="text-[#81858c]">Listing Starts In</span>
                                    <div className="flex gap-2 my-2">
                                        {Object.entries(item.listingStartsIn).map(([key, value]) => (
                                            <div key={key} className="flex">
                                                <span className="font-medium text-sm bg-gray-100 px-1 rounded-sm">
                                                    {value}
                                                </span>
                                                <p className="opacity-70 font-medium">{key.charAt(0).toUpperCase()}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-full flex border rounded-2xl p-[24px]">
                            {pools.map((pool) => (
                                <div key={pool.id} className="w-1/3 border-r px-4 last:border-r-0">
                                    <div className="flex flex-col items-center">
                                        <p className="text-xl font-semibold">{pool.title}</p>
                                        <span className="text-[#81858c]">{pool.description}</span>
                                        <div className="bg-gray-100 h-12 w-12 justify-center p-1 mt-2 flex items-center rounded-full">
                                            {pool.icons}
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center mt-4">
                                        <span className="text-[#81858c]">Prize Pool (FLOCK)</span>
                                        <p className="text-xl font-semibold">{pool.prize}</p>
                                    </div>
                                    <div className="flex justify-between w-full mt-6">
                                        <span className="underline underline-offset-2 text-[#81858c] decoration-dashed">APR</span>
                                        <div className="flex gap-1 underline underline-offset-4 text-[#81858c] decoration-dashed">
                                            <span className="text-black font-medium">{pool.apr}</span>
                                            <span className="font-medium">( </span>
                                            <span className="flex font-medium items-center gap-1">
                                                <img src={vip} className="w-[28px] h-[14px]" alt="VIP" />
                                                <span>{pool.vipApr}</span>
                                            </span>
                                            <span className="font-medium">)</span>
                                        </div>
                                    </div>
                                    <div className="flex justify-between w-full py-3">
                                        <span className="text-[#81858c]">Total Staked</span>
                                        <span className="font-medium">{pool.totalStaked}</span>
                                    </div>
                                    <div className="flex justify-between w-full">
                                        <span className="text-[#81858c]">Total Participants</span>
                                        <span className="font-medium">{pool.totalParticipants}</span>
                                    </div>
                                    <div className="flex items-center justify-center mt-12">
                                        <button className="bg-[#E9EDF2] rounded-lg py-1 px-10 flex flex-col items-center">
                                            <p className="font-semibold">Open for Staking in</p>
                                            <span className="text-[#81858c]">{pool.timeRemaining}</span>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='border flex justify-between w-full rounded-xl px-6 py-3 mt-5 bg-slate-100'>
                        <div className='flex items-center gap-2'>
                            <FaCoins /><span className='font-medium'>Invite your friends to Buycex Launchpool and earn from a <span className='text-sm text-[#EFB81C]'>30,000 USDT</span> prize pool!</span>
                        </div>
                        <div className='flex gap-1 items-center'>
                            <p className='text-sm text-[#EFB81C] font-medium'>Share</p>
                            <RiShareBoxFill fill='#EFB81C' />
                        </div>
                    </div>
                </div>
                <div className='border flex justify-between w-full rounded-xl px-6 py-6 mt-5 bg-white'>
                    <div className='flex items-center gap-2'>
                        <MdNotificationsActive size={30} fill='#EFB81C' /><span className='font-medium text-[16px]'>Subscribe to receive updates on upcoming Launchpool events!</span>
                    </div>
                    <button className='flex gap-1 bg-[#EFB81C] px-4 rounded-md items-center'>
                        <MdOutlineNotifications />
                        <p className='text-sm font-medium'>Subscribe</p>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default StakeEarn;