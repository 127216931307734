import React, { useEffect } from 'react'
import TotalValue from './TotalValue'
import MainTable from './Table/MainTable'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FinanceUserEarn } from 'store/actions/AssetsUserAction';

function FixedTermEarn() {
    const history = useHistory();
  const dispatch = useDispatch();
  
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  const { data, loading, error } = useSelector(state => state.financeUserEarn);
  useEffect(() => {
        
    dispatch(FinanceUserEarn(userID, userToken)); 
  }, [dispatch, userID, userToken, history]);
console.log("data",data);
  
  

    return (
        <div className='max-w-[1400px] mx-auto !p-0'>
            <div className='px-0 lg:px-3'>
            <TotalValue data={data}/>
            <MainTable/>
        </div>
        </div>
    )
}

export default FixedTermEarn