import React, { useState } from "react";
import TimerSwitch from "../SecuritSettingsComponents/SwitchToggler";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactivateAccountSecondPopup from "../reactivateAccount/reactivateAccountSecondPopup";
import {
    BsCheckCircleFill,
    BsPersonFillGear,
} from "react-icons/bs";
import { FaUserLock } from "react-icons/fa";

function AccountActivity() {
    const [secureLoggingDuration, setSecureLoggingDuration] = useState(false);
    const history = useHistory();
    const toggleSwitch = () => {
        setSecureLoggingDuration((prevState) => !prevState);
    };
    const [settings, setSettings] = useState([
        {
            label: "Secure Logging Duration",
            description:
                "If activated, the login session on the web will be logged out after one hour of inactivity.",
            isSet: secureLoggingDuration,
            type: "switch",
            icon: <FaUserLock size={20} />,
        },
        {
            label: "Account Management",
            description: "Disable or delete account",
            isSet: true,
            type: "management",
            icon: <BsPersonFillGear size={20} />,
        },
    ]);

    const [isReactivatePopupVisible, setIsReactivatePopupVisible] =
        useState(false);
    const reason = useSelector(
        (state) => state.authCheck?.authStatus?.freeze_reason
    );
    const status = useSelector((state) => state.authCheck?.authStatus?.status);
    const handleDisableClick = () => {
        history.push("/user/account/disable");
    };

    const handleDeleteClick = () => {
        history.push("/user/account/delete");
    };
    const handleReactivateClick = () => {
        if (reason !== "deleted" && status == 0) {
            setIsReactivatePopupVisible(true);
        } else {
            toast.error("Your account is already active");
        }
    };
    const handleClosePopup = () => {
        setIsReactivatePopupVisible(false);
    };
    return (
        <div>
            <div className="bg-[#FFFFFF] ">
                {settings.map((item, index) => (
                    <div
                        key={index}
                        className="flex flex-col md:flex-row justify-between items-start gap-4 md:gap-0 md:items-center py-[20px] px-4 rounded-xl mb-3 border hover:bg-[#F4F4F430] transition-all ease-out duration-300"
                    >
                        <div className="flex items-center gap-4">
                        <div className="bg-gray-100 p-2 rounded-full">
                                <span>{item.icon}</span>
                            </div>
                            <div className="flex flex-col">
                                <div>
                                    <span className="text-[14px] font-semibold">
                                        {item.label}
                                    </span>
                                </div>
                                <p className="text-[#15141590] text-[12px]">
                                    {item.description}
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-end w-full md:w-auto">
                            {item.type === "management" ? (
                                <div className="flex items-center gap-2">
                                    <button
                                        type="button"
                                        onClick={handleDisableClick}
                                        className="font-semibold px-[15px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[65px] rounded-md text-[12px] transition-all ease-out duration-300"
                                    >
                                        Disable
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleDeleteClick}
                                        className="font-semibold px-[15px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[65px] rounded-md text-[12px] transition-all ease-out duration-300"
                                    >
                                        Delete
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleReactivateClick}
                                        className="font-semibold px-[15px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[65px] rounded-md text-[12px] transition-all ease-out duration-300"
                                    >
                                        Reactivate
                                    </button>
                                </div>
                            ) : item.type === "switch" ? (
                                <TimerSwitch
                                    isChecked={secureLoggingDuration}
                                    onChange={toggleSwitch}
                                />
                            ) : item.type === "password" ? (
                                <div className="flex items-center gap-10">
                                    <div className="flex items-center gap-1">
                                        <BsCheckCircleFill
                                            size={10}
                                            fill={
                                                item.isSet
                                                    ? "#EFB81C"
                                                    : "#15141590"
                                            }
                                        />
                                        <span className="text-[10px] font-semibold text-[#15141580]">
                                            {item.isSet ? "Set" : "Not Set"}
                                        </span>
                                    </div>
                                    <button
                                        onClick={handleShowWithDrawPassword}
                                        className="font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                    >
                                        {item.isSet ? "Change" : "Set"}
                                    </button>
                                </div>
                            ) : (
                                <div className="flex items-center gap-10">
                                    <div className="flex items-center gap-1">
                                        <BsCheckCircleFill
                                            size={10}
                                            fill={
                                                item.isSet
                                                    ? "#EFB81C"
                                                    : "#15141590"
                                            }
                                        />
                                        <span className="text-[10px] font-semibold text-[#15141580]">
                                            {item.isSet ? "Set" : "Not Set"}
                                        </span>
                                    </div>
                                    <button
                                        onClick={() => handlePopupClick(item)}
                                        className="font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                    >
                                        {item.isSet ? "Change" : "Set"}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {isReactivatePopupVisible && (
                <ReactivateAccountSecondPopup onClose={handleClosePopup} />
            )}
        </div>
    );
}

export default AccountActivity;
