import React, { useState, useRef, useEffect } from "react";
import { RiBitCoinFill, RiMoneyDollarCircleFill } from "react-icons/ri";
import { Line } from "react-chartjs-2";
import {
    Chart,
    LinearScale,
    CategoryScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from "chart.js";
import { FaAngleRight } from "react-icons/fa";
import TradeNowPopup from "./TradeNowPopup";

Chart.register(
    LinearScale,
    CategoryScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

function LatestProject({ latest_projects }) {
    console.log("latest-projects:", latest_projects);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const textRef = useRef(null);
    const [isPopupActive, setIsPopupActive] = useState(false);
    const [tradeValue, setTradeValue] = useState(null);

    const showModal = (item) => {
        setIsPopupActive(true);
        setTradeValue({ ...item, icon: <RiBitCoinFill size={18} /> });
    };

    const handleCancel = () => {
        setIsPopupActive(false);
    };

    const handleOk = () => {
        setIsPopupActive(false);
    };

    useEffect(() => {
        if (textRef.current) {
            setIsTruncated(
                textRef.current.scrollHeight > textRef.current.clientHeight
            );
        }
    }, [isExpanded]);

    const handleMoreClick = () => {
        setIsExpanded(!isExpanded);
    };
    // console.log("latest-project:", data);
    const sectionsData = Array.isArray(latest_projects)
        ? latest_projects?.map((item) => ({
              icon: <RiBitCoinFill size={66} />, // Assuming a default icon for all items
              title: item.name,
              subtitle: item.coinname,
              description: item.content || "No description available.",
              stats: {
                  volume: item.num,
                  turnover: item.deal,
                  high: item.price,
                  low: item.min,
                  graphData: [parseFloat(item.price), parseFloat(item.min)], // Example graph data
                  graphColor: {
                      border: "#EF4444", // Default color
                      background: "rgba(239, 68, 68, 0.2)", // Default color
                  },
                  change: item.commission,
                  changeColor: "#EF4444", // Default color
              },
          }))
        : [];
    // Data for both sections
    const _sectionsData = [
        {
            icon: <RiBitCoinFill size={66} />,
            title: "CATGOLD",
            subtitle: "Cat Gold Miner",
            description:
                "Cat Gold Miner: Where Purr-fectly profitable! Dive into the world of feline gold mining where players become moguls of their own mining empires.",
            stats: {
                volume: "67.46M",
                turnover: "110.80K",
                high: "0.00184",
                low: "0.0080",
                graphData: [0.0018, 0.0017, 0.0016, 0.0015, 0.00162],
                graphColor: {
                    border: "#EF4444",
                    background: "rgba(239, 68, 68, 0.2)",
                },
                change: "-1.21%",
                changeColor: "#EF4444",
            },
        },
        {
            icon: <RiMoneyDollarCircleFill size={66} />,
            title: "HOLD",
            subtitle: "Holdcoin",
            description:
                "We are creating a web3 game while adhering to the investing philosophy of 'Hold,' providing a great user experience, and building a bridge and portal between Web2.",
            stats: {
                volume: "80.00M",
                turnover: "120.00K",
                high: "0.00200",
                low: "0.00150",
                graphData: [0.0015, 0.0016, 0.0017, 0.0018, 0.002],
                graphColor: {
                    border: "#22c55e",
                    background: "rgba(34, 197, 94, 0.2)",
                },
                change: "+1.21%",
                changeColor: "#22c55e",
            },
        },
    ];

    return (
        <div className="container mb-52">
            {sectionsData.map((section, index) => (
                <div key={index} className="border rounded-2xl px-6 py-4 my-10">
                    <div className="flex w-full justify-between">
                        <div className="text-[#22c55e] font-medium text-xs bg-[#22c55e] bg-opacity-10 px-2 py-1 flex rounded-md justify-self-start">
                            In Progress
                        </div>
                        <button
                            onClick={() => showModal(section)}
                            className="bg-[#EFB81C] px-3 py-0.5 rounded-full text-sm text-white"
                        >
                            Trade Now
                        </button>
                    </div>
                    {tradeValue !== null && (
                        <TradeNowPopup
                            isPopupActive={isPopupActive}
                            onOk={handleCancel}
                            onCancel={handleOk}
                            tradeValue={tradeValue}
                        />
                    )}
                    <div className="flex justify-between">
                        <div className="w-1/3">
                            <div>
                                <div className="flex items-center gap-3 mt-5">
                                    {section.icon}
                                    <div className="flex flex-col">
                                        <p className="font-bold text-2xl">
                                            {section.title}
                                        </p>
                                        <span className="text-gray-400">
                                            {section.subtitle}
                                        </span>
                                    </div>
                                </div>
                                <p>
                                    <p
                                        ref={textRef}
                                        className={`text-gray-600 text-sm font-medium mt-4 ${
                                            isExpanded ? "" : "line-clamp-2"
                                        }`}
                                    >
                                        {section.description}
                                    </p>
                                    {isTruncated && (
                                        <p
                                            onClick={handleMoreClick}
                                            className="inline-flex text-xs font-medium items-center text-[#EFB81C] cursor-pointer"
                                        >
                                            {isExpanded ? "Less" : "More"}{" "}
                                            <FaAngleRight className="ml-0.5" />
                                        </p>
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className="flex w-1/5 justify-between mt-10 self-start">
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col">
                                    <p className="text-sm text-gray-400">
                                        24H Volume({section.title})
                                    </p>
                                    <p className="font-medium">
                                        {section.stats.volume}
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-sm text-gray-400">
                                        24H Turnover(USDT)
                                    </p>
                                    <p className="font-medium">
                                        {section.stats.turnover}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col">
                                    <p className="text-sm text-gray-400">
                                        High(USDT)
                                    </p>
                                    <p className="font-medium">
                                        {section.stats.high}
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-sm text-gray-400">
                                        Low(USDT)
                                    </p>
                                    <p className="font-medium">
                                        {section.stats.low}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/6 self-start mt-10">
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col">
                                    <p className="text-sm text-gray-400">
                                        24H Volume(USDT)
                                    </p>
                                    <p className="font-medium flex gap-1">
                                        {section.stats.graphData.slice(-1)[0]}{" "}
                                        <p
                                            className="font-medium"
                                            style={{
                                                color: section.stats
                                                    .changeColor,
                                            }}
                                        >
                                            ({section.stats.change})
                                        </p>
                                    </p>
                                </div>
                                <div className="mt-4">
                                    <Line
                                        data={{
                                            labels: [
                                                "1D",
                                                "2D",
                                                "3D",
                                                "4D",
                                                "5D",
                                            ],
                                            datasets: [
                                                {
                                                    data: section.stats
                                                        .graphData,
                                                    borderColor:
                                                        section.stats.graphColor
                                                            .border,
                                                    backgroundColor:
                                                        section.stats.graphColor
                                                            .background,
                                                    fill: true,
                                                    tension: 0.3,
                                                },
                                            ],
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: { display: false },
                                                tooltip: { enabled: false },
                                            },
                                            scales: {
                                                x: { display: false },
                                                y: { display: false },
                                            },
                                        }}
                                        height={50}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default LatestProject;
