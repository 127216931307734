import Repository from "./Repository";

const CONVERT = '/Convert';
const FINANCE = '/Finance/giveBalanceTypesapi';

// Helper function to generate headers
const getHeaders = (userId, token) => ({
    'token': token,
    'id': userId,
    'Content-type': 'application/json'
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    async getBalanceTypes(userId, token) {

       
        try {
            const response = await Repository.get(FINANCE, {
                headers: getHeaders( token,userId)
            });

            console.log("response",response)
            return response;
        } catch (error) {
            throw error;
        }
    },
    async doTrade(payload, token) {
        console.log("Token:", token);
        console.log("Payload:", payload.user_id);
    
        try {
            // Create a FormData object to replicate the form-data behavior from Postman
            const formData = new FormData();
            formData.append('user_id', payload.user_id);
            formData.append('from_coin', payload.from_coin);
            formData.append('to_coin', payload.to_coin);
            formData.append('amount', payload.amount);
    
            // Send the payload using form-data and include the same headers as in Postman
            const response = await Repository.post(`${CONVERT}/doTrade`, formData, {
                headers: {
                    'TOKEN': token,  // Same token as in Postman
                    'ID': payload.user_id,  // User ID header
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                }
            });
    
            console.log("Response:", response);  // Log the response for debugging
            return response;
    
        } catch (error) {
            console.log("Error during trade:", error.message);  // Log error message
            throw error;  // Re-throw for further handling
        }
    },    
    
    
    callTrade(payload, token) {
        return Repository.post(`${CONVERT}/callTrade`, payload, {
            headers: {
                'token': token,
                'Content-type': 'application/json'
            }
        });
    },
    async calcEstimatedAssetvalueData(payload,token) {

        const response = await Repository.get(`${CONVERT}/calcEstimatedAssetvalueData?user_id=${payload.user_id}&conversion_coin=${payload.coinname}`, {
            headers: getHeaders(payload.user_id,payload.token)
        });
 
        return response;
    },


    async getConvertedAmount(from_coin, to_coin, amount, token, userId) {
        try {
        
            const formData = new FormData();
            formData.append('from_coin', from_coin);
            formData.append('to_coin', to_coin);
            formData.append('amount', amount);
    
            // Send the POST request
            const response = await Repository.post(`${CONVERT}/getConvertedAmount`, formData, {
                headers: {
                    'TOKEN': token,
                    'ID': userId,  
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            console.log("Converted Amount Response:", response);
            return response;
        } catch (error) {
            console.log("Error in getConvertedAmount:", error.message);
            throw error;
        }
    }
    
};
