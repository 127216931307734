import axios from "axios";
import Repository from "./Repository";

const endpoints = {
    login: "/Login/loginnew",
    reactiveEmailPasswordAuth: "/Login/reactiveEmailPasswordAuth",
    verifyG2faCode: "/Login/check2fanew",
    view2faGoogle: "/Login/view2faGoogle",
    email2FA: "/Login/email2fa",
    phone2FA: "/Login/phone2fa",
    confirm2faCodelogin: "/Login/confirm2facodelogin",
    doActiveFreezeAccount: "/Login/doActiveFreezedAccount",
    submit: "/Login/submit",

    signup: "/Login/emailregister",
    emailCode: "/Login/emailcode",
    verifyEmail: "/Login/confirmemailcode",
    resendEmailOtp: "/Login/resendcode",

    phoneSignup: "/Login/phoneregister",
    verifyPhone: "/Login/confirmphonecode",
    verifyPhoneNew: "/Login/bindphonenumber",
    verifyPhonePass: "/Login/confirmphonecodeNew",
    resendPhoneOtp: "/Login/phoneresendcode",

    checkUser: "/Login/chkuser",
    forgotCode: "/Login/forgotcode",
    verifySaveEmail: "/Login/verifySaveEmail",
    verifyCodeFromEmail: "/Login/verifyCodeFromEmail",
    setEmail: "/Login/setEmail",
    sendCodeToEmail: "/Login/sendCodeToEmail",
    confirmCode: "/Login/confirmcode",
    phoneResetPassword: "/Login/phoneResetPassword",
    findPassword: "/Login/findpwd",
    findPayPassword: "/Login/findpaypwd",
    checkRegistration: "/Login/check_reg",
    sendPhoneCode: "/Login/sencellphone",
    sendForgotCode: "/Login/sendForgetCode",
    forgotSave: "/Login/forgetsave",
    upregister2: "/Login/upregister2",
    paypassword: "/Login/paypassword",
    changeFundPassword: "/Login/changeFundPassword",
    logout: "/Login/loginout", //Get

    //get code on email
    emailRegister: "/Login/emailregister",

    //google api
    googleEndPoint: "/Login/googleLoginNew",

    resetPasswordEmail: "/Login/findpwdemail",
};

const AuthRepository = {
    // Login
    login(payload) {
        // const formData = new FormData();
        // formData.append("username", payload?.username);
        // formData.append("password", payload?.password);
        return Repository.post(`${endpoints.login}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    reactiveEmailPasswordAuth(payload) {
        return Repository.post(
            `${endpoints.reactiveEmailPasswordAuth}`,
            payload,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
    },

    forgotCode(payload) {
        return Repository.post(`${endpoints.forgotCode}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    verifySaveEmail(payload) {
        return Repository.post(`${endpoints.verifySaveEmail}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    verifyCodeFromEmail(payload) {
        return Repository.post(`${endpoints.verifyCodeFromEmail}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    setEmail(payload) {
        return Repository.post(`${endpoints.setEmail}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    sendCodeToEmail(payload) {
        return Repository.post(`${endpoints.sendCodeToEmail}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    sendForgotCode(payload) {
        return Repository.post(`${endpoints.sendForgotCode}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    confirmCode(payload) {
        return Repository.post(`${endpoints.confirmCode}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    phoneResetPassword(payload) {
        return Repository.post(`${endpoints.phoneResetPassword}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    // User Registration with Email
    signup(payload) {
        return Repository.post(`${endpoints.signup}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    verifyEmail(payload) {
        const formData = new FormData();
        formData.append("sid", payload?.sid);
        formData.append("code", payload?.code.toString());
        return Repository.post(`${endpoints.verifyEmail}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    resendEmailOtp(payload) {
        return Repository.post(`${endpoints.resendEmailOtp}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    phoneSignup(payload) {
        return Repository.post(`${endpoints.phoneSignup}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    verifyPhone(payload) {
        return Repository.post(`${endpoints.verifyPhone}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    verifyPhoneNew(payload) {
        return Repository.post(`${endpoints.verifyPhoneNew}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    verifyPhonePass(payload) {
        return Repository.post(`${endpoints.verifyPhonePass}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    resendPhoneOtp(payload) {
        return Repository.post(`${endpoints.resendPhoneOtp}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    verifyG2faCode(payload) {
        return Repository.post(`${endpoints.verifyG2faCode}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    // view2faGoogle(payload) {
    //     const formData = new FormData();
    //     formData.append("userid", payload?.userid);
    //     formData.append("type", payload?.type);
    //     formData.append("ga_login", payload?.ga_login);
    //     formData.append("ga_transfer", payload?.ga_transfer);
    //     formData.append("ga", payload?.ga);
    //     formData.append("secret", payload?.secret);

    //     return Repository.post(`${endpoints.view2faGoogle}`, formData, {
    //         headers: { "Content-Type": "multipart/form-data" },
    //     });
    // },
    view2faGoogle(payload) {
        const formData = new FormData();
        formData.append("userid", payload?.userid);
        formData.append("type", payload?.type);
        formData.append("ga_login", payload?.ga_login);
        formData.append("ga_transfer", payload?.ga_transfer);
        formData.append("ga", payload?.ga);
        formData.append("secret", payload?.secret);

        return Repository.post(`${endpoints.view2faGoogle}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    emailRegister(payload) {
        return Repository.post(`${endpoints.emailRegister}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    googleLogin({ email }) {
        const formData = new FormData();
        formData.append("email", email);

        return Repository.post(`${endpoints.googleEndPoint}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    googleRegister({ email, username }) {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("username", username);

        return Repository.post(`${endpoints.googleEndPoint}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    email2fa(payload) {
        const formData = new FormData();
        formData.append("user_id", payload);
        return Repository.post(`${endpoints.email2FA}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    phone2fa(payload) {
        const formData = new FormData();
        formData.append("user_id", payload);
        return Repository.post(`${endpoints.phone2FA}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    confirm2faCodelogin(payload) {
        return Repository.post(`${endpoints.confirm2faCodelogin}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    doActiveFreezeAccount(payload) {
        return Repository.post(`${endpoints.doActiveFreezeAccount}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    setFundPassword(payload) {
        return Repository.post(`${endpoints.upregister2}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    paypassword(payload) {
        return Repository.post(`${endpoints.paypassword}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    changeFundPassword(payload) {
        const { ID, TOKEN, ...rest } = payload;

        return Repository.post(
            `${endpoints.changeFundPassword}`,
            { ...rest, ID },
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    TOKEN: TOKEN,
                    ID: ID,
                },
            }
        );
    },

    resetPasswordEmail(payload) {
      
        const formData = new FormData();
        formData.append("email", payload.email);
        formData.append("email_verify", payload.email_verify);
        formData.append("password", payload.password);
        formData.append("repassword", payload.repassword);

     
        return Repository.post(`${endpoints.resetPasswordEmail}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
};

export default AuthRepository;
