import React from 'react'
import { AiFillBank } from 'react-icons/ai'
import { FaCheck } from 'react-icons/fa'
import { MdOutlineMenuBook } from 'react-icons/md'
import Navbar from './navbar'

function TermDeposit() {
    return (
        <>
        <div className='bg-black'>
            <div className='container'>
                <div className='py-8'>
                    <p className='text-white font-semibold text-4xl py-2'>Simple Earn</p>
                    <div className='flex gap-8 my-3'>
                        <div className='flex items-center gap-2'><FaCheck fill='#2d9d76' /><p className='font-medium text-white'>Principal Protected</p></div>
                        <div className='flex items-center gap-2'><FaCheck fill='#2d9d76' /><p className='font-medium text-white'>Flexible Term</p></div>
                    </div>
                    <div className='flex items-center gap-2'><FaCheck fill='#2d9d76' /><p className='font-medium text-white'>Compound Interest</p></div>
                <div className='flex gap-14 py-6'>
                <div>
                <p className='text-3xl text-[#30bd86] font-bold'>1.90B</p>
                <p className='text-gray-500 font-medium text-sm py-2'>Total Leading(USDT)</p>
                </div>
                <div>
                <div className='flex gap-2'><p className='text-3xl text-[#30bd86] font-bold'>10.93%</p><p className='bg-[#30bd86] self-center text-sm text-white font-semibold px-2 py-0.5 rounded-md'>+40.00%</p></div>
                <p className='text-gray-500 font-medium text-sm py-2'>7-Day APR for USDT</p>
                </div>
                </div>
                <div className='flex gap-5 mb-5'>
                <button className='bg-[#EFB81C] rounded-full px-6 flex text-white font-medium items-center py-2 gap-1'><AiFillBank/>Proof of Reserves</button>
                <button className='bg-[#EFB81C] rounded-full px-6 flex text-white font-medium items-center py-2 gap-1'><MdOutlineMenuBook/>About Simple Earn</button>
                </div>
                </div>
            </div>
        </div>
        <Navbar/>
        </>
    )
}

export default TermDeposit