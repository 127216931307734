import React, { useState } from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function AllCoins({ data }) {
    const history = useHistory();
    const handleCashIn = () => {
        history.push("/fiat/deposit");
    };

    let coinList = [];
    if (Object.keys(data).length > 0) {
        coinList = Object.keys(data).map((key, index) => ({
            id: index,
            icon: data[key].img || "default_coin_image.png",
            coinName: data[key].name.toUpperCase(),
            coinType: data[key].title.match(/\[([^\[\]]+)\]/)?.[1].trim() || "Unknown",
            amount: parseFloat(data[key].amount),
            coinPrice: parseFloat(data[key].price),
            market: parseFloat(data[key].market_change),
        }));
    }
    console.log("dasdsf",coinList)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const paginatedCoinList = coinList.slice(startIndex, endIndex);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div className='w-full'>
            {/* Header row */}
            <div className='flex w-full mb-8'>
                <div className='flex items-center gap-1 md:w-1/5 w-[25%]'>
                    <p className='text-xs text-[#929AA5]'>Popular Coins</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 md:w-1/5 w-[25%]'>
                    <p className='text-xs text-[#929AA5]'>Amount</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 md:w-1/5 w-[25%]'>
                    <p className='text-xs text-[#929AA5]'>Coin Price</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 md:w-1/5 w-[25%]'>
                    <p className='text-xs text-[#929AA5]'>24H Change</p>
                </div>
                <div className='md:flex hidden justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Trade</p>
                </div>
            </div>

            {/* Map through popular coins array */}
            {paginatedCoinList.length > 0 && paginatedCoinList.map((data) => (
                <div key={data.id} className='flex w-full items-center mb-4'>
                    <div className='flex gap-2 md:w-1/5 w-[25%]'>
                        <div className='flex items-center gap-1'>
                            <img
                                src={`https://api.buycex.com/Upload/coin/${data.icon}`}
                                alt=""
                                className="w-6 h-6 rounded-full"
                            />
                            <div className="flex flex-col">
                                <span className="md:text-[16px] text-sm font-bold ">{data.coinName}</span>
                                <span className="text-[14px] text-[#aaaaaa]">
                                    {data.coinType}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='flex md:flex-row flex-col md:items-center justify-end gap-1 md:w-1/5 w-[25%]'>
                        <p className='text-[14px] md:text-[16px] text-end font-normal'>{data.amount}</p>  <span className="text-end text-[#aaaaaa]">
                            {data.coinName}
                        </span>
                    </div>
                    <div className='flex flex-col gap-1 md:w-1/5 w-[25%]'>
                        <p className="text-[14px] md:text-[16px] text-end font-normal">
                            ${data.coinPrice}
                        </p>
                    </div>


                    <div className='flex flex-col gap-1 md:w-1/5 w-[25%]'>
                        <div className='flex gap-2 justify-end items-center'>
                            <p
                                className={`text-[14px] md:text-[16px] font-semibold ${data.market < 0 ? 'text-red-500' : 'text-green-500'
                                    }`}>
                                {data.market != null && !isNaN(data.market)
                                    ? `${data.market}%`
                                    : "0%"}
                            </p>
                        </div>
                    </div>

                    <div className='md:flex hidden justify-end w-1/5'>
                        <button className='text-sm text-[#EFB81C] underline' onClick={handleCashIn}>Cash In</button>
                    </div>
                </div>
            ))}
            <div className="flex justify-center mt-4">
                {Array.from({ length: Math.ceil(coinList.length / itemsPerPage) }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-3 py-1 mx-1 text-xs font-semibold border rounded ${currentPage === index + 1 ? 'bg-yellow-500 text-black' : 'bg-gray-100 text-gray-700'}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>

        </div>



    );
}

export default AllCoins;
