import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

import { toggleSecureLogging } from "store/actions/userSecuritySettingsAction";
import {
    verifyG2faCode,
    setFundPassword,
    checkFundPassword,
    changeFundPassword,
} from "store/actions/authAction";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { IoIosCash } from "react-icons/io";

import {
    BsCheckCircleFill,
    BsXCircleFill,
    BsCreditCard2BackFill,
    BsFileLock2Fill,
    BsGoogle,
    BsPersonFillGear,
    BsShieldFillCheck,
} from "react-icons/bs";
import toast from "react-hot-toast";
import GoogleAccountBinding from "../securitySettings/googleAccountBinding";
import AntiPhishingPopup from "../securitySettings/antiPhishing";
import WithdrawPassword from "../withdraw-password/WithdrawPassword";
import ReactivateAccountPopup from "../reactivateAccount/reactivateAccountPopup";
import TimerSwitch from "./SwitchToggler";
import AntiPhishingModal from "./AntipushingModal";
import SetFundPasswordModal from "./SetFundPasswordModal";
import ChangeFundPasswordModal from "./ChangeFundPasswordModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
    fetchAuthStatus,
    saveAntiPishing,
} from "store/actions/userSecuritySettingsAction";
import { fetchAntiPhishingCode } from "store/actions/userSecuritySettingsAction";
import EmailVerificationModal from "./EmailVerificationModal";
import { getUserWallets } from "store/actions/walletaddreswhitelistAction";

const AdvancedSettings = ({
    authStatus,
    antiPhishingNotVerified,
    withdrawalPasswordValue,
    g2faVerificationValue,
    secureLoggingDuration,
    setSecureLoggingDuration,
    googleAccountBinding,
    antiPhishingValue,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { gftOTP } = location.state || {};
    const secureLogging = useSelector(
        (state) => state.secureLogin.secureLogging
    );
    const [isGoogleBindingPopupOpen, setIsGoogleBindingPopupOpen] =
        useState(false);
    const [isAntiPhishingPopupOpen, setIsAntiPhishingPopupOpen] =
        useState(false);
    const [showWithDrawPassword, setShowWithDrawPassword] = useState(false);
    const [isReactivatePopupVisible, setIsReactivatePopupVisible] =
        useState(false);

    const [fundPasswordSettings, setFundPasswordSettings] = useState({
        password: "",
        confirmPassword: "",
        g2faOtp: "",
    });
    const [changefundPasswordSettings, setChangeFundPasswordSettings] =
        useState({
            currentpassword: "",
            newPassword: "",
            confirmNewPassword: "",
            g2faOtp: "",
        });
    const reason = useSelector(
        (state) => state.authCheck?.authStatus?.freeze_reason
    );
    const status = useSelector((state) => state.authCheck?.authStatus?.status);
    const antiphishing = useSelector(
        (state) => state.authCheck?.authStatus?.antiphishing
    );
    const { loading, antiPhishingCode, error } = useSelector(
        (state) => state.antiPhishingCode
    );

    useEffect(() => {
        console.log("gftOTP", gftOTP);

        if (gftOTP !== undefined && gftOTP === false) {
            handleSetFundPasswordClick();
        }
    }, [gftOTP]);

    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    const { wallets } = useSelector((state) => state.walletwhitelist);
    useEffect(() => {
        dispatch(fetchAntiPhishingCode(ID, TOKEN));
    }, [dispatch]);
    console.log("dtaa", antiPhishingCode);
    console.log(status, reason);
    const [isCodeVisible, setIsCodeVisible] = useState(false);

    const toggleCodeVisibility = () => {
        setIsCodeVisible(!isCodeVisible);
    };

    const handleReactivateClick = () => {
        if (reason !== "deleted" && status == 0) {
            setIsReactivatePopupVisible(true);
        } else {
            toast.error("Your account is already active");
        }
    };

    const handleClosePopup = () => {
        setIsReactivatePopupVisible(false);
    };
    const [whitelistEnabled, setWhitelistEnabled] = useState(false);

    const [settings, setSettings] = useState([
        {
            label: "Anti-Phishing Code",
            description:
                "It will appear in all official emails from BuyCex to prevent phishing attempts.",
            isSet: antiphishing,
            icon: <BsShieldFillCheck size={24} />,
            type: "popup",
        },
        {
            label: "Fund Password",
            description:
                "Used during withdrawal, P2P trading, and other security verification.",
            isSet: withdrawalPasswordValue,
            icon: <BsCreditCard2BackFill size={24} />,
            type: "password",
        },
        {
            label: "Withdrawal Address Whitelist",
            description: "Once enabled, the whitelisted withdrawal addresses.",
            isSet: whitelistEnabled, // Use a different state for Whitelist
            icon: <IoIosCash size={24} />,
            type: "password",
        },
    ]);

    const handleGoogleBindingOpenPopup = () => {
        setIsGoogleBindingPopupOpen(true);
        setIsAntiPhishingPopupOpen(false); // Ensure Anti-Phishing popup is closed
    };

    const handleGoogleBindingClosePopup = () => {
        setIsGoogleBindingPopupOpen(false);
    };

    const handleAntiPhishingOpenPopup = () => {
        setIsAntiPhishingPopupOpen(true);
        setIsGoogleBindingPopupOpen(false); // Ensure Google Binding popup is closed
    };

    const handleAntiPhishingClosePopup = () => {
        setIsAntiPhishingPopupOpen(false);
    };

    const handleWithDrawPassClose = () => {
        setShowWithDrawPassword(false);
    };

    const handleActivate = () => {
        console.log("Google Account Binding Activated");
        setIsGoogleBindingPopupOpen(false); // Close the popup after activation
    };

    const handleSaveAntiPhishingCode = (code) => {
        console.log("Anti-Phishing Code Saved:", code);

        const payload = {
            ID,
            TOKEN,
            antiphishing: `${code.antiFishingCode}`,
        };
        const ssPayload = {
            user_id: localStorage.getItem("user_id_2fa") || ID,
            ga: parseInt(code?.googleCode),
        };

        dispatch(verifyG2faCode(ssPayload, () => {}));

        dispatch(
            saveAntiPishing(
                payload,
                () => {
                    setIsAntiPhishingPopupOpen(false); // Close the popup after saving
                },
                () => {
                    console.log("failed anti fishing");
                }
            )
        );
    };

    const handleShowWithDrawPassword = () => {
        if (!authStatus.ga) {
            toast.error("Please Set 2FA first to proceed.");
            return;
        }
        setShowWithDrawPassword(true);
    };

    useEffect(() => {
        setSettings((prevSettings) => [
            { ...prevSettings[0], isSet: !!authStatus.antiphishing },
            { ...prevSettings[1], isSet: !!authStatus.paypassword },
            ...prevSettings.slice(2),
        ]);
    }, [authStatus, secureLogging]);

    useEffect(() => {
        const check_fund_password = () => {
            dispatch(checkFundPassword(ID));
        };
        check_fund_password();
    }, [ID]);

    const toggleSwitch = () => {
        setSecureLoggingDuration((prevState) => !prevState);
    };

    const handlePopupClick = (item) => {
        if (item.label === "Google Account Binding") {
            handleGoogleBindingOpenPopup();
        } else if (item.label === "Anti-Phishing Code") {
            handleAntiPhishingClick();
        } else if (item.label === "Anti-Phishing Code") {
            if (!authStatus.email) {
                toast.error("Email is required");
            } else {
                handleAntiPhishingOpenPopup();
            }
        }
    };

    const handleDisableClick = () => {
        history.push("/user/account/disable");
    };

    const handleDeleteClick = () => {
        history.push("/user/account/delete");
    };

    const [isSetFundPasswordModalVisible, setIsSetFundPasswordModalVisible] =
        useState(false);
    const [
        isChangeFundPasswordModalVisible,
        setIsChangeFundPasswordModalVisible,
    ] = useState(false);

    const handleSetFundPasswordClick = () => {
        if (!authStatus.ga) {
            toast.error("Please Set 2FA first to proceed.");
            return;
        }
        setIsSetFundPasswordModalVisible(true);
    };

    const handleAntiPhishingClick = () => {
        if (!authStatus.ga || !g2faVerificationValue) {
            // Check if 2FA is active
            toast.error("Please Set 2FA first to proceed.");
            return;
        }
        setIsAntiPhishingPopupOpen(true); // Open AntiPhishing modal
    };

    const handleChangeFundPasswordClick = () => {
        if (!authStatus.ga) {
            toast.error("Please Set 2FA first to proceed.");
            return;
        }
        setIsChangeFundPasswordModalVisible(true);
    };

    const [
        isEmailVerificationModalVisible,
        setIsEmailVerificationModalVisible,
    ] = useState(false);

    const handleResetFundPasswordClick = () => {
        if (!authStatus.ga) {
            toast.error("Please Set 2FA first to proceed.");
            return;
        }
        setIsEmailVerificationModalVisible(true);
    };

    const handleEmailVerificationSuccess = () => {
        setIsEmailVerificationModalVisible(false);
        setIsSetFundPasswordModalVisible(true);
    };

    const handleEmailVerificationClose = () => {
        setIsEmailVerificationModalVisible(false);
    };

    const handleSetFundPasswordClose = () => {
        setIsSetFundPasswordModalVisible(false);
        setFundPasswordSettings({
            password: "",
            confirmPassword: "",
            g2faOtp: "",
        });
    };

    const handleChangeFundPasswordClose = () => {
        setIsChangeFundPasswordModalVisible(false);
    };

    const handleFundPasswordSetup = async () => {
        const payload = {
            user_id: localStorage.getItem("user_id_2fa") || ID,
            ga: parseInt(fundPasswordSettings?.g2faOtp),
        };

        dispatch(
            verifyG2faCode(payload, () => {
                dispatch(
                    setFundPassword({
                        user_id: ID,
                        paypassword: fundPasswordSettings?.password,
                        repaypassword: fundPasswordSettings?.confirmPassword,
                    })
                );
            })
        );

        // handleSetFundPasswordClose();
    };

    const changeFundPasswordHandler = () => {
        const payload = {
            user_id: localStorage.getItem("user_id_2fa") || ID,
            ga: parseInt(changefundPasswordSettings?.g2faOtp),
        };

        dispatch(
            verifyG2faCode(payload, () => {
                dispatch(
                    changeFundPassword({
                        ID: ID,
                        TOKEN: TOKEN,
                        oldpaypassword:
                            changefundPasswordSettings.currentpassword,
                        newpaypassword: changefundPasswordSettings.newPassword,
                        repaypassword:
                            changefundPasswordSettings.confirmNewPassword,
                    })
                );
            })
        );
    };

    useEffect(() => {
        if (ID && TOKEN) {
            dispatch(getUserWallets(TOKEN, ID));
        }
    }, [dispatch, TOKEN, ID]);

    console.log("wallets", wallets.length);

    return (
        <div>
            <div className="font-bold text-[18px] my-3">
                <span className="text-[24px] font-semibold uppercase">
                    Advanced Settings
                </span>
            </div>
            <div className="bg-[#FFFFFF] border rounded-xl px-4 py-2">
                {settings.map((item, index) => (
                    <div
                        key={index}
                        className={`flex justify-between items-center py-6 transition-all ease-out duration-300 ${
                            index === settings.length - 1
                                ? ""
                                : "border-b hover:bg-[#F4F4F430]"
                        }`}
                    >
                        <div className="flex items-start lg:items-center gap-2 w-full justify-between">
                            <div className="bg-[#F4F4F4] self-start rounded-full p-2">
                                <span>{item.icon}</span>
                            </div>
                            <div className="flex flex-col lg:flex-row w-full gap-4 lg:gap-0 items-start lg:items-center justify-between">
                                <div className="flex flex-col w-full">
                                    <div>
                                        <span className="text-[18px] font-semibold">
                                            {item.label}
                                        </span>
                                    </div>
                                    <p className="text-[#15141590] text-[14px]">
                                        {item.description}
                                    </p>
                                </div>

                                <div className="w-full">
                                    {item.type === "password" ? (
                                        <div className="flex items-center gap-10 w-full lg:justify-end justify-between">
                                            <div className="flex items-center gap-1">
                                                {/* Add condition to display relevant status based on the label */}
                                                {item.label ===
                                                "Fund Password" ? (
                                                    <>
                                                        {item.isSet ? (
                                                            <BsCheckCircleFill
                                                                size={12}
                                                                fill="#008000"
                                                            />
                                                        ) : (
                                                            <BsXCircleFill
                                                                size={12}
                                                                color="#808080"
                                                            />
                                                        )}
                                                        <span className="text-[12px] font-semibold text-[#15141580]">
                                                            {item.isSet
                                                                ? "Enabled"
                                                                : "Not Configure"}
                                                        </span>
                                                    </>
                                                ) : item.label ===
                                                  "Withdrawal Address Whitelist" ? (
                                                    <>
                                                        {/* Display Saved Wallet Count */}
                                                        {wallets.length > 0 ? (
                                                            <div className="flex items-center">
                                                                <BsCheckCircleFill
                                                                    size={12}
                                                                    fill="#008000"
                                                                />
                                                                <span className="ml-1 text-[12px] font-semibold text-[#15141580]">
                                                                    {
                                                                        wallets.length
                                                                    }{" "}
                                                                    Saved Wallet
                                                                    {wallets.length >
                                                                    1
                                                                        ? "s"
                                                                        : ""}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div className="flex items-center">
                                                                <BsXCircleFill
                                                                    size={12}
                                                                    color="#808080"
                                                                />
                                                                <span className="ml-1 text-[12px] font-semibold text-[#15141580]">
                                                                    0 Saved
                                                                    Wallet
                                                                </span>
                                                            </div>
                                                        )}
                                                    </>
                                                ) : null}
                                            </div>

                                            {/* Add action buttons based on labels */}
                                            {item.label === "Fund Password" ? (
                                                <div className="flex items-center gap-2">
                                                    {item.isSet ? (
                                                        <>
                                                            <button
                                                                onClick={
                                                                    handleChangeFundPasswordClick
                                                                }
                                                                className="font-bold sm:px-[15px] px-[8px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[50px] sm:min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                                            >
                                                                Change
                                                            </button>
                                                            <button
                                                                onClick={
                                                                    handleResetFundPasswordClick
                                                                }
                                                                className="font-bold sm:px-[15px] px-[8px] py-2 border-2 hover:border-[#EFB81C] hover:bg-[#EFB81C] text-[#151415] min-w-[50px] sm:min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                                            >
                                                                Reset
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <button
                                                            onClick={
                                                                handleSetFundPasswordClick
                                                            }
                                                            className="font-bold sm:px-[15px] px-[8px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[50px] sm:min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                                        >
                                                            Set
                                                        </button>
                                                    )}
                                                </div>
                                            ) : item.label ===
                                              "Withdrawal Address Whitelist" ? (
                                                <Link
                                                    to="/user/withdraw-address-whitelist"
                                                    className="font-bold sm:px-[15px] px-[8px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[50px] sm:min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                                >
                                                    Manage
                                                </Link>
                                            ) : null}
                                        </div>
                                    ) : (
                                        // Code for anti-phishing or other types
                                        <div className="flex items-center gap-10 w-full lg:justify-end justify-between">
                                            <div className="flex items-center gap-1 w-full">
                                                {item.isSet ? (
                                                    <BsCheckCircleFill
                                                        size={12}
                                                        fill="#008000"
                                                    />
                                                ) : (
                                                    <BsXCircleFill
                                                        size={12}
                                                        color="#808080"
                                                    />
                                                )}
                                                <span className="text-[12px] font-semibold text-[#15141580]">
                                                    {item.isSet
                                                        ? "Enabled"
                                                        : "Not Configure"}
                                                </span>

                                                {antiPhishingCode?.antiphishing && (
                                                    <div className="flex items-center ml-auto">
                                                        <span className="text-[12px] flex items-center font-semibold text-[#15141580] ml-1">
                                                            {isCodeVisible
                                                                ? antiPhishingCode.antiphishing
                                                                : "••••••••"}
                                                        </span>
                                                        <button
                                                            onClick={
                                                                toggleCodeVisibility
                                                            }
                                                            className="text-[#151415] ml-2"
                                                        >
                                                            {isCodeVisible ? (
                                                                <FiEye
                                                                    size={12}
                                                                />
                                                            ) : (
                                                                <FiEyeOff
                                                                    size={12}
                                                                />
                                                            )}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>

                                            <button
                                                onClick={() =>
                                                    handlePopupClick(item)
                                                }
                                                className="font-bold sm:px-[15px] px-[8px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[50px] sm:min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                            >
                                                {item.isSet ? "Change" : "Set"}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {isGoogleBindingPopupOpen && (
                <GoogleAccountBinding
                    onClose={handleGoogleBindingClosePopup}
                    onActivate={handleActivate}
                />
            )}
            {/* AntiPhishing */}
            {isAntiPhishingPopupOpen && g2faVerificationValue && (
                <AntiPhishingModal
                    visible={isAntiPhishingPopupOpen}
                    onClose={handleAntiPhishingClosePopup}
                    onSave={(code) => handleSaveAntiPhishingCode(code)}
                />
            )}

            {showWithDrawPassword && authStatus.ga && (
                // <div className="absolute w-[100%] left-0 top-0 h-full">
                <WithdrawPassword
                    isOpen={showWithDrawPassword}
                    onClose={handleWithDrawPassClose}
                />
                // </div>
            )}
            {isReactivatePopupVisible && (
                <ReactivateAccountPopup onClose={handleClosePopup} />
            )}
            <SetFundPasswordModal
                visible={isSetFundPasswordModalVisible}
                onClose={handleSetFundPasswordClose}
                fundPasswordSettings={fundPasswordSettings}
                setFundPasswordSettings={setFundPasswordSettings}
                onSet={handleFundPasswordSetup}
            />

            <ChangeFundPasswordModal
                visible={isChangeFundPasswordModalVisible}
                onClose={handleChangeFundPasswordClose}
                changefundPasswordSettings={changefundPasswordSettings}
                setChangeFundPasswordSettings={setChangeFundPasswordSettings}
                onConfirm={changeFundPasswordHandler}
                handleResetFundPasswordClick={handleResetFundPasswordClick}
            />
            <EmailVerificationModal
                isVisible={isEmailVerificationModalVisible}
                onClose={handleEmailVerificationClose}
                onSuccess={handleEmailVerificationSuccess}
            />
        </div>
    );
};

export default AdvancedSettings;
