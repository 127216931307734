import Repository from "./Repository";

const CurrentOrderEndPoint = "/issue/log";
const OrderHistoryEndPoint = "/issue/alllogs";
const LgProductEndPoint = "/issue/index";
export default {
    currentOrder(userId, token) {
        return Repository.get(`${CurrentOrderEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },

    orderHistory(userId, token) {
        return Repository.get(`${OrderHistoryEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    
    lgProjects(userId, token) {
        return Repository.get(`${LgProductEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
};
