import React,{useState,useEffect} from 'react';
import image20Million from '../../assets/img/Aboutsection4.png'; 
import { Link } from 'react-router-dom/cjs/react-router-dom';

const ProjectCard = ({ project }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 27,
    hours: 0,
    minutes: 30,
    seconds: 56,
  });

  const tick = () => {
    setTimeLeft((prevTime) => {
      let { days, hours, minutes, seconds } = prevTime;

      if (seconds > 0) {
        seconds--;
      } else if (minutes > 0) {
        seconds = 59;
        minutes--;
      } else if (hours > 0) {
        seconds = 59;
        minutes = 59;
        hours--;
      } else if (days > 0) {
        seconds = 59;
        minutes = 59;
        hours = 23;
        days--;
      } else {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }

      return { days, hours, minutes, seconds };
    });
  };

  useEffect(() => {
    const timer = setInterval(() => tick(), 1000);
    return () => clearInterval(timer); 
  }, []);
  return (
    <div className="relative bg-white rounded-lg shadow-lg p-4 w-full sm:w-1/2 md:w-1/4">
      <span className="absolute top-2 left-0 z-50 bg-[#EFB81C] self-start text-white text-sm font-semibold px-2 py-1 rounded">
        Running
      </span>
      <img src={project.image} alt={project.name} className="w-full h-32 object-cover rounded-t-lg" />
      <div className="mt-4">
        <div className='flex justify-between w-full'>
        <div className='flex flex-col'>
        <span className="bg-[#EFB81C] self-start text-white text-sm font-semibold px-2 py-1 rounded">{project.badge}</span>
        <h3 className="text-lg font-semibold mt-2">{project.name}</h3>
        </div>
        <div className='flex flex-col items-end justify-end'>
        <span className="ml-2 text-[#48bf5b] font-semibold text-sm">{project.type}</span>
        <span className="ml-2 text-gray-500 text-sm">10025.325</span>
        <span className="ml-2 text-gray-500 text-sm">USDT</span>
        </div>
        </div>
        <p className="text-gray-600 text-sm">Fundraised: {project.fundraised}</p>
        <p className="text-gray-600 text-sm">Fundraising Progress: {project.progress}</p>
        <button className="mt-4 w-full bg-[#EFB81C] text-white text-sm font-bold py-2 px-4 rounded-lg cursor-not-allowed">
      {`${timeLeft.days}D : ${timeLeft.hours.toString().padStart(2, '0')}H : ${timeLeft.minutes.toString().padStart(2, '0')}M : ${timeLeft.seconds.toString().padStart(2, '0')}S`}
    </button>
      </div>
    </div>
  );
};

const LaunchpadProjects = () => {
  const projects = [
    {
      name: 'CVM (CosVM)',
      image: image20Million,
      fundraised: '84,742,420 CVM',
      progress: '282,474.73%',
      badge: 'Lock Now',
      type: '24 Hour Locked',
    },
    {
      name: 'CYRUS',
      image: image20Million,
      fundraised: '84,742,420 CYRUS',
      progress: '282,474.73%',
      badge: 'Lock Now',
      type: '24 Hour Locked',
    },
    {
      name: 'CVM (CosVM)',
      image: image20Million,
      fundraised: '84,742,420 CVM',
      progress: '282,474.73%',
      badge: 'Lock Now',
      type: '24 Hour Locked',
    },
    {
      name: 'CVM (CosVM)',
      image: image20Million,
      fundraised: '84,742,420 CVM',
      progress: '282,474.73%',
      badge: 'Lock Now',
      type: '24 Hour Locked',
    },
  ];

  const handleMoreClick = () => {
    console.log('More button clicked!');
  };

  return (
    <div className="container py-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold">In Progress (3)</h2>
        <Link to='/trade/startup-mining/in-progress'>
        <button
          onClick={handleMoreClick}
          className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700"
          id="more-button"
        >
          View All
        </button>
        </Link>
      </div>
    
      <div className="flex flex-wrap gap-4 justify-center">
      {projects.slice(0, 3).map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </div>
    </div>
  );
};

export default LaunchpadProjects;
