import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchuserproduct, Redeem, Toggle } from "store/actions/EarnStaking";

function Subscribed({ buttonType }) {
    const userid = useSelector((state) => state.authUser?.user?.ID);
    const token = useSelector((state) => state.authUser?.user?.TOKEN);
    const dispatch = useDispatch();

    const [products, setProducts] = useState([]);

    const fetchData = async () => {
        try {
            const response = await dispatch(fetchuserproduct(userid, token));
            if (response?.data?.status === 1) {
                setProducts(response.data.data);
            } else {
                console.error("Failed to fetch products:", response?.data?.message);
            }
        } catch (error) {
            console.error("Error fetching user product:", error);
        }
    };

    useEffect(() => {
        if (userid && token) {
            fetchData();
        }
    }, [dispatch, userid, token]);

    const handleToggle = async (productId, currentAutoDeduct) => {
        const newAutoDeduct = currentAutoDeduct === "1" ? "0" : "1";
        const payload = {
            id: productId,
            auto_deduct: newAutoDeduct,
        };

        try {
            const response = await dispatch(Toggle(userid, token, payload));
            if (response?.data?.status === 1) {
                console.log("Auto deduct toggled successfully");
                setProducts((prevProducts) =>
                    prevProducts.map((product) =>
                        product.id === productId
                            ? { ...product, auto_deduct: newAutoDeduct }
                            : product
                    )
                );
            } else {
                console.error("Failed to toggle auto deduct:", response?.data?.message);
            }
        } catch (error) {
            console.error("Error toggling auto deduct:", error);
        }
    };

    const handleRedeem = async (productId) => {
        const payload = {
            user_product_id: productId,
        };

        try {
            const response = await dispatch(Redeem(userid, token, payload));
            if (response?.data?.status === 1) {
                console.log("Redeemed successfully");
                fetchData();
            } else {
                console.error("Failed to redeem:", response?.data?.message);
            }
        } catch (error) {
            console.error("Error redeeming:", error);
        }
    };

    const filteredProducts = products.filter((product) =>
        buttonType === "Deposit"
            ? product.status === "1" && product.is_redeemed === "0"
            : product.status === "0" && product.is_redeemed === "1"
    );

    return (
        <div
            className={`p-3 sm:p-6 rounded-2xl border ${
                products.length > 0 ? "h-auto" : "h-96"
            } mb-6`}
        >
            <button className="px-2 py-2 rounded-md font-medium text-sm text-ellipsis whitespace-nowrap bg-[#f5f5f5]">
                Package
            </button>
            <div className="flex min-w-[600px] my-8">
                <div className="flex items-center gap-1 w-1/5">
                    <p className="text-xs text-[#929AA5]">Token</p>
                </div>
                <div className="flex items-center justify-end gap-1 w-1/5">
                    <p className="text-xs text-[#929AA5]">Amount</p>
                </div>
                <div className="flex items-center justify-end gap-1 w-1/5">
                    <p className="text-xs text-[#929AA5]">Duration</p>
                </div>
                <div className="flex items-center justify-end gap-1 w-1/5">
                    <p className="text-xs text-[#929AA5]">Weekly Deposit</p>
                </div>
                <div className="flex items-center justify-end gap-1 w-1/5">
                    <p className="text-xs text-[#929AA5]">Monthly Deposit</p>
                </div>
                <div className="flex items-center justify-end gap-1 w-1/5">
                    <p className="text-xs text-[#929AA5]">Toggle</p>
                </div>
                {filteredProducts.some(
                    (product) => product.is_redeemed === "0"
                ) && (
                    <div className="flex justify-end gap-1 w-1/5">
                        <p className="text-xs text-[#929AA5]">Action</p>
                    </div>
                )}
            </div>

            {filteredProducts.length > 0 ? (
                filteredProducts.map((product) => (
                    <div className="flex min-w-[600px] my-8" key={product.id}>
                        <div className="flex items-center gap-3 w-1/5">
                            <img
                                src="https://api.buycex.com/Upload/coin/65f98a1338aff.png"
                                className="w-8 h-8 mt-1"
                                alt={product.coin}
                            />
                            <p className="text-sm font-semibold">
                                {product.coin.toUpperCase()}
                            </p>
                        </div>
                        <div className="flex items-center justify-end gap-1 w-1/5">
                            <p className="text-sm font-semibold">
                                {product.amount} {product.coin.toUpperCase()}
                            </p>
                        </div>
                        <div className="flex items-center justify-end gap-1 w-1/5">
                            <p className="text-sm font-semibold">
                                {product.duration} Year
                            </p>
                        </div>
                        <div className="flex items-center justify-end gap-1 w-1/5">
                            <p className="text-sm font-semibold">
                                {product.weekly_deposit}{" "}
                                {product.coin.toUpperCase()}
                            </p>
                        </div>
                        <div className="flex items-center justify-end gap-1 w-1/5">
                            <p className="text-sm font-semibold">
                                {product.monthly_deposit}{" "}
                                {product.coin.toUpperCase()}
                            </p>
                        </div>
                        <div className="flex items-center justify-end gap-1 w-1/5">
                            <label className="inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={product.auto_deduct === "1"}
                                    onChange={() =>
                                        handleToggle(
                                            product.id,
                                            product.auto_deduct
                                        )
                                    }
                                    className="sr-only peer"
                                />
                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#EFB81C]"></div>
                            </label>
                        </div>
                        {product.is_redeemed === "1" ? (
                            ""
                        ) : (
                            <div
                                onClick={() => handleRedeem(product.id)}
                                className="flex cursor-pointer justify-end gap-1 w-1/5"
                            >
                                <p className="text-sm font-semibold rounded-md bg-[#EFB81C] px-2 py-1">
                                    Redeem
                                </p>
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <div className="flex justify-center items-center h-56">
                    <p className="text-sm text-center text-gray-500">
                        No products available
                    </p>
                </div>
            )}
        </div>
    );
}

export default Subscribed;
