import React from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

const MarketCard = ({currentItems}) => {
    if (!Array.isArray(currentItems)) return <div>No data available.</div>;
    const topbuttons = [
      {
          id: 1,
          title: "Trade ",
          link: "/trade/pre-market/detail",
          //icon: "//s1.bycsi.com/bybit/deadpool/image-f921f812edc64b5c8a1809617f250a8e.svg",
      },
     
  ];

  return (
    <div className="">
       <>
      {currentItems &&
        currentItems.map((item) => (
          <div key={item.id} className=' mt-6 text-white md:flex-row flex-col bg-gray-100 flex rounded-xl overflow-hidden   cursor-pointer hover:shadow-lg'>
          <div className='w-full md:w-[300px] lg:w-[420px] p-[20px] lg:p-[32px]  '>
  <div className='flex items-center gap-2 lg:gap-4'>
    <div className='flex items-center justify-center rounded-full w-[64px] h-[64px] lg:w-[78px] lg:h-[78px] border-1 border-[#EFB81C]'>
      <img src={`https://api.buycex.com/Upload/issue/${item.image}`} alt="" className='lg:w-[60px] w-[50px] h-[50px] lg:h-[60px] rounded-full object-cover' />
    </div>
    <div>
      <h1 className='font-semibold text-[#EFB81C] text-[24px] lg:text-[32px] leading-none flex gap-1'>
        {item.name}
        <img className="w-[24px] h-[24px]" src="--https://fh-static.bycsi.com/hot-8d1c84ee1b1c30beadd4fb7239e169a3.svg" alt="" />
      </h1>
      <p className='text-[14px] text-black'>{item.coinname}</p>
    </div>
  </div>
  <div className='mt-4 text-[12px] text-[#adb1b8] lg:h-10 overflow-hidden'>
    {item.content ? (
      <>{item.content}</>
    ) : (
      <>A short description about The coin. It's just dummy text because currently, this coin has no description.</>
    )}
  </div>
</div>

            <div className='border-[#EFB81C] rounded-r-[8px] flex flex-col items-start justify-center p-[20px] lg:p-[32px] w-full md:rounded-r-xl'>
              <div className='  items-center w-fit  flex overflow-hidden text-[12px]'>
                  {item.zhuangtai ? (
                <>
                  <div style={{backgroundColor:"rgba(32,178,108,.16)"}} className=' text-[#20b26c] py-[6px] px-[12px] text-[12px] rounded-l-[4px]'>In Progress</div>
                  <div className='py-[5px] px-[12px] border-[1px] border-[#EFB81C]   text-[#adb1b8] rounded-r-[4px] text-[12px]'>{item.time} - Pending</div>
                </>
                  
                  
                  
                  ):( <>
                    <div style={{backgroundColor:"rgba(32,178,108,.16)"}} className=' text-[#20b26c] py-[6px] px-[12px] text-[12px] rounded-l-[4px]'>In Progress</div>
                    <div className='py-[5px] px-[12px] border-[1px] border-[#EFB81C]   text-[#adb1b8] rounded-r-[4px] text-[12px]'>{item.time} - Pending</div>
                  </>)}
                
               
              </div>
              <div className='mt-[32px] md:gap-0 gap-2  flex flex-col md:flex-row md:items-center md:justify-between  w-full'>
                <div>
                  <h1 className='lg:text-[12px] text-[12px] md:text-[10px]  text-black'>Last Traded Price</h1>
                  <p className='font-semibold text-[14px] md:text-[14px] lg:text-[18px] mt-1 text-[#EFB81C]'>{item.price}<span className='text-xs text-green-600'>(0.00%)</span> </p>
                </div>
                <div className='flex gap-1 md:gap-2 lg:gap-4 md:items-center md:justify-center '>
                <h1 className='w-[1px] hidden md:block md:h-[28px] bg-[#EFB81C]'></h1>
                <div>
                  <h1 className='lg:text-[12px] text-[12px] md:text-[10px]  text-black'>24H Volume(USDT)</h1>
                  <p className='font-semibold text-[#EFB81C]text-[14px] md:text-[14px] text-[#EFB81C] lg:text-[18px] mt-1'>32,148 </p>
                </div>
                </div>
                <div className='flex gap-1 md:gap-2 lg:gap-4 md:items-center md:justify-center '>
                <h1 className='w-[1px] hidden md:block md:h-[28px] bg-[#EFB81C]'></h1>
                <div>
                  <h1 className='text-black text-[12px] md:text-[10px] lg:text-[12px]'>Total Trading Volume(USDT)</h1>
                  <p className='font-semibold text-[14px] md:text-[14px] lg:text-[18px] text-[#EFB81C] mt-1'>2,708,018</p>
                </div>
                </div>
               
                {topbuttons.map((button) => (
    <NavLink to={button.link} key={button.id}>
        <button
            className="flex bg-yellow-600 
                p-[12px] h-[36px] rounded-[6px] gap-1 font-medium items-center text-white text-[18px]"
        >
            <img
                src={button.icon}
                alt=""
                className="w-[16px] h-[16px]"
            />
            {button.title}
        </button>
    </NavLink>
))}

              </div>
            </div>
          </div>
        ))}
    </>
    </div>
  )
}

export default MarketCard
