/* eslint-disable no-unused-vars */
// Authentication Routes
import Login from "views/auth/Login";
import Register from "views/auth/register";
import Home from "views/home";
import ForgetPassword from "components/auth/validations/forget-password/forgetPassword";
import ResetPassword from "components/auth/validations/change-password/changePassword";
import BcxToken from "components/earn/BCXToken";

// new user layout pages
import AccountOverview from "components/user/account/overview_old/AccountOverview";
import SecuritySettings from "components/user/account/SecuritSettingsComponents/SecuritySettings_old/securitySettings";
import Advancements from "components/user/account/Advancements";
import NotificationCenter from "components/user/notification/UserNotification";

import ResetWithDrawPassword from "components/user/account/withdraw-password/forgetWithDrawPassword/ResetWithDrawPassword";

import AssetsSpot from "components/user/assets/pages/Spot";
import AssetsDeposit from "components/user/assets/pages/Deposit";
import AssetsWithdrawRecord from "components/user/assets/pages/WithdrawRecord";
import AssetsTransferRecord from "components/user/assets/pages/TransferRecord";

import AssetsUncreditedDepositForm from "components/user/assets/pages/UncreditedDepositForm";
import AssetsFunding from "components/user/assets/pages/Funding";
import AssetsEarn from "components/user/assets/pages/Earn";

import AssetsSpotHistory from "components/user/assets/pages/SpotHistory";
import AssetsMarginHistory from "components/user/assets/pages/MarginHistory";
import AssetsFundingHistory from "components/user/assets/pages/FundingHistory";
import AssetsEarnHistory from "components/user/assets/pages/EarnHistory";

import SpotOrderPending from "views/orders/SpotOrderPending";
import SpotOrderHistory from "components/user/orders/pages/SpotOrder/SpotOrderHistory";
import SpotOrderTrade from "components/user/orders/pages/SpotOrder/SpotOrderTrade";
import SpotOrderMargin from "components/user/orders/pages/SpotOrder/SpotOrderMargin";
import ConvertOrderPending from "components/user/orders/pages/ConvertOrder/ConvertOrderPending";
import ConvertOrderCompleted from "components/user/orders/pages/ConvertOrder/ConvertOrderCompleted";
import UserLaunchPad from "components/user/orders/pages/LaunchPad/LaunchPad";
import LaunchGuard from "views/LaunchGuard/LaunchGuard";
import Startupmining from "views/LaunchGuard/Startupmining";
import StartupTask from "views/LaunchGuard/StartupTask";

import FiatOrder from "components/user/orders/pages/FiatOrder/FiatOrder";

// Common Pages
import PrivacyPolicy from "components/privacyPolicy/PrivacyPolicy";
import Announcement from "views/pages/Announcement";

import HelpCenterList from "components/helpCenter/HelpCenterList";
import ProofOfReserve from "views/pages/ProofOfReserve";
import Referral from "views/pages/Referral";
import SubmitCase from "views/pages/SubmitCase";
import TermsAndCondition from "views/pages/TermAndCondition";
import UserProtection from "views/pages/UserProtection";
import Verification from "views/pages/Verification";
import RiskDisclosure from "views/pages/RiskDisclosure";
import PressRoom from "components/pressRoom/PressRoom";
import Downloads from "components/downloads/Downloads";
import PublicVoting from "views/pages/PublicVoting";

// Crypto & Fiat
import FiatDeposit from "views/fiat/fiatDeposit";
import FiatWithdraw from "views/fiat/fiatWithdraw";
import CryptoDeposit from "components/cryptoDeposit/CryptoDeposit";
import CryptoConvert from "views/crypto/cryptoConvert";
import CryptoWithdraw from "views/crypto/cryptoWithdraw";
import CryptoMining from "views/crypto/CryptoMining/CryptoMining";

// Spot and Trade section
import SpotTrade from "views/trade/spot/SpotTrade";

import SpotTradeOld from "views/trade/spot/SpotTrade";

import G2FA from "components/auth/validations/g2fa/g2faVerification";

import OneClickBuy from "components/oneClickBuy/oneClickBuy";

import OTCPage from "views/pages/OTC";

// rewards hub
import RewardsHub from "views/pages/RewardsHub";

// user Identity verification
import IdentityVerification from "components/user/account/identity-verification/IdentityVerification";
import BasicInformation from "components/user/account/identity-verification/BasicInformation";
import UploadID from "components/user/account/identity-verification/UploadID";
import FaceRecognition from "components/user/account/identity-verification/FaceRecognition";
import CompleteVerification from "components/user/account/identity-verification/CompleteVerification";
import KYCVerification from "components/user/account/identity-verification/KYCVerification";
import AdvancedVerificationForm from "components/user/account/identity-verification/AdvanceVerificationForm";

//Disable Account
import DisableAccount from "components/user/account/disableAccount/disableAccount";

//Re-activate Account
import ReactivateAccount from "components/user/account/reactivateAccount/reactivateAccount";

//Delete Account
import DeleteAccount from "components/user/account/deleteAccount/deleteAccount";
import ConfirmDelete from "components/user/account/deleteAccount/confirmDelete";
import UnableDelete from "components/user/account/deleteAccount/unableDelete";

//P2P
import Orders from "views/trade/Orders/Orders";
import MyProfile from "views/trade/MyProfile/MyProfile";
import AssetsOverview from "views/assets/AssetsOverview";
import MarketPage from "views/market/overview/MarketOverview";
import P2p from "views/trade/P2P/P2p";
import P2pChat from "views/trade/P2P/P2pComponents/P2pChat/P2pChat";
import MyAds from "views/trade/MyAds/MyAds";
import PostAds from "views/trade/PostAds/PostAds";
import Merchant from "views/trade/Merchant/Merchant";
import AdvertiserDetail from "views/trade/P2P/P2pComponents/P2pAdvertiserDetail/AdvertiserDetail";
import BankTransfer from "views/trade/MyProfile/P2pPaymentMethodComponents/BankTransfer";
import AdHistory from "views/trade/MyAds/AdHistory";
// trade => pre-market trading

import CryptoTransfer from "views/crypto/cryptoTransfer";
import OTCTradePage from "views/trade/otc/OTCTrading";
// trade => pre-market trading
import PreMarketTrading from "components/trade/pre-market-trading/PreMarketTrading";
import SingleTradeCard from "components/trade/pre-market-trading/SingleTradeCard";

// Third Party Payment
import ThirdPartyPayments from "../views/thirdPartyPayments/thirdPartyPayments";
import Dashboard from "views/profile/Dashboard/Dashboard";

// Express Trade
import Express from "views/trade/Express/Express";
import SpotAssets from "views/profile/Assets/Spot/SpotAssets";
import FundingAssets from "views/profile/Assets/Funding/FundingAssets";
import EarnAsset from "views/profile/Assets/Earn/EarnAsset";
import MarginAssets from "views/profile/Assets/Margin/MarginAssets";

// earn overview
import EarnOverview from "components/earn/EarnOverview";
// dynamic earn/home/ fixed or flexible usdt page
import EarnSavings from "components/earn/earnsavings/EarnSavings";
import FiatBalanceAssets from "views/profile/Assets/FiatBalance/FiatBalanceAssets";

import FutureAssets from "views/profile/Assets/Future/FutureAssets";
import SpotHistory from "views/profile/History/SpotHistory/SpotHistory";
import P2pHistory from "views/profile/History/P2pHistory/P2pHistory";
import Derivates from "components/derivates";

import CryptoDPS from "components/earn/cryptoDps/CryptoDPS";
import TransactionHistory from "views/profile/History/TransactionHistory/TransferHistory";
import FutureHistory from "views/profile/History/FutureHistory/FutureHistory";
import PaymentsHistory from "views/profile/History/PaymentsHistory/PaymentsHistory";
import OTCHistory from "views/profile/History/OTCHistory/OTCHistory";
import CryptoDepositHistory from "views/profile/History/CryptoDepositHistory/CryptoDepositHistory";
import CryptoWithdrawHistory from "views/profile/History/CryptoDepositHistory/CryptoWithdrawHistory";
import CryptoTransferHistory from "views/profile/History/CryptoDepositHistory/CryptoTransferHistory";

import ListingOfVoice from "views/ListingOfVoice/ListingOfVoice";
import ConvertHistory from "views/profile/History/ConvertHistory/ConvertHistory";
import EarnHistory from "views/profile/History/EarnHistory/EarnHistory";
import MarginHistory from "views/profile/History/MarginHistory/MarginHistory";
import PreListTrade from "views/profile/History/Pre-ListTrade/Pre-ListTrade";
import DPSHistory from "views/profile/History/DPSHistory/DPSHistory";
import CopyTrading from "views/profile/Assets/CopyTrading/CopyTrading";
import TradingBot from "views/profile/Assets/TradingBot/TradingBot";

import Settings from "views/profile/Settings/Settings";

// more pages
// for marketplace shop page
import MarketPlace from "components/marketplace/MarketPlace";
import SingleProductDetail from "components/marketplace/SingleProductDetail";
// web3 networks
import WebNetwork from "components/web3Networks/WebNetwork";
// our partenrs
import Partners from "components/partners/Partners";
import PerpetualTrade from "views/trade/spot/PerpetualTrade";
import FutureTrade from "views/trade/spot/FutureTrade";
import OptionTrade from "views/trade/spot/OptionTrade";
import MarginTrade from "views/trade/spot/MarginTrade";
import ResetSecuritySettings from "components/auth/validations/email/resetSecuritySettings";
import CryptoInternalTransfer from "views/crypto/InternalTranfer/CryptoInternalTransfer";
// import SecuritySettingsNew from "components/user/account/SecuritSettingsComponents/new/securitySettings";
import IdentityVerificationProcess from "components/user/account/IdentityVerification";
import SecuritySettingsNew from "components/user/account/SecuritSettingsComponents/securitySettings";
import AccountOverviewNew from "components/user/account/overview/AccountOverview";
import WithdrawAddressWhitelist from "views/profile/History/WithdrawAddressWhitelist/WithdrawAddressWhitelist";
import ReferralHistory from "views/profile/History/ReferralHistory/ReferralHistory";
import Home2 from "views/index/index2";

//Earn
import BcxTokenEarn from "views/profile/Earn/BcxToken/BcxToken";
import LaunchPad from "components/trade/LaunchPad/LaunchPad";
import FixedTermEarn from "views/profile/Assets/TeamDeposit/FixedTermEarn";
import BcxHistory from "views/profile/History/BcxHistory/BCXHistory";
import MyOrder from "components/trade/LaunchPad/MyOrder";
import SimpleEarn from "views/earn/simpleEarn";
import TermDeposit from "views/earn/termDeposit/termDeposit";
import CryptoDps from "views/profile/Earn/CryptoDps/CryptoDps";
import PaymentProfile from "components/user/account/payment/payment";
import LaunchPool from "views/LaunchGuard/LaunchPool/LaunchPool";

const routes = [
    {
        path: "/reset-security-settings",
        layout: "auth",
        component: ResetSecuritySettings,
        exact: true,
    },
    {
        path: "/identity-verification",
        layout: "user",
        component: IdentityVerificationProcess,
        exact: true,
    },
    {
        path: "/user/account/overview",
        path: "/user/account/overview_old",
        layout: "user",
        component: AccountOverview,
        exact: true,
    },
    {
        path: "/user/account/overview",
        layout: "user",
        component: AccountOverviewNew,
        exact: true,
    },

    {
        path: "/user/account/security_old",
        layout: "user",
        component: SecuritySettings,
        exact: true,
    },
    {
        path: "/user/account/security",
        layout: "user",
        component: SecuritySettingsNew,
        exact: true,
    },
    {
        path: "/user/account/payment",
        layout: "user",
        component: PaymentProfile,
        exact: true,
    },

    {
        path: "/user/account/advancements",
        layout: "user",
        component: Advancements,
        exact: true,
    },

    {
        path: "/user/account/notification-center",
        layout: "user",
        component: NotificationCenter,
        exact: true,
    },
    {
        path: "/user/account/delete/confirm",
        layout: "user",
        component: ConfirmDelete,
        exact: true,
    },
    {
        path: "/user/account/delete/failed",
        layout: "user",
        component: UnableDelete,
        exact: true,
    },

    {
        path: "/user/account/disable",
        layout: "user",
        component: DisableAccount,
        exact: true,
    },
    {
        path: "/earn/bcx-token",
        layout: "main",
        component: BcxToken,
        exact: true,
    },
    {
        path: "/earn/home/flexible",
        layout: "main",
        component: SimpleEarn,
        exact: true,
    },
    {
        path: "/earn/home/fixed",
        layout: "main",
        component: TermDeposit,
        exact: true,
    },

    {
        path: "/user/account/re-activate",
        layout: "main",
        component: ReactivateAccount,
        exact: true,
    },

    {
        path: "/user/account/advancements",
        layout: "user",
        component: Advancements,
        exact: true,
    },

    {
        path: "/user/account/rewards-hub",
        layout: "user",
        component: RewardsHub,
        exact: true,
    },

    {
        path: "/user/account/disable",
        layout: "user",
        component: DisableAccount,
        exact: true,
    },

    {
        path: "/user/account/delete",
        layout: "user",
        component: DeleteAccount,
        exact: true,
    },
    {
        path: "/user/account/identity-verification/information",
        layout: "user",
        component: IdentityVerification,
        exact: true,
    },
    {
        path: "/user/account/identity-verification/basic-verification",
        layout: "user",
        component: BasicInformation,
        exact: true,
    },

    {
        path: "/user/account/identity-verification/id-upload",
        layout: "user",
        component: UploadID,
        exact: true,
    },

    {
        path: "/user/account/identity-verification/face-recognition",
        layout: "user",
        component: FaceRecognition,
        exact: true,
    },

    {
        path: "/user/account/identity-verification/complete-verification",
        layout: "user",
        component: CompleteVerification,
        exact: true,
    },
    {
        path: "/user/account/identity-verification/kyc-verification",
        layout: "user",
        component: KYCVerification,
        exact: true,
    },
    {
        path: "/user/account/identity-verification/advance-verification",
        layout: "user",
        component: AdvancedVerificationForm,
        exact: true,
    },
    {
        path: "/user/account/security/reset-withdraw-password",
        layout: "main",
        component: ResetWithDrawPassword,
        exact: true,
    },

    {
        path: "/user/assets/overview",
        layout: "assets",
        component: AssetsOverview,
        exact: true,
    },
    {
        path: "/user/assets/spot",
        layout: "assets",
        component: AssetsSpot,
        exact: true,
    },
    {
        path: "/user/assets/deposit",
        layout: "assets",
        component: AssetsDeposit,
        exact: true,
    },
    {
        path: "/user/assets/withdrawrecord",
        layout: "assets",
        component: AssetsWithdrawRecord,
        exact: true,
    },
    {
        path: "/user/assets/transferrecord",
        layout: "assets",
        component: AssetsTransferRecord,
        exact: true,
    },
    {
        path: "/user/assets/uncrediteddepositform",
        layout: "assets",
        component: AssetsUncreditedDepositForm,
        exact: true,
    },

    {
        path: "/user/assets/funding",
        layout: "assets",
        component: AssetsFunding,
        exact: true,
    },

    {
        path: "/user/assets/earn",
        layout: "assets",
        component: AssetsEarn,
        exact: true,
    },

    {
        path: "/user/assets/history/spot",
        layout: "assets",
        component: AssetsSpotHistory,
        exact: true,
    },

    {
        path: "/user/assets/history/margin",
        layout: "assets",
        component: AssetsMarginHistory,
        exact: true,
    },

    {
        path: "/user/assets/history/funding",
        layout: "assets",
        component: AssetsFundingHistory,
        exact: true,
    },

    {
        path: "/user/assets/history/earn",
        layout: "assets",
        component: AssetsEarnHistory,
        exact: true,
    },

    {
        path: "/user/forget-password",
        layout: "auth",
        component: ForgetPassword,
        exact: true,
    },

    {
        path: "/user/reset-password",
        layout: "auth",
        component: ResetPassword,
        exact: true,
    },

    {
        path: "/login/verify-g2fa",
        layout: "auth",
        component: G2FA,
        exact: true,
    },

    // Main Routes
    {
        path: "/index1",
        layout: "main",
        component: Home,
        exact: true,
    },
    {
        path: "/",
        layout: "main",
        component: Home2,
        exact: true,
    },
    {
        path: "/market/overview",
        layout: "market",
        component: MarketPage,
        exact: true,
    },
    {
        path: "/privacy-policy",
        layout: "main",
        component: PrivacyPolicy,
        exact: true,
    },

    // Authentication Routes
    {
        path: "/login",
        layout: "auth",
        component: Login,
        exact: true,
    },
    {
        path: "/register",
        layout: "auth",
        component: Register,
        exact: true,
    },

    // Common Pages
    {
        path: "/announcements",
        layout: "main",
        component: Announcement,
        exact: true,
    },
    {
        path: "/help-center-list",
        layout: "main",
        component: HelpCenterList,
        exact: true,
    },
    {
        path: "/proof-of-reserve",
        layout: "main",
        component: ProofOfReserve,
        exact: true,
    },
    {
        path: "/referral",
        layout: "main",
        component: Referral,
        exact: true,
    },
    {
        path: "/submit-case",
        layout: "main",
        component: SubmitCase,
        exact: true,
    },
    {
        path: "/terms-and-condition",
        layout: "main",
        component: TermsAndCondition,
        exact: true,
    },
    {
        path: "/user-protection",
        layout: "main",
        component: UserProtection,
        exact: true,
    },
    {
        path: "/press-room",
        layout: "main",
        component: PressRoom,
        exact: true,
    },
    {
        path: "/verification",
        layout: "main",
        component: Verification,
        exact: true,
    },
    {
        path: "/risk-disclosure",
        layout: "main",
        component: RiskDisclosure,
        exact: true,
    },
    {
        path: "/fiat/deposit",
        layout: "p2p",
        component: FiatDeposit,
        exact: true,
    },
    {
        path: "/fiat/withdraw",
        layout: "main",
        component: FiatWithdraw,
        exact: true,
    },
    {
        path: "/crypto/deposit",
        layout: "p2p",
        component: CryptoDeposit,
        exact: true,
    },
    {
        path: "/crypto/convert",
        layout: "main",
        component: CryptoConvert,
        exact: true,
    },
    {
        path: "/crypto/transfer",
        layout: "main",
        component: CryptoTransfer,
        exact: true,
    },
    {
        path: "/crypto/withdraw",
        layout: "main",
        component: CryptoWithdraw,
        exact: true,
    },
    {
        path: "/crypto/mining",
        layout: "main",
        component: CryptoMining,
        exact: true,
    },
    {
        path: "/crypto/internal-pay",
        layout: "main",
        component: CryptoInternalTransfer,
        exact: true,
    },

    {
        path: "/trade/spot/:coin",
        layout: "spotTrade",
        component: SpotTrade,
        exact: true,
    },

    // for PerpetualTrade
    {
        path: "/trade/perpetual/:coin",
        layout: "spotTrade",
        component: PerpetualTrade,
        exact: true,
    },
    // for FutureTrade
    {
        path: "/trade/future/:coin",
        layout: "spotTrade",
        component: FutureTrade,
        exact: true,
    },
    // for OptionTrade
    {
        path: "/trade/option/:coin",
        layout: "spotTrade",
        component: OptionTrade,
        exact: true,
    },
    // for MarginTrade
    {
        path: "/trade/margin/:coin",
        layout: "spotTrade",
        component: MarginTrade,
        exact: true,
    },
    // {
    //     path: "/trade/spot",
    //     layout: "spotTrade",
    //     component: SpotTrade,
    //     exact: true,
    // },
    {
        path: "/trade/express",
        layout: "p2p",
        component: Express,
        exact: true,
    },
    {
        path: "/downloads",
        layout: "main",
        component: Downloads,
        exact: true,
    },
    {
        path: "/public-voting",
        layout: "main",
        component: PublicVoting,
        exact: true,
    },
    // earn and earn overview
    {
        path: "/earn/home",
        layout: "main",
        component: EarnOverview,
        exact: true,
    },
    // earn saving
    {
        path: "/earn/home/:coin",
        layout: "main",
        component: EarnSavings,
        exact: true,
    },

    // crypto DPS
    {
        path: "/earn/dps",
        layout: "main",
        component: CryptoDPS,
        exact: true,
    },

    // for more pages
    // marketplace shop page
    {
        path: "/shop",
        layout: "main",
        component: MarketPlace,
        exact: true,
    },
    {
        path: "/shop/:id",
        layout: "main",
        component: SingleProductDetail,
        exact: true,
    },
    // web3 networks
    {
        path: "/listed-dapp",
        layout: "main",
        component: WebNetwork,
        exact: true,
    },
    // our patners
    {
        path: "/our-partners",
        layout: "main",
        component: Partners,
        exact: true,
    },
    // P2P

    {
        path: "/trade/p2p",
        layout: "p2p",
        component: P2p,
        exact: true,
    },
    {
        path: "/trade/p2p/post-ads",
        layout: "p2p",
        component: PostAds,
        exact: true,
    },
    {
        path: "/trade/p2p/apply-merchant",
        layout: "p2p",
        component: Merchant,
        exact: true,
    },

    {
        path: "/trade/p2p/my-ads",
        layout: "p2p",
        component: MyAds,
        exact: true,
    },
    {
        path: "/trade/p2p/chat",
        layout: "p2p",
        component: P2pChat,
        exact: true,
    },
    {
        path: "/trade/p2p/orders",
        layout: "p2p",
        component: Orders,
        exact: true,
    },
    {
        path: "/trade/p2p/profile",
        layout: "p2p",
        component: MyProfile,
        exact: true,
    },
    {
        path: "/trade/p2p/advertiserdetail",
        layout: "p2p",
        component: AdvertiserDetail,
        exact: true,
    },
    {
        path: "/trade/p2p/payment/bank",
        layout: "p2p",
        component: BankTransfer,
        exact: true,
    },
    {
        path: "/trade/p2p/ads-history",
        layout: "p2p",
        component: AdHistory,
        exact: true,
    },
    // Profile

    {
        path: "/user/dashboard",
        layout: "user",
        component: Dashboard,
        exact: true,
    },
    {
        path: "/user/asset/spot-overview",
        layout: "user",
        component: SpotAssets,
        exact: true,
    },
    {
        path: "/user/assets/funding-overview",
        layout: "user",
        component: FundingAssets,
        exact: true,
    },
    {
        path: "/user/earn/flexible-earn",
        layout: "user",
        component: EarnAsset,
        exact: true,
    },
    {
        path: "/user/assets/margin",
        layout: "user",
        component: MarginAssets,
        exact: true,
    },
    {
        path: "/user/assets/fiat-balance",
        layout: "user",
        component: FiatBalanceAssets,
        exact: true,
    },
    {
        path: "/user/assets/future",
        layout: "user",
        component: FutureAssets,
        exact: true,
    },
    {
        path: "/user/assets/copy-trading",
        layout: "user",
        component: CopyTrading,
        exact: true,
    },
    {
        path: "/user/earn/bcx-token",
        layout: "user",
        component: BcxTokenEarn,
        exact: true,
    },
    {
        path: "/trade-ai",
        layout: "main",
        component: TradingBot,
        exact: true,
    },
    {
        path: "/user/history/spot-history",
        layout: "user",
        component: SpotHistory,
        exact: true,
    },
    {
        path: "/user/history/spot-history",
        layout: "user",
        component: SpotHistory,
        exact: true,
    },
    {
        path: "/user/history/p2p-history",
        layout: "user",
        component: P2pHistory,
        exact: true,
    },
    {
        path: "/user/history/transfer-history",
        layout: "user",
        component: TransactionHistory,
        exact: true,
    },
    {
        path: "/user/history/crypto-deposit",
        layout: "user",
        component: CryptoDepositHistory,
        exact: true,
    },
    {
        path: "/user/history/crypto-transfer",
        layout: "user",
        component: CryptoTransferHistory,
        exact: true,
    },
    {
        path: "/user/history/crypto-withdraw",
        layout: "user",
        component: CryptoWithdrawHistory,
        exact: true,
    },
    {
        path: "/user/history/futures-history",
        layout: "user",
        component: FutureHistory,
        exact: true,
    },
    {
        path: "/user/history/payments-history",
        layout: "user",
        component: PaymentsHistory,
        exact: true,
    },
    {
        path: "/user/history/otc-history",
        layout: "user",
        component: OTCHistory,
        exact: true,
    },
    {
        path: "/user/history/bcx-history",
        layout: "user",
        component: BcxHistory,
        exact: true,
    },
    {
        path: "/user/history/convert-history",
        layout: "user",
        component: ConvertHistory,
        exact: true,
    },
    {
        path: "/user/history/earn-history",
        layout: "user",
        component: EarnHistory,
        exact: true,
    },
    {
        path: "/user/history/margin-history",
        layout: "user",
        component: MarginHistory,
        exact: true,
    },
    {
        path: "/user/history/Pre-ListTrade-history",
        layout: "user",
        component: PreListTrade,
        exact: true,
    },
    {
        path: "/user/history/dps-history",
        layout: "user",
        component: DPSHistory,
        exact: true,
    },
    {
        path: "/user/history/referral-history",
        layout: "user",
        component: ReferralHistory,
        exact: true,
    },
    {
        path: "/user/settings",
        layout: "user",
        component: Settings,
        exact: true,
    },
    {
        path: "/user/withdraw-address-whitelist",
        layout: "user",
        component: WithdrawAddressWhitelist,
        exact: true,
    },
    {
        path: "/user/earn/fixed-term-earn",
        layout: "user",
        component: FixedTermEarn,
        exact: true,
    },
    {
        path: "/user/earn/crypto-dps",
        layout: "user",
        component: CryptoDps,
        exact: true,
    },
    {
        path: "/trade/third-party",
        layout: "p2p",
        component: ThirdPartyPayments,
        exact: true,
    },

    {
        path: "/trade/launch-pad",
        layout: "main",
        component: LaunchPad,
        exact: true,
    },
    {
        path: "/trade/launch-pad/my-order",
        layout: "main",
        component: MyOrder,
        exact: true,
    },

    {
        path: "/trade/spot",
        layout: "auth",
        component: SpotTrade,
        exact: true,
    },
    {
        path: "/trade/pre-market",
        layout: "main",
        component: PreMarketTrading,
        exact: true,
    },
    {
        path: "/trade/pre-market/detail/:id/:name",
        component: SingleTradeCard,
        layout: "auth",
        exact: true,
    },

    // order new pages
    {
        path: "/user/spot-order/pending",
        layout: "orders",
        component: SpotOrderPending,
        exact: true,
    },
    {
        path: "/user/spot-order/order-history",
        layout: "orders",
        component: SpotOrderHistory,
        exact: true,
    },
    {
        path: "/user/spot-order/trade-history",
        layout: "orders",
        component: SpotOrderTrade,
        exact: true,
    },
    {
        path: "/user/spot-order/margin-history",
        layout: "orders",
        component: SpotOrderMargin,
        exact: true,
    },
    {
        path: "/user/fiat-order",
        layout: "orders",
        component: FiatOrder,
        exact: true,
    },
    {
        path: "/user/convert-order/pending",
        layout: "orders",
        component: ConvertOrderPending,
        exact: true,
    },
    {
        path: "/user/convert-order/completed",
        layout: "orders",
        component: ConvertOrderCompleted,
        exact: true,
    },
    {
        path: "/user/launchpad",
        layout: "orders",
        component: UserLaunchPad,
        exact: true,
    },
    {
        path: "/otc",
        layout: "main",
        component: OTCPage,
        exact: true,
    },
    {
        path: "/trade/otc",
        layout: "spotTrade",
        component: OTCTradePage,
        exact: true,
    },
    {
        path: "/futures/home",
        layout: "main",
        component: Derivates,
        exact: true,
    },
    {
        path: "/trade/Launch-guard",
        layout: "main",
        component: LaunchGuard,
        exact: true,
    },
    {
        path: "/launchpool",
        layout: "main",
        component: LaunchPool,
        exact: true,
    },
    {
        path: "/trade/startup-mining",
        layout: "main",
        component: Startupmining,
        exact: true,
    },
    {
        path: "/trade/startup-mining/:tab",
        layout: "main",
        component: Startupmining,
        exact: true,
    },

    {
        path: "/trade/startup-task",
        layout: "main",
        component: StartupTask,
        exact: true,
    },

    {
        path: "/Vote/index-task",
        layout: "main",
        component: ListingOfVoice,
        exact: true,
    },
];

export default routes;
