import {
    FETCH_BCX_FINANCE_HISTORY_REQUEST,
    FETCH_BCX_FINANCE_HISTORY_SUCCESS,
    FETCH_BCX_FINANCE_HISTORY_FAILURE,
    GET_CONVERT_BCX_REQUEST,
    GET_CONVERT_BCX_SUCCESS,
    GET_CONVERT_BCX_FAILURE,
} from "../types";

const initialState = {
    records: {
        data: [],
        loading: false,
        error: null,
    },
    //,
    convertBcxLoading: false,
    convertBcx: [],
    convertBcxError: null,
};

const bcxReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BCX_FINANCE_HISTORY_REQUEST:
            return {
                ...state,
                records: {
                    ...state.records,
                    loading: true,
                    error: null,
                },
            };
        case FETCH_BCX_FINANCE_HISTORY_SUCCESS:
            return {
                ...state,
                records: {
                    ...state.records,
                    loading: false,
                    data: action.payload,
                },
            };
        case FETCH_BCX_FINANCE_HISTORY_FAILURE:
            return {
                ...state,
                records: {
                    ...state.records,
                    loading: false,
                    error: action.payload,
                },
            };
        case GET_CONVERT_BCX_REQUEST:
            return {
                ...state,
                convertBcxLoading: true,
                convertBcx: [],
                convertBcxError: null,
            };
        case GET_CONVERT_BCX_SUCCESS:
            return {
                ...state,
                convertBcxLoading: false,
                convertBcx: action.payload,
                convertBcxError: null,
            };
        case GET_CONVERT_BCX_FAILURE:
            return {
                ...state,
                convertBcxLoading: false,
                convertBcx: [],
                convertBcxError: action.payload,
            };
        default:
            return state;
    }
};

export default bcxReducer;
