import axiosObj from './Repository'; 

export const userPayId = (TOKEN, ID) => {
  return axiosObj.get('Finance/getUserPayId', {
    headers: {
      TOKEN,
      ID,
    },
  });
};
