import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { GiMining } from "react-icons/gi";
import { GoDotFill } from "react-icons/go";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { IoCopySharp, IoInformationCircleSharp } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import ReactPaginate from "react-paginate";

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function MiningCoins() {
    const [expandedRow, setExpandedRow] = useState(null);

    const coins = [
        {
            id: 1,
            name: "Bitcoin",
            symbol: "BTC",
            algo: "SHA256",
            worker: "284,825",
            hashrate: "53.84 Eh/s",
            network: "844.96 Eh/s",
            url: "https://global.buycex.com/Upload/coin/BTC.png",
            chartData: [10, 20, 15, 25, 20, 30, 35],
        },
        {
            id: 2,
            name: "ETHPoW",
            symbol: "ETHW",
            algo: "Ethash",
            worker: "348",
            hashrate: "30.84 Th/s",
            network: "30.87 Th/s",
            url: "https://global.buycex.com/Upload/coin/ETH.png",
            chartData: [5, 15, 10, 20, 25, 30, 25],
        },
    ];

    const toggleRow = (id) => {
        setExpandedRow(expandedRow === id ? null : id);
    };

    const getChartData = (data) => ({
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
            {
                label: "Network Hashrate (Eh/s or Th/s)",
                data: data,
                fill: false,
                backgroundColor: "#EFB81C",
                borderColor: "#EFB81C",
                tension: 0.4,
            },
        ],
    });

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: { display: false }, // Hide the legend
        },
        scales: {
            x: {
                grid: { display: false }, // Disable x-axis grid
                ticks: { color: "#81858c", font: { size: 12 } }, // Style x-axis labels
            },
            y: {
                grid: { display: false }, // Disable y-axis grid
                ticks: { color: "#81858c", font: { size: 12 } }, // Style y-axis labels
            },
        },
    };

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const startIndex = currentPage * itemsPerPage;
    const currentItems = coins.slice(startIndex, startIndex + itemsPerPage);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    return (
        <div>
            <table className="w-full mt-10">
                <thead>
                    <tr className="border-b border-gray-200">
                        <th
                            className="px-4 font-medium text-[#81858c] text-xs py-2 text-left"
                            style={{ width: "25%" }}
                        >
                            ID
                        </th>
                        <th
                            className="px-4 font-medium text-[#81858c] text-xs py-2 text-left"
                            style={{ width: "12%" }}
                        >
                            Algo
                        </th>
                        <th
                            className="px-4 font-medium text-[#81858c] text-xs py-2 text-left"
                            style={{ width: "12%" }}
                        >
                            Active Workers
                        </th>
                        <th
                            className="px-4 font-medium text-[#81858c] text-xs py-2 text-left"
                            style={{ width: "12%" }}
                        >
                            Hashrate
                        </th>
                        <th
                            className="px-4 font-medium text-[#81858c] text-xs py-2 text-left"
                            style={{ width: "12%" }}
                        >
                            Network
                        </th>
                        <td
                            className="px-4 font-medium text-[#81858c] text-xs py-2 text-left"
                            style={{ width: "25%" }}
                        >
                            Tutorial
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((coin) => (
                        <React.Fragment key={coin.id}>
                            <tr
                                className="hover:bg-gray-50 border-b border-gray-200 cursor-pointer"
                                onClick={() => toggleRow(coin.id)}
                            >
                                <td
                                    className="px-4 py-4 flex items-center gap-[10px] text-left"
                                    style={{ width: "25%" }}
                                >
                                    <img
                                        src={coin.url}
                                        alt={`${coin.name} logo`}
                                        className="w-6 h-6 object-cover"
                                    />
                                    <div className="flex flex-col">
                                        <p className="font-semibold">{coin.symbol}</p>
                                        <p className="text-sm text-[#81858c]">{coin.name}</p>
                                    </div>
                                </td>
                                <td className="px-4 py-4 text-left" style={{ width: "12%" }}>
                                    {coin.algo}
                                </td>
                                <td className="px-4 py-4 text-left" style={{ width: "12%" }}>
                                    {coin.worker}
                                </td>
                                <td className="px-4 py-4 text-left" style={{ width: "12%" }}>
                                    {coin.hashrate}
                                </td>
                                <td className="px-4 py-4 text-left" style={{ width: "12%" }}>
                                    {coin.network}
                                </td>
                                <td className="px-4 py-4 text-left" style={{ width: "25%" }}>
                                    <div className="flex items-center gap-10">
                                        <p className="text-[#EFB81C]">View Tutorial</p>
                                        {expandedRow === coin.id ? (
                                            <FaAngleUp className="opacity-60" />
                                        ) : (
                                            <FaAngleDown className="opacity-60" />
                                        )}
                                    </div>
                                </td>
                            </tr>
                            {expandedRow === coin.id && (
                                <tr>
                                    <td colSpan="6">
                                        <div className="flex w-full gap-4 py-4 px-4">
                                            {/* Left Box */}
                                            <div className="w-1/2 bg-gray-50 rounded-md">
                                                <div className="px-4 py-4">
                                                    <label className="flex items-center gap-1">
                                                        <GoDotFill fill="#EFB18c" />
                                                        <p>Network Hashrate</p>
                                                    </label>
                                                    <Line data={getChartData(coin.chartData)} options={chartOptions} />
                                                </div>
                                            </div>

                                            {/* Right Box */}
                                            <div className="w-1/2 px-4 py-4 bg-gray-50 rounded-md">
                                                <div className="w-full flex justify-between border-b">
                                                    <div>
                                                        <p className="text-xs font-medium">Mining Url:</p>
                                                        <div className="flex flex-col py-3">
                                                            <div className="flex items-center gap-3"><p className="text-sm hover:text-[#EFB81C] py-2">stratum+tcp://bch.poolbuycex.com:443</p><IoCopySharp fill="#81858c" /></div>
                                                            <div className="flex items-center gap-3"><p className="text-sm hover:text-[#EFB81C] py-2">stratum+tcp://bch.poolbuycex.com:443</p><IoCopySharp fill="#81858c" /></div>
                                                            <div className="flex items-center gap-3"><p className="text-sm hover:text-[#EFB81C] py-2">stratum+tcp://bch.poolbuycex.com:443</p><IoCopySharp fill="#81858c" /></div>
                                                            <p className="font-medium text-[#81858c] text-sm">Supported ports: 443、1800、3333、8888</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-end w-1/2">
                                                        <button className="bg-[#eaecef] self-start rounded-md px-4 py-1 font-medium text-sm">Unit profit details</button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="flex gap-2 items-center py-3">
                                                        <p className="text-xs font-medium">Calculation</p><IoInformationCircleSharp fill="#81858c" />
                                                    </div>
                                                    <div className="flex gap-3 items-center py-5 border-b">
                                                        <div className="border flex py-2 px-2 h-14 items-center rounded-2xl">
                                                            <input value={1} className="bg-transparent outline-none" />
                                                            <p>Th/s</p>
                                                        </div>
                                                        <FaArrowRightLong />
                                                        <p className="text-sm font-medium">0.00012013 <span className="text-sm font-medium text-[#81858c]">{coin.symbol}</span></p>
                                                    </div>
                                                </div>
                                                <div className="flex w-full py-3">
                                                    <div className="w-1/2">
                                                        <div className="flex flex-col pb-3">
                                                            <p className="text-xs font-medium text-[#81858c]">Mining Fee</p>
                                                            <p className="text-sm">4.00%</p>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <p className="text-xs font-medium text-[#81858c]">Settlement Period</p>
                                                            <p className="text-sm">00:00 today to 00:00 UTC tomorrow</p>
                                                        </div>
                                                    </div>
                                                    <div className="w-1/2">
                                                        <div className="flex flex-col pb-3">
                                                            <p className="text-xs font-medium text-[#81858c]">Settlement Method</p>
                                                            <p className="text-sm">FPPS</p>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <p className="text-xs font-medium text-[#81858c]">Payout Time</p>
                                                            <p className="text-sm">02:00 to 10:00 UTC daily</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageCount={Math.ceil(coins.length / itemsPerPage)}
                onPageChange={handlePageClick}
                className="flex gap-2 mt-4 items-center justify-center"
                pageClassName="!pt-0.5 border rounded-sm bg-[#EFB81C] font-semibold text-center w-[32px] h-[32px]"
                previousClassName="!pt-0.5 border rounded-sm font-semibold text-center w-[32px] h-[32px]"
                nextClassName="!pt-0.5 border rounded-sm font-semibold text-center w-[32px] h-[32px]"
                activeClassName="items active"
            />                
        </div>
    );
}

export default MiningCoins;
