import Repository from "./Repository";
const recordsEndPoint = "/Finance/doDustHistory";
const convertEndPoint = "/Finance/doDustConvert";

export default {
    records(userId, token) {
        return Repository.get(`${recordsEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    convertToBcx(userId, token, param) {
        return Repository.get(`${convertEndPoint}${param}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
};
