import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { confirmCode, phoneResetPassword ,resetPasswordEmail} from "store/actions/authAction";
import { forgotCode, sendForgotCode } from "store/actions/authAction";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import ModalVerification from "../email/emailVerificationHelp";
const ChangePassword = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const inputRefs = useRef([]);
    const location = useLocation();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    // const [email, setEmail] = useState(
    //     localStorage.getItem("resetEmail") || ""
    // );
   /// const phone = localStorage.getItem("resetPhone") || "";
    const phone = location.state?.phone;
   // const email = useSelector((state) => state.authCheck.authStatus.email);
    const emailFromRedux = useSelector((state) => state.authCheck.authStatus.email);
    const email = location.state?.email;
  
    //const email = emailFromRedux || localStorage.getItem("resetEmail");
    const [emailPassword, setEmailPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [inputHasValue, setInputHasValue] = useState(false);
    const [code, setCode] = useState(Array(6).fill(""));
    const [showModal, setShowModal] = useState(false);
    const inputIds = Array(6)
        .fill("")
        .map(() => `code-input-${Math.random().toString(36).substr(2, 9)}`);

    const resetMethod = email ? "email" : phone ? "phone" : "";
   
    const togglePasswordVisibility = (type) => {
        if (type === "emailPassword") {
            setPasswordVisible(!passwordVisible);
        } else if (type === "confirmPassword") {
            setConfirmPasswordVisible(!confirmPasswordVisible);
        }
    };

    const handleCodeChange = (e, index) => {
        const value = e.target.value;
        if (/[^0-9]/.test(value)) return; // Allow only numbers

        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        // Move focus to the next input if there's a value
        if (value && index < code.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && !code[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData("text");
        const digits = pastedText.split("").slice(0, code.length);
        setCode(digits);
    };

    const resetPassword = (e) => {
        e.preventDefault();
    
        if (emailPassword !== confirmPassword) {
            toast.dismiss("error-toast");
            toast.error("Passwords do not match", { id: "error-toast" });
        } else {
            const confirmCodePayload = {
                [resetMethod]: resetMethod === "email" ? email : phone,
                verify_code: parseInt(code.join("")),
            };
    
            const resetPasswordPayload = {
                email: email,
                email_verify: parseInt(code.join("")),
                password: confirmPassword,
                repassword: emailPassword, 
            };
        
            const action =
                resetMethod === "email" ? confirmCode : phoneResetPassword;
    
        
            dispatch(action(confirmCodePayload))
                .then((response) => {
                    
                    if (response==true) {
                     
    
                 
                        dispatch(resetPasswordEmail(resetPasswordPayload))
                            .then((resetResponse) => {
                        
                                if (resetResponse==true) {
                                    
                                    history.push("/login"); 
                                }
                            })
                            .catch((error) => {
                                console.error("Error resetting password:", error);
                                toast.error(
                                    "Failed to reset password. Please try again."
                                );
                            });
                    } else {
                        toast.error(response?.data?.message || "Invalid verification code.");
                    }
                })
                .catch((error) => {
                    console.error("Error during verification:", error);
                    toast.error("Failed to verify. Please try again.");
                });
        }
    };
    

    useEffect(() => {
        if (
            emailPassword &&
            confirmPassword &&
            code.every((digit) => digit !== "")
        ) {
            setInputHasValue(true);
        } else {
            setInputHasValue(false);
        }
    }, [emailPassword, confirmPassword, code]);

    useEffect(() => {
        if (
            emailPassword &&
            confirmPassword &&
            emailPassword === confirmPassword
        ) {
            setInputHasValue(true);
        } else {
            setInputHasValue(false);
        }
    }, [emailPassword, confirmPassword]);

    const [timer, setTimer] = useState(60);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(interval);
        } else {
            setIsButtonDisabled(false);
        }
    }, [timer]);

    // const handleResendCode = () => {
       
    //     setTimer(60);
    //     setIsButtonDisabled(true);
    // };
    const handleResendCode = async () => {
        setTimer(60);
        setIsButtonDisabled(true); 
    
  
        if (email) {
            let payload = { email };
    
            console.log("Resend payload email", payload);
            try {
                const isSuccess = await dispatch(forgotCode(payload));
    
                if (isSuccess) {
                    console.log("Resent code successfully to email:", email);
                } else {
                    console.error("Failed to resend code to email");
                }
            } catch (error) {
                console.error("Error in handleResendCode (email):", error);
            }
        } else if (phone) {
            let payload = { phone };
    
            console.log("Resend payload phone", payload);
            try {
                const isSuccess = await dispatch(sendForgotCode(payload)); 
    
                if (isSuccess) {
                    console.log("Resent code successfully to phone:", phone);
                } else {
                    console.error("Failed to resend code to phone");
                }
            } catch (error) {
                console.error("Error in handleResendCode (phone):", error);
            }
        } else {
            console.error("Neither email nor phone is provided for resending the code.");
        }
    
   
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 60000); // 60 seconds
    };
    
    

    return (
        <div className="fixed inset-0 w-full bg-[#F4F4F4] max-lg:bg-[#FFFFFF] h-full">
            <div className="m-auto w-max p-8 max-lg:p-0 bg-[#FFFFFF] max-lg:bg-transparent h-max absolute inset-0 flex flex-col gap-2 text-[#151415] rounded-md">
                <h3 className="mb-2 text-xl font-bold w-max">Reset Password</h3>
                <p className="text-[12px] mb-4 w-max">
                    Please enter the verification code sent to <br />
                    <strong className="text-[12px]">
                        {resetMethod === "email" ? email : phone}
                    </strong>{" "}
                    and set a new password.
                </p>
                <form onSubmit={resetPassword} className="flex flex-col gap-4">
                    <input
                        type="text"
                        name="username"
                        value={resetMethod === "email" ? email : phone}
                        autoComplete="username"
                        className="sr-only"
                        readOnly
                    />
                    <div className="flex flex-col gap-2">
                        <label
                            htmlFor="code"
                            className="text-[13px] font-semibold"
                        >
                            Verification Code
                        </label>
                        <div className="flex justify-between gap-2">
                            {code.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    value={digit}
                                    onChange={(e) => handleCodeChange(e, index)}
                                    onKeyDown={(e) => handleBackspace(e, index)}
                                    onPaste={handlePaste}
                                    maxLength="1"
                                    ref={(ref) =>
                                        (inputRefs.current[index] = ref)
                                    }
                                    className="w-12 h-12 text-center text-lg bg-transparent border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-400"
                                />
                            ))}
                        </div>
                        <div className="flex gap-2 justify-end mb-8 items-center">
                            <p className="text-xs text-black cursor-pointer font-medium" onClick={() => setShowModal(true)}>Not able to receive verification code?</p>
                            <ModalVerification
                visible={showModal}
                onClose={() => setShowModal(false)}
                email={email}
            />
                            <button
                            onClick={handleResendCode}
                            disabled={isButtonDisabled}
                            className={`text-[#EFB81C] text-xs rounded-md ${
                                isButtonDisabled
                                    ? "text-gray-400 cursor-not-allowed"
                                    : "text-[#EFB81C]"
                            }`}
                        >
                            {isButtonDisabled
                                ? `Resend Code (${timer}s)`
                                : "Resend Code"}
                        </button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <label
                            htmlFor="password"
                            className="text-[13px] font-semibold"
                        >
                            New Password
                        </label>
                        <div className="relative w-max">
                            <input
                                type={passwordVisible ? "text" : "password"}
                                name="password"
                                placeholder="Enter new password"
                                autoComplete="new-password"
                                value={emailPassword}
                                onChange={(e) =>
                                    setEmailPassword(e.target.value)
                                }
                                className="w-[350px] px-3 py-2.5 bg-transparent placeholder:text-gray-300 placeholder:text-[12px] text-[13px] focus:ring-0 border border-[#151415] focus:border-[#EFB81C] rounded-md"
                            />
                            <button
                                type="button"
                                onClick={() =>
                                    togglePasswordVisibility("emailPassword")
                                }
                                className="absolute inset-y-0 flex items-center right-3"
                            >
                                {passwordVisible ? (
                                    <BsEyeFill className="w-5 h-5 text-gray-300" />
                                ) : (
                                    <BsEyeSlashFill className="w-5 h-5 text-gray-300" />
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <label
                            htmlFor="confirmPassword"
                            className="text-[13px] font-semibold"
                        >
                            Confirm Password
                        </label>
                        <div className="relative w-max">
                            <input
                                type={
                                    confirmPasswordVisible ? "text" : "password"
                                }
                                name="confirmPassword"
                                placeholder="Confirm new password"
                                autoComplete="new-password"
                                value={confirmPassword}
                                onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                }
                                className="w-[350px] px-3 py-2.5 bg-transparent placeholder:text-gray-300 placeholder:text-[12px] text-[13px] focus:ring-0 border border-[#151415] focus:border-[#EFB81C] rounded-md"
                            />
                            <button
                                type="button"
                                onClick={() =>
                                    togglePasswordVisibility("confirmPassword")
                                }
                                className="absolute inset-y-0 flex items-center right-3"
                            >
                                {confirmPasswordVisible ? (
                                    <BsEyeFill className="w-5 h-5 text-gray-300" />
                                ) : (
                                    <BsEyeSlashFill className="w-5 h-5 text-gray-300" />
                                )}
                            </button>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className={`btn font-semibold text-[14px] py-2 mt-6 w-full bg-[#EFB81C] ${
                            inputHasValue
                                ? "opacity-100 cursor-pointer"
                                : "opacity-50 cursor-not-allowed"
                        }`}
                        disabled={!inputHasValue}
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;
