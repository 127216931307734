import React, { useEffect, useState } from 'react';
import SimpleEarn from './SimpleEarn/SimpleEarn';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FinanceUserEarn } from 'store/actions/AssetsUserAction';
import FixedTerm from './FixedTerm/FixedTerm';

const MainTable = () => {
    const [activeItem, setActiveItem] = useState('Fixed Term'); // Default active item

    const discoverNavItems = ['Fixed Term'];
    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();
   
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const { data, loading, error } = useSelector(state => state.financeUserEarn);
    


    useEffect(() => {
        
        dispatch(FinanceUserEarn(userID, userToken)); 
      }, [dispatch, userID, userToken, history]);
console.log("data",data);
      useEffect(() => {
        if (data) {
          const { simpleEarn, fixedTerm } = data;
    
          if (activeItem === 'Simple Earn' && simpleEarn) {
            // Mapping over simpleEarn data and including all additional fields
            const coinList = Object.keys(simpleEarn).map((coinKey, index) => ({
              id: index,
              coinName: coinKey.toUpperCase(),
              amount: parseFloat(simpleEarn[coinKey].amount),
              coinType: simpleEarn[coinKey].title.match(/\[([^\[\]]+)\]/)?.[1].trim() || "Unknown",
              price: simpleEarn[coinKey].price,
              img: simpleEarn[coinKey].img,  // Image
              title: simpleEarn[coinKey].title,  // Title
              xnbd: simpleEarn[coinKey].xnbd,  // xnbd value
              xnbz: simpleEarn[coinKey].xnbz,  // xnbz value
              type: simpleEarn[coinKey].type,  // Type
              market_change: simpleEarn[coinKey].market_change,  // Market change
              volume: simpleEarn[coinKey].volume,  // Volume
            }));
            setTableData(coinList);
          } else if (activeItem === 'Fixed Term' && fixedTerm) {
            // Mapping over fixedTerm data and including all additional fields
            const coinList = Object.keys(fixedTerm).map((coinKey, index) => ({
              id: index,
              coinName: coinKey.toUpperCase(),
              amount: parseFloat(fixedTerm[coinKey].amount),
              coinType: fixedTerm[coinKey].title.match(/\[([^\[\]]+)\]/)?.[1].trim() || "Unknown",
              price: fixedTerm[coinKey].price,
              img: fixedTerm[coinKey].img,  // Image
              title: fixedTerm[coinKey].title,  // Title
              xnbd: fixedTerm[coinKey].xnbd,  // xnbd value
              xnbz: fixedTerm[coinKey].xnbz,  // xnbz value
              type: fixedTerm[coinKey].type,  // Type
              market_change: fixedTerm[coinKey].market_change,  // Market change
              volume: fixedTerm[coinKey].volume,  // Volume
            }));
            setTableData(coinList);
          }
          
        }
      }, [data, activeItem])
      console.log("data fetch",tableData);
    // Function to render the content based on active item
    const renderContent = () => {
        switch (activeItem) {
            case 'Fixed Term':
                return <FixedTerm data={tableData} />;             
            default:
                return <FixedTerm />;
        }
    };

    return (
        <div className=''>
            {/* Render only discoverNavItems */}
            <nav className='flex gap-3 w-full border-gray-200 pt-2'>
                {discoverNavItems.map((item) => (
                    <button
                        key={item}
                        onClick={() => setActiveItem(item)}
                        className={`relative py-2 px-2 text-md font-semibold text-ellipsis whitespace-nowrap ${
                            activeItem === item ? '' : 'text-gray-400'
                        }`}
                    >
                        {item}
                        {activeItem === item && (
                            <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                        )}
                    </button>
                ))}
            </nav>

            <div className="mt-4">{renderContent()}</div> {/* Render the content of the active tab */}
        </div>
    );
};

export default MainTable;
