
import {
    FETCH_CRYPTO_DEPOSIT_REQUEST,
    FETCH_CRYPTO_DEPOSIT_SUCCESS,
    FETCH_CRYPTO_DEPOSIT_FAILURE,
  } from "../types";
  
  const initialState = {
    loading: false,  
    data: null,     
    error: null,     
  };
  
  const cryptoDepositReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CRYPTO_DEPOSIT_REQUEST:
        return {
          ...state,
          loading: true,   
          error: null,    
        };
      case FETCH_CRYPTO_DEPOSIT_SUCCESS:
        return {
          ...state,
          loading: false, 
          data: action.payload,  
        };
      case FETCH_CRYPTO_DEPOSIT_FAILURE:
        return {
          ...state,
          loading: false, 
          error: action.payload, 
        };
      default:
        return state; 
    }
  };
  
  export default cryptoDepositReducer;
  