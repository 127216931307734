import React, { useEffect, useState } from "react";
import "../../components/home-page-sections/hero-section/./HeroSection.scss";
import LaunchpadProjects from '../../components/Launchpad/LaunchpadProjects';
const heroImages = [HeroImageOne, HeroImageTwo, HeroImageThird];
import HeroImageOne from "assets/img/Aboutsection4.png";
import HeroImageTwo from "assets/img/algo-orders.png";
import HeroImageThird from "assets/img/Aboutsection3.png";
import ProjectGrid from 'components/Launchpad/ProjectCard';
import LaunchProjectsSection from 'components/Launchpad/LaunchProjectsSection';
import GetStartedSection from 'components/Launchpad/GetStartedSection';
import { FaCopy } from 'react-icons/fa';
import Launchnavbar from "components/Launchpad/Launchnavbar";
import { BsQuestionCircle, BsWallet2 } from "react-icons/bs";
import { GoBook } from "react-icons/go";
import { MdOutlineWatchLater } from "react-icons/md";
const LaunchGuard = () => {

  const flippingWords = ["Deposit USDT To Lock Token Before its Officially Listed in Exchange.", "Unlock USDT With Guarded Refund Any Time Without Assets Loss."];
  const [currentWord, setCurrentWord] = useState("Exchange Crypto");
  const [flipState, setFlipState] = useState("flip-enter");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageTransitionState, setImageTransitionState] = useState("fade-in");

  useEffect(() => {
    const wordInterval = setInterval(() => {
      setFlipState("flip-exit");

      setTimeout(() => {
        setCurrentWord((prev) => {
          const nextIndex =
            (flippingWords.indexOf(prev) + 1) %
            flippingWords.length;
          return flippingWords[nextIndex];
        });
        setFlipState("flip-enter");
      }, 600);
    }, 3000);

    return () => clearInterval(wordInterval);
  }, []);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setImageTransitionState("fade-out");

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => {
          return (prevIndex + 1) % heroImages.length;
        });
        setImageTransitionState("fade-in");
      }, 600);
    }, 3000);

    return () => clearInterval(imageInterval);
  }, []);
  return (
    <>
      <div className="text-black min-h-screen">

        <section className="hero-section-black border-b flex flex-col relative mb-12 "> {/* Added mb-12 and pb-12 */}
          <div className="hero-section__wrapper bg-black text-white container flex items-center justify-between gap-8 w-full max-h-[80vh] m-auto max-md:flex-col">
            {/* Left Section */}
            <div className="hero-title-wrapper mt-8 flex flex-col gap-4 w-[60%] mb-16">
              <h1 className="hero-title flex flex-col gap-2 text-[48px] font-[600] leading-tight">
                <span className="text-[48px] font-[900] text-[#EFB81C]">Launch Guard</span>
                <span className="text-[28px]">Lock Now ! Reveal If Token Price Increase On Listing</span>
                {/* <span className="text-sm justify-center text-gray-400 ">Deposit USDT To Lock Token Before its Officially Listed in Exchang.</span> Reduced from mb-8 to mb-4 */}
                {/* <span className="text-sm justify-center text-gray-400 mb-4">Unlock USDT With Guarded Refund Any Time Without Assets Loss.</span> */}
              </h1>
              <p className="hero-description -mt-2"> {/* Negative margin to further reduce gap */}
                <span></span>{" "}
                <span className={`flip-text ${flipState}`}>{currentWord}</span>{" "}
              </p>

              <div className="hero-btn-wrap flex items-center gap-4 mt-2 mb-6">
                <button className="bg-[#EFB81C] text-white py-1 px-4 rounded-md">
                  Invite & Earn More Airdrops
                </button>
                <button className="border border-[#EFB81C] hover:bg-[#EFB81C] text-[#EFB81C] hover:text-black py-1 px-4 rounded-md">
                  <FaCopy className="inline-block mr-1" />
                </button>
              </div>
              <div className="flex gap-6">
                <button className="border px-3 py-0.5 font-medium rounded-full text-white text-xs"><p className="flex items-center gap-1"><BsWallet2 />Trading Rules</p></button>
                <button className="border px-3 py-0.5 font-medium rounded-full text-white text-xs"><p className="flex items-center gap-1"><BsQuestionCircle />FAQ</p></button>
                <button className="border px-3 py-0.5 font-medium rounded-full text-white text-xs"><p className="flex items-center gap-1"><GoBook />Trading Guide</p></button>
                <button className="border px-3 py-0.5 font-medium rounded-full text-white text-xs"><p className="flex items-center gap-1"><MdOutlineWatchLater />Beginner Guide</p></button>
              </div>
            </div>

            <div className="stats-wrapper flex flex-col items-center p-10 rounded-full border-4 border-[#EFB81C] w-[40%] h-full">
              {/* Stat Item 1 */}
              <div className="stat-item text-center flex flex-col justify-center mb-6">
                <p className="text-6xl font-bold text-[#EFB81C]">8250.0%</p>
                <p className="text-xl text-gray-400">ATH ROI</p>
                <p className="text-base text-gray-500">ArtWallet (1ART)</p>
              </div>

              {/* Circular Separator */}
              <div className="border-b border-gray-600 w-full mb-6"></div>

              {/* Stat Item 2 */}
              <div className="stat-item text-center flex flex-col justify-center">
                <p className="text-6xl font-bold text-[#EFB81C]">16.49%</p>
                <p className="text-xl text-gray-400">APY</p>
              </div>
            </div>







          </div>
          {/* Navbar */}

          <Launchnavbar />

        </section>


        {/* Other Components */}


        <LaunchpadProjects />

        <ProjectGrid />

        <LaunchProjectsSection />
        <GetStartedSection />
      </div>

    </>

  );
};

export default LaunchGuard;
