import React, { useState } from "react";
import { Modal } from "antd";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsExclamationCircleFill } from "react-icons/bs";
import { TbBrandGoogle } from "react-icons/tb";

const ChangeFundPasswordModal = ({
    visible,
    onClose,
    onConfirm,
    changefundPasswordSettings,
    handleResetFundPasswordClick,
    setChangeFundPasswordSettings,
}) => {
    const [showPassword, setShowPassword] = useState([false, false, false]);

    const togglePasswordVisibility = (index) => {
        const updatedShowPassword = [...showPassword];
        updatedShowPassword[index] = !updatedShowPassword[index];
        setShowPassword(updatedShowPassword);
    };

    return (
        <Modal
            title={<p>Change Fund Password</p>}
            visible={visible}
            onCancel={onClose}
            footer={null}
            okText="Change"
            cancelText="Cancel"
        >
            <div className="flex gap-2 bg-yellow-50 rounded-md p-2 items-start">
                <BsExclamationCircleFill
                    fill="#EFB81C"
                    className="mt-[5px]"
                    size={16}
                />
                <span className="font-medium w-full text-justify">
                    After changing your fund password, on-chain withdrawals,
                    internal transfers, fiat withdrawals, P2P transactions, and
                    BuyCex Card transactions will be temporarily suspended for
                    24 hours.
                </span>
            </div>
            <div className="mt-4">
                <div>
                    <p className="mb-2 font-medium text-[#81858c]">
                        Current Fund Password
                    </p>
                    <div className="relative">
                        <input
                            type={showPassword[0] ? "text" : "password"}
                            className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2"
                            placeholder="Please Enter"
                            onChange={(e) =>
                                setChangeFundPasswordSettings({
                                    ...changefundPasswordSettings,
                                    currentpassword: e.target.value,
                                })
                            }
                        />
                        <button
                            type="button"
                            onClick={() => togglePasswordVisibility(0)}
                            className="absolute right-3 top-2/4 transform -translate-y-2/4"
                        >
                            {showPassword[0] ? (
                                <AiOutlineEyeInvisible
                                    className="opacity-35"
                                    size={20}
                                />
                            ) : (
                                <AiOutlineEye
                                    className="opacity-35"
                                    size={20}
                                />
                            )}
                        </button>
                    </div>

                    <p className="mt-4 mb-2 font-medium text-[#81858c]">
                        New Fund Password
                    </p>
                    <div className="relative">
                        <input
                            type={showPassword[1] ? "text" : "password"}
                            className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2"
                            placeholder="Please Enter"
                            onChange={(e) =>
                                setChangeFundPasswordSettings({
                                    ...changefundPasswordSettings,
                                    newPassword: e.target.value,
                                })
                            }
                        />
                        <button
                            type="button"
                            onClick={() => togglePasswordVisibility(1)}
                            className="absolute right-3 top-2/4 transform -translate-y-2/4"
                        >
                            {showPassword[1] ? (
                                <AiOutlineEyeInvisible
                                    className="opacity-35"
                                    size={20}
                                />
                            ) : (
                                <AiOutlineEye
                                    className="opacity-35"
                                    size={20}
                                />
                            )}
                        </button>
                    </div>

                    <div className="mt-4">
                        <p className="mb-2 font-medium text-[#81858c]">
                            Confirm Fund Password
                        </p>
                        <div className="relative">
                            <input
                                type={showPassword[2] ? "text" : "password"}
                                className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2"
                                placeholder="Please Enter"
                                onChange={(e) =>
                                    setChangeFundPasswordSettings({
                                        ...changefundPasswordSettings,
                                        confirmNewPassword: e.target.value,
                                    })
                                }
                            />
                            <button
                                type="button"
                                onClick={() => togglePasswordVisibility(2)}
                                className="absolute right-3 top-2/4 transform -translate-y-2/4"
                            >
                                {showPassword[2] ? (
                                    <AiOutlineEyeInvisible
                                        className="opacity-35"
                                        size={20}
                                    />
                                ) : (
                                    <AiOutlineEye
                                        className="opacity-35"
                                        size={20}
                                    />
                                )}
                            </button>
                        </div>
                        <p onClick={() => {
                            handleResetFundPasswordClick();
                            onClose();
                        }} className="text-end my-2 cursor-pointer text-sm font-medium">
                            Forgot Fund Password?
                        </p>
                    </div>
                    <div className="mt-4 w-full">
                        <div className="flex items-center gap-1 mb-2 font-medium text-[#81858c]">
                            <TbBrandGoogle /> <p>Google verification code</p>
                        </div>
                        <input
                            type="number"
                            className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2 no-arrows"
                            placeholder="Please enter your Google Authenticator code"
                            style={{ appearance: "textfield" }}
                            onChange={(e) =>
                                setChangeFundPasswordSettings({
                                    ...changefundPasswordSettings,
                                    g2faOtp: e.target.value,
                                })
                            }
                        />
                    </div>
                    <button
                        onClick={() => {
                            onConfirm();
                            // onClose();
                        }}
                        className="bg-[#EFB81C] mt-5 py-2 text-center rounded-md w-full font-semibold"
                    >
                        Confirm
                    </button>
                    <style>
                        {`
                .no-arrows::-webkit-inner-spin-button,
                .no-arrows::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .no-arrows {
                    -moz-appearance: textfield; /* For Firefox */
                }
                `}
                    </style>
                </div>
            </div>
        </Modal>
    );
};

export default ChangeFundPasswordModal;
