import React from 'react';
import image20Million from '../../assets/img/Aboutsection4.png'; 
import { Link } from 'react-router-dom/cjs/react-router-dom';

const ProjectCard = ({ project }) => {
  return (
    <div className="relative bg-white rounded-lg shadow-lg p-4 w-full sm:w-1/2 md:w-1/4">
      <span className="absolute top-2 left-0 z-50 bg-[#EFB81C] self-start text-[#48bf5b] text-sm font-semibold px-2 py-1 rounded">
        Closed
      </span>
      <img src={project.image} alt={project.name} className="w-full h-32 object-cover rounded-t-lg" />
      <div className="mt-4">
        <div className='flex justify-between w-full'>
        <div className='flex flex-col'>
        <span className="bg-[#EFB81C] self-start text-white text-sm font-semibold px-2 py-1 rounded">{project.badge}</span>
        <h3 className="text-lg font-semibold mt-2">{project.name}</h3>
        </div>
        <div className='flex flex-col items-end justify-end'>
        <span className="ml-2 text-[#48bf5b] font-semibold text-sm">{project.type}</span>
        <span className="ml-2 text-gray-500 text-sm">10025.325</span>
        <span className="ml-2 text-gray-500 text-sm">USDT</span>
        </div>
        </div>
        <p className="text-gray-600 text-sm">Fundraised: {project.fundraised}</p>
        <p className="text-gray-600 text-sm">Fundraising Progress: <span className='text-[#ee4444] font-medium'>{project.progress}</span></p>
        <button className="mt-4 w-full bg-gray-200 text-gray-500 text-sm font-bold py-2 px-4 rounded-lg cursor-not-allowed">
          Ended
        </button>
      </div>
    </div>
  );
};

const ProjectGrid = () => {
  const projects = [
    {
      name: 'TERRA',
      image: image20Million,
      fundraised: '84,742,420 TERRA',
      progress: 'Period Ended',
      badge: 'Unlock',
      type: 'Total Locked Fund',
    },
    {
      name: 'TERRA',
      image: image20Million,
      fundraised: '84,742,420 TERRA',
      progress: 'Period Ended',
      badge: 'Unlock',
      type: 'Total Locked Fund',
    },
    {
      name: 'TERRA',
      image: image20Million,
      fundraised: '84,742,420 TERRA',
      progress: 'Period Ended',
      badge: 'Unlock',
      type: 'Total Locked Fund',
    },
    {
      name: 'TERRA',
      image: image20Million,
      fundraised: '84,742,420 TERRA',
      progress: 'Period Ended',
      badge: 'Unlock',
      type: 'Total Locked Fund',
    },
  ];

  const handleMoreClick = () => {
    console.log('More button clicked!');
  };

  return (
    <div className="container py-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold">Finished (3)</h2>
        <Link to='/trade/startup-mining/ended'>
        <button
          onClick={handleMoreClick}
          className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700"
          id="more-button"
        >
          View All
        </button>
        </Link>
      </div>
    
      <div className="flex flex-wrap gap-4 justify-center">
      {projects.slice(0, 3).map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </div>
    </div>
  );
};

export default ProjectGrid;
