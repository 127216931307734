import React, { useEffect, useState } from 'react';
import Subscribed from './Subscribed';

const MainTable = ({ buttonType }) => {
    const [activeItem, setActiveItem] = useState('Subscribed');

    const discoverNavItems = ['Subscribed'];
    
    const renderContent = () => {
        switch (activeItem) {
            case 'Subscribed':
                return <Subscribed buttonType={buttonType} />;             
            default:
                return <Subscribed buttonType={buttonType} />;
        }
    };

    return (
        <div className=''>
            <nav className='flex gap-3 w-full border-gray-200 pt-2'>
                {discoverNavItems.map((item) => (
                    <button
                        key={item}
                        onClick={() => setActiveItem(item)}
                        className={`relative py-2 px-2 text-md font-semibold text-ellipsis whitespace-nowrap ${
                            activeItem === item ? '' : 'text-gray-400'
                        }`}
                    >
                        {item}
                        {activeItem === item && (
                            <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                        )}
                    </button>
                ))}
            </nav>

            <div className="mt-4">{renderContent()}</div>
        </div>
    );
};

export default MainTable;
