import React, { useState, useEffect } from "react";
import { FaAngleDown, FaAngleUp, FaAngleRight } from "react-icons/fa";

const NetworkSelect = ({ networks, selectedNetwork, setSelectedNetwork,selectedCrypto }) => {
    const [loading, setLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredNetworks, setFilteredNetworks] = useState(networks);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 800); 
    }, []);

    useEffect(() => {
        const filtered = networks.filter((network) =>
            network.label.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredNetworks(filtered);
    }, [searchQuery, networks]);

    const handleSelect = (network) => {
        setSelectedNetwork(network);
        setIsDropdownOpen(false); 
        setSearchQuery(""); 
    };

    const closeDropdown = (e) => {
        if (e.target.closest(".dropdown-container")) return;
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        document.addEventListener("click", closeDropdown);
        return () => document.removeEventListener("click", closeDropdown);
    }, []);

    return (
        <>
            <label className={`block mb-4 mt-9 text-[20px] font-medium  ${selectedCrypto ? 'text-gray-900' : 'text-gray-400'}`}>
            Select Network
            </label>
            <div className="relative dropdown-container">
                {loading ? (
                    <div className="animate-pulse">
                        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
                    </div>
                ) : (
                    <div className="relative">
                        <div
                            className={`flex items-center justify-between ${selectedCrypto ? 'border border-black hover:ring-1 ring-[#EFB81C] hover:border-[#EFB81C]' : 'border'} rounded-lg px-4 py-4 cursor-pointer bg-white dark:bg-gray-800 dark:text-white`}
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        >
                            <div className="flex items-center">
                                <span className={`text-sm ${selectedCrypto ? 'text-gray-900' : 'text-gray-400'}`}>
                                    {selectedNetwork?.label || "Select Network"}
                                </span>
                            </div>
                            <span>
                                {isDropdownOpen ? <FaAngleUp className={`text-sm ${selectedCrypto ? 'text-gray-900' : 'text-gray-400'}`}/> : <FaAngleDown className={`text-sm ${selectedCrypto ? 'text-gray-900' : 'text-gray-400'}`}/>}
                            </span>
                        </div>

                        {isDropdownOpen && (
                            <div className="absolute mt-2 w-full bg-white border rounded shadow-lg dark:bg-gray-800 dark:text-white z-10">
                                <div className="p-2">
                                    <input
                                        type="text"
                                        placeholder="Search networks"
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#EFB81C] dark:bg-gray-700 dark:text-white"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                                <ul className="max-h-64 overflow-auto">
                                    {filteredNetworks.length > 0 ? (
                                        filteredNetworks.map((network) => (
                                            <li
                                                key={network.value}
                                                className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                                                onClick={() => handleSelect(network)}
                                            >
                                                {network.label}
                                            </li>
                                        ))
                                    ) : (
                                        <li className="px-4 py-2 text-center text-gray-500 dark:text-gray-400">
                                            No results found
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {selectedNetwork &&
            <div className="flex items-center w-full justify-between mt-2">
                <p className="text-[12px]">
                    Contract address ending in
                </p>
                <div className="flex items-center">
                    <p className="text-[12px]">jL6t</p>
                    <FaAngleRight className="opacity-55" size={12} />
                </div>
            </div>
            }
        </>
    );
};

export default NetworkSelect;