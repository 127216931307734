import Repository from "./Repository";

const WITHDRAW = "/Crypto/doWithdraw";
const CRYPTO_WITHDRAW = "/wallet/upCryptoWithdrawal";
const UPWALLET = "/User/upwallet";
const FETCH_WITHDRAWALS = "/Crypto/MyWithdrawals";
export default {
    async doWithdraw(payload, token,userId) {
        console.log("Withdraw Payload:", payload);

        // Create FormData object
        const formData = new FormData();
        formData.append("coin", payload.coin);
        formData.append("num", payload.num);
        formData.append("addr", payload.addr);
        formData.append("ga", payload.ga);
       // formData.append("verify", payload.verify);

        try {
            const response = await Repository.post(WITHDRAW, formData, {
                headers: {
                    "TOKEN": token,
                    "ID": userId,
                    "Content-Type": "multipart/form-data",
                },
            });

            console.log("Withdraw Response:", response);
            return response;
        } catch (error) {
            console.error("Error during withdrawal:", error.message);
            throw error;
        }
    },

    async upWallet(payload, token, userId) {
        console.log("UpWallet Payload:", payload);

        // Create FormData object
        const formData = new FormData();
        formData.append("coin", payload.coin);
        formData.append("name", payload.name);
        formData.append("addr", payload.addr);
        formData.append("dest_tag", payload.dest_tag || "");
        formData.append("paypassword", payload.paypassword);

        try {
            const response = await Repository.post(UPWALLET, formData, {
                headers: {
                    "TOKEN": token,
                    "ID": userId,
                    "Content-Type": "multipart/form-data",
                },
            });

            console.log("UpWallet Response:", response);
            return response;
        } catch (error) {
            console.error("Error during wallet update:", error.message);
            throw error;
        }
    },
    async upCryptoWithdrawal(payload, token, userId) {
        console.log("Crypto Withdrawal Payload:", payload);

        // Create FormData object
        const formData = new FormData();
        formData.append("coin", payload.coin);
        formData.append("network", payload.network);
        formData.append("otp", payload.otp);
        formData.append("num", payload.num);
        formData.append("paypassword", payload.paypassword);
        formData.append("cellphone_verify", payload.cellphone_verify);
        formData.append("addr", payload.addr);
        formData.append("dest_tag", payload.dest_tag || "");
        formData.append("gacode", payload.gacode);

        try {
            const response = await Repository.post(CRYPTO_WITHDRAW, formData, {
                headers: {
                    TOKEN: token,
                    ID: userId,
                    "Content-Type": "multipart/form-data",
                },
            });

            console.log("Crypto Withdrawal Response:", response);
            return response;
        } catch (error) {
            console.error("Error during crypto withdrawal:", error.message);
            throw error;
        }
    },
    async fetchWithdrawals(coin, token, userId) {
        try {
          const response = await Repository.get(`${FETCH_WITHDRAWALS}?coin=${coin}`, {
            headers: {
              TOKEN: token,
              ID: userId,
            },
          });
    
          console.log("Fetch Withdrawals Response:", response);
          return response.data; // Return data for further processing
        } catch (error) {
          console.error("Error fetching withdrawals:", error.message);
          throw error;
        }
      },
};
