import React, { useState, useEffect } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import logo from "assets/img/logo/logo-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Input, Form, Select, Button, Typography, Space } from "antd";
import { getConvertToBCX } from "store/actions/BCXAction";
import fetchAssetsData from "store/actions/AssetsUserAction";
import { IoMailOutline } from "react-icons/io5";
import { sendCodeToEmail, verifyCodeFromEmail } from "store/actions/authAction";
const IMAGE_BASE_URL = "https://api.buycex.com/Upload/coin/";

const { Text } = Typography;

function ConvertBcxPopup({ activePopup, onOk, onCancel }) {
    const dispatch = useDispatch();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedCoin, setSelectedCoin] = useState(null);
    const [isTimerActive, setIsTimerActive] = useState(false);
    const [selectedCoinOptions, setSelectedCoinOptions] = useState([]);
    const [otp, setOtp] = useState("");
    const [timer, setTimer] = useState(60);
    const { assetsData, loading: assetsLoading } = useSelector(
        (state) => state.assetsData || {}
    );
    const user_email = useSelector((state) => state.authCheck.authStatus.email);

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const token = useSelector((state) => state.authUser?.user?.TOKEN);
    useEffect(() => {
        dispatch(fetchAssetsData(userID, token, "fund"));
    }, [dispatch, userID, token]);

    const [form] = Form.useForm();


    const handleSelectCoin = (coin) => {
        setSelectedCoin(coin);
        setIsDropdownOpen(false);
    };

    const handleSendCode = () => {
        if (!isTimerActive) {
          
            const payloadE = {
                email: user_email,
                user_id: userID,
            };
            dispatch(sendCodeToEmail(payloadE)); // Dispatch for step 1
           
            setIsTimerActive(true);
            setTimer(60);
        }
    };

    useEffect(() => {
        if (isTimerActive && timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval); // Cleanup on component unmount
        }
        if (timer === 0) {
            setIsTimerActive(false); // Reset the timer state when it reaches 0
        }
    }, [isTimerActive, timer]);
    const onFinish = async (values) => {
        const payload = {
            user_id: userID,
            code: otp,
        };
        const res = await dispatch(
            verifyCodeFromEmail(payload, true, (res) => {
                console.log("Res:", res);
            })
        );
        if(res){
            const param = `?user_id=${userID}&coin=${
                values?.coin || ""
            }&dust_coin=bcx&amount=${values.amount || 1}`;
    
            dispatch(getConvertToBCX(userID, token, param, onCancel));
        }
    };
    useEffect(() => {
        if (assetsData?.cryptoList) {
            const coinsData = [];

            // Process cryptoList
            Object.keys(assetsData.cryptoList).forEach((coinKey) => {
                const coin = assetsData.cryptoList[coinKey];
                coinsData.push({
                    value: coinKey.toLowerCase(),
                    ticker: coinKey.toUpperCase(),
                    label: coin.title,
                    icon: `${IMAGE_BASE_URL}${coin.img}`,
                });
            });

            setSelectedCoin(coinsData[0] || null);
            setSelectedCoinOptions(coinsData);
        }
    }, [assetsData]);

    return (
        <div>
            <Modal
                open={activePopup}
                onOk={onOk}
                onCancel={onCancel}
                footer={null}
            >
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <div className="flex mt-6 w-full items-center justify-between">
                        <div className="relative w-1/2 rounded-md">
                            <Form.Item
                                name="coin"
                                label="Select Coin"
                                rules={[
                                    {
                                        required: true,
                                        message: "Coin is required",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select Coin"
                                    value={selectedCoin}
                                    onSelect={handleSelectCoin}
                                    suffixIcon={
                                        isDropdownOpen ? (
                                            <FaCaretUp />
                                        ) : (
                                            <FaCaretDown />
                                        )
                                    }
                                    onDropdownVisibleChange={(open) =>
                                        setIsDropdownOpen(open)
                                    }
                                >
                                    {selectedCoinOptions.map((coin) => (
                                        <Option key={coin} value={coin.value}>
                                            {coin.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="flex w-1/2 items-center">
                            <div className="px-3">To</div>
                            <div className="flex gap-2 items-center bg-[#f9f9fb] cursor-pointer p-2 w-full rounded-md">
                                <img
                                    src={logo}
                                    alt="logo"
                                    className="w-6 h-6"
                                />
                                <p className="font-semibold text-sm">
                                    BCX (BUYCEX)
                                </p>
                            </div>
                        </div>
                    </div>

                    <Form.Item
                        name="amount"
                        label="Amount"
                        rules={[
                            {
                                required: true,
                                message: "Amount is required",
                            },
                        ]}
                    >
                        <Input placeholder="Please enter amount" suffix="All" />
                    </Form.Item>

                    <Form.Item>
                        <div className="flex justify-between text-gray-400 text-sm">
                            <Text>Transferable Amount</Text>
                            <Text>0</Text>
                        </div>
                    </Form.Item>

                    <Form.Item
                        label={
                            <div className="flex text-[#81858c] items-center gap-1">
                                <IoMailOutline />
                                <h6 className="text-sm text-[#81858c]">
                                    A code has been sent to the{" "}
                                    <span className="text-md font-semibold text-black">
                                        {user_email}
                                    </span>{" "}
                                    .
                                </h6>
                            </div>
                        }
                    >
                        <Space.Compact style={{ width: "100%" }}>
                        <div className="flex justify-between bg-gray-100 rounded-md py-2 px-4 w-full">
                                <input
                                    type="text"
                                    placeholder="Email Verification Code"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    className="bg-transparent text-black font-medium focus:outline-none border-none"
                                />
                                <div>
                                    <span
                                        className={`text-[#F1BA19] font-medium text-sm ${
                                            isTimerActive
                                                ? "cursor-not-allowed opacity-50"
                                                : "cursor-pointer"
                                        }`}
                                        onClick={handleSendCode}
                                    >
                                        Send Verification Code{" "}
                                        {isTimerActive ? `(${timer}s)` : ""}
                                    </span>
                                </div>
                            </div>
                            {/* <Input
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder={"Email Verification Code"}
                            />
                            <Button
                                className={`px-4 font-semibold text-ellipsis whitespace-nowrap ${
                                    isTimerActive
                                        ? "text-[#EFB81C] cursor-not-allowed opacity-60"
                                        : "text-[#EFB81C] cursor-pointer"
                                }`}
                                onClick={handleSendCode}
                                disabled={isTimerActive}
                            >
                                {isTimerActive
                                    ? `Send Code (${timer}s)`
                                    : "Send Code"}
                            </Button> */}
                        </Space.Compact>
                    </Form.Item>

                    <Form.Item>
                        <button
                            type="submit"
                            className="bg-[#EFB81C] w-full py-2 rounded-md font-semibold mt-6"
                        >
                            Confirm
                        </button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default ConvertBcxPopup;
