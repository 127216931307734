import { 
    DO_TRADE_FAILURE, 
    DO_TRADE_SUCCESS, 
    DO_TRADE_REQUEST,
    CALL_TRADE_FAILURE, 
    CALL_TRADE_SUCCESS, 
    CALL_TRADE_REQUEST,
    CALC_ASSET_VALUE_FAILURE, 
    CALC_ASSET_VALUE_SUCCESS, 
    CALC_ASSET_VALUE_REQUEST,   
    GET_CONVERTED_AMOUNT_FAILURE,
    GET_CONVERTED_AMOUNT_SUCCESS,
    GET_CONVERTED_AMOUNT_REQUEST
} from "../types";

const initialState = { 
    loading: false, 
    tradeData: null, 
    error: null, 
    balances: {} 
};

const cryptoConvertReducer = (state = initialState, action) => {
    switch (action.type) {
        // Handle all request actions (loading)
        case DO_TRADE_REQUEST:
        case CALL_TRADE_REQUEST:
        case CALC_ASSET_VALUE_REQUEST:
            return { ...state, loading: true, error: null };
        
        case GET_CONVERTED_AMOUNT_REQUEST: 
            return { ...state, loading: true, error: null };
        // Handle success actions
        case DO_TRADE_SUCCESS:
        case CALL_TRADE_SUCCESS:
            // Check if payload contains the necessary data
            if (action.payload && action.payload.status === 1) {
                // Only update tradeData when status is 1 (success)
                return { 
                    ...state, 
                    loading: false, 
                    tradeData: action.payload, 
                    error: null 
                };
            }
            // If status is not 1, treat it as a failure
            return { 
                ...state, 
                loading: false, 
                tradeData: null, 
                error: action.payload?.info || "An error occurred"
            };

        // Handle balance calculation success
        case CALC_ASSET_VALUE_SUCCESS:
            const { coin, balance, ...rest } = action.payload; // Destructure the payload

            return { 
                ...state, 
                loading: false, 
                balances: { 
                    ...state.balances, 
                    [coin]: { 
                        balance, 
                        ...rest 
                    }
                }, 
                error: null 
            };

        case GET_CONVERTED_AMOUNT_SUCCESS:
                return { 
                    ...state, 
                    loading: false, 
                    conversionData: action.payload, 
                    error: null 
                };

        // Handle failure actions
        case DO_TRADE_FAILURE:
        case CALL_TRADE_FAILURE:
        case CALC_ASSET_VALUE_FAILURE:
            return { 
                ...state, 
                loading: false, 
                tradeData: null, 
                error: action.payload 
            };

        case GET_CONVERTED_AMOUNT_FAILURE: 
            return { 
                ...state, 
                loading: false, 
                error: action.payload 
            };
        default:
            return state;
    }
};

export default cryptoConvertReducer;
