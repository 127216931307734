import Repository from "./Repository";  

const cryptoDeposit = "/Wallet/cryptodeposit"; 

class CryptoDepositRepository {

  async fetchCryptoDeposit(selectedCrypto, token, userId) {
    try {

      const coinName = selectedCrypto && selectedCrypto.value ? selectedCrypto.value : selectedCrypto;
      
    
      const headers = {
        TOKEN: token,
        ID: userId,
      };

      const response = await Repository.get(
        `${cryptoDeposit}?coin=${coinName.toLowerCase()}`,
        { headers }
      );

      console.log("Fetch Crypto Deposit Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching crypto deposit:", error.message);
      throw error;
    }
  }
}

export default new CryptoDepositRepository();
