import React, { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineFeaturedPlayList } from "react-icons/md";
import { Link } from "react-router-dom";

import {
    fetchCurrentOrders,
    fetchLgProjects,
} from "store/actions/LaunchGuardAction";

import HistorcialProject from "./HistorcialProject";
import UpcomingProject from "./UpcomingProject";
import LatestProject from "./LatestProject";

function Navbar() {
    const dispatch = useDispatch();
    const [activeComponent, setActiveComponent] = useState("latest");

    const userID = useSelector((state) => state?.authUser?.user?.ID);
    const userToken = useSelector((state) => state?.authUser?.user?.TOKEN);
    const projects = useSelector((state) => state?.lgData?.projects);

    useEffect(() => {
        dispatch(fetchLgProjects(userID, userToken));
    }, [dispatch, userID, userToken]);

    const tabs = [
        { id: "latest", label: "Latest Projects" },
        { id: "upcoming", label: "Upcoming Projects" },
        { id: "historical", label: "Historical Projects" },
    ];

    return (
        <>
            <div className="border-b bg-[#EFB81C] flex justify-between w-full h-10">
                <div className="container flex justify-between w-full">
                    <div className="flex gap-7">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                className={`text-sm h-8 font-medium relative ${
                                    activeComponent === tab.id
                                        ? "text-[#4B5563]"
                                        : "text-[#4B5563]"
                                }`}
                                onClick={() => setActiveComponent(tab.id)}
                            >
                                {tab.label}
                                {activeComponent === tab.id && (
                                    <span className="absolute left-1/2 transform -translate-x-1/2 bottom-0 w-auto px-2 h-1 bg-white rounded-full" />
                                )}
                            </button>
                        ))}
                    </div>
                    <div className="flex gap-4 items-center">
                        <div className="border border-[#4B5563] flex gap-2 items-center px-2 py-0.5 rounded-md">
                            <IoSearchOutline
                                className="text-[#81858c]"
                                size={14}
                            />
                            <input
                                placeholder="Search"
                                className="placeholder:text-sm placeholder:text-[#4B5563] bg-transparent"
                            />
                        </div>
                        <Link to="/trade/launch-pad/my-order">
                            <button className="flex gap-2 items-center text-sm font-medium text-[#4B5563]">
                                <MdOutlineFeaturedPlayList />
                                My Order
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="p-4">
                {activeComponent === "latest" && (
                    <LatestProject latest_projects={projects?.data?.list_running} />
                )}
                {activeComponent === "upcoming" && <UpcomingProject />}
                {activeComponent === "historical" && <HistorcialProject />}
            </div>
        </>
    );
}

export default Navbar;
