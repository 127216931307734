import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ContinueBtn from "components/cryptoWithdraw/ContinueBtn";

import toast from "react-hot-toast";
import { Modal } from "antd";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { IoMailOutline } from "react-icons/io5";
import { TbPasswordFingerprint } from "react-icons/tb";
import { Link } from "react-router-dom/cjs/react-router-dom";
import G2faOtpVerification from "./otpHelper";
import {
    verifyCodeFromEmail,
    SetEmail,
    sendCodeToEmail,
    verifySaveEmail,
    verifyG2faCode,
} from "store/actions/authAction";

const InternalTransfer = ({ selectedCoin }) => {
    const dispatch = useDispatch();

    const { authStatus, authStatusError } = useSelector(
        (state) => state.authCheck
    );

    const history = useHistory();
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    const [otp, setOtp] = useState("");
    const [coin, setCoin] = useState(selectedCoin?.value.toLowerCase() || "");
    const [num, setNum] = useState("");
    const [address, setAddress] = useState("");
    const [payPassword, setPayPassword] = useState("");
    const [payMethod, setPayMethod] = useState("email");
    const [responseMessage, setResponseMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [errors, setErrors] = useState({});
    const [uidInputVisible, setUidInputVisible] = useState(false);
    const [receiver_id, setReceiverId] = useState("");
    const [showErrors, setShowErrors] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState({});
    const [timer, setTimer] = useState(60);
    const email = useSelector((state) => state.authCheck.authStatus.email);

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [g2faOtp, setG2faOtp] = useState("");
    const [showG2FT, setShowG2FT] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
        }
    }, [TOKEN, ID, history]);

    useEffect(() => {
        if (selectedCoin) {
            setCoin(selectedCoin.value.toLowerCase());
        }
    }, [selectedCoin]);

    useEffect(() => {
        setPayMethod("email");
    }, []);

    useEffect(() => {
        // if (isSubmitted === false) {
        //     validateForm();
        // }
    }, [coin, num, address, payPassword, isSubmitted]);

    const validateForm = () => {
        let errors = {};

        if (!num && showErrors) {
            toast.error("Amount is required.");
        }
        if (num && parseFloat(num) < 0.01 && showErrors) {
            toast.error("Amount must be at least 0.01.");
        }
        if (!address && !receiver_id && showErrors) {
            toast.error("Either email address or receiver ID is required.");
        }

        setErrors(errors);
        setIsFormValid(
            Object.keys(errors).length === 0 &&
                !!num &&
                (address || receiver_id)
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (otp === "") {
            toast.error("Please enter an OTP.");
            return;
        }

        if (payPassword === "") {
            toast.error("Please enter Pay Password.");
            return;
        }

        // validateForm();

        if (!address && !receiver_id && !num) {
            toast.error(
                "Please enter an amount and either an email address or receiver ID."
            );
            return;
        }

        if ((address || receiver_id) && !num) {
            toast.error(
                "Amount is required when an email address or receiver ID is provided."
            );
            return;
        }

        if (!isSubmitting) {
            setIsSubmitting(true);

            if (parseFloat(num) < 0.01) {
                toast.error("Amount must be at least 0.01.");
                setIsSubmitting(false);
                return;
            }

            setIsLoading(true);

            try {
                const formData = new FormData();
                formData.append("user_id", ID);
                formData.append("otp", otp);
                formData.append("coin", coin);
                formData.append("num", num);
                formData.append("paypassword", payPassword);
                formData.append("paymethod", payMethod);

                if (payMethod === "email") {
                    formData.append("address", address);
                } else {
                    formData.append("receiver_id", receiver_id);
                }

                const response = await axios.post(
                    "https://api.buycex.com/Pay/doPaymentSend",
                    formData,
                    { headers: { TOKEN, ID } }
                );

                if (response.data.status === 1) {
                    // setShowG2FT(true);
                    toast.success("Transaction successful!");
                    const transactionTime = new Date().toLocaleString();
                    setShowModal(false);
                    setCoin(selectedCoin.value.toLowerCase());
                    setNum("");
                    setOtp("");
                    setAddress("");
                    setPayPassword("");
                    setPayMethod("");
                    setReceiverId("");
                    setTransactionDetails({
                        amount: num,
                        receiver: payMethod === "email" ? address : receiver_id,
                        currency: coin,
                        transactionTime: transactionTime,
                        method: payMethod,
                    });

                    setShowSuccessModal(true);
                    closeModal();
                } else if (response.data.status === 0) {
                    toast.error(response.data.data || "Transaction failed.");
                    setResponseMessage(response.data.data);
                    setShowModal(true);
                } else {
                    // toast.error("Transaction failed.");
                    // setResponseMessage("Transaction failed.");
                    setShowModal(true);
                }
            } catch (error) {
                // toast.error(
                //     "Error occurred. Please check console for details."
                // );
                // console.error("Submission error:", error); // Error log for more details
            } finally {
                setIsLoading(false);
                setIsSubmitting(false);
            }
        } else {
            // console.log("Form is either invalid or currently submitting."); // Debugging check
        }
    };

    const handlePayMethodChange = (method) => {
        setPayMethod(method);
        if (method === "payid") {
            setUidInputVisible(true);
        } else {
            setUidInputVisible(false);
        }
    };
    const closeModal = () => {
        setShowModal(false);
        setShowSuccessMessage(false);
    };

    const handleSendCode = () => {
        if (!isButtonDisabled) {
            const payloadE = {
                email: email,
                user_id: userID,
            };
            dispatch(sendCodeToEmail(payloadE)); // Dispatch for sending code to email
            setIsButtonDisabled(true);
        }
    };

    useEffect(() => {
        let interval = null;
        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        return 60;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isButtonDisabled]);

    const handle2faSuccess = async () => {
        setG2faOtp(false);
        setTimeout(async () => {
            setG2faOtp(false);
        }, 1000);
    };

    return (
        <div className="w-full">
            <div className="inline-flex w-[100%] my-3 rounded-md" role="group">
                <button
                    type="button"
                    className={`px-4 py-2 text-sm font-semibold rounded-md dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-[#EFB81C] ${
                        payMethod === "email"
                            ? "text-black bg-[#F5F5F5]"
                            : "hover:text-black text-[#707a8a]"
                    }`}
                    onClick={() => handlePayMethodChange("email")}
                >
                    Email
                </button>

                <button
                    type="button"
                    className={`px-4 py-2 text-sm font-semibold rounded-md dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-[#EFB81C] ${
                        payMethod === "payid"
                            ? "text-black bg-[#F5F5F5]"
                            : "hover:text-black text-[#707a8a]"
                    }`}
                    onClick={() => handlePayMethodChange("payid")}
                >
                    User ID
                </button>
            </div>
            <form onSubmit={handleSubmit}>
                {!uidInputVisible && (
                    <>
                        <p className="text-[#81858c] mt-3 text-sm mb-1">
                            Email Address
                        </p>
                        <input
                            type="email"
                            placeholder="Recipient's email"
                            className="bg-[#f3f5f7] h-14 rounded-[10px] text-gray-900 text-sm focus:ring-1 focus:ring-[#EFB81C] focus:border-[#EFB81C] dark:border-[#EFB81C] focus:outline-none block w-full p-2.5 dark:bg-transparent dark:placeholder-gray-400 dark:text-white dark:focus:!border-[#EFB81C] dark:focus:outline-none"
                            onChange={(e) => setAddress(e.target.value)}
                            onFocus={() => setShowErrors(false)}
                        />
                    </>
                )}
                {uidInputVisible && (
                    <>
                        <p className="text-[#81858c] mt-3 text-sm mb-1">
                            Receiver ID
                        </p>
                        <input
                            type="text"
                            placeholder="Enter Receiver ID"
                            className="bg-[#f3f5f7] h-14 rounded-[10px] text-gray-900 text-sm focus:ring-1 focus:ring-[#EFB81C] focus:border-[#EFB81C] dark:border-[#EFB81C] focus:outline-none block w-full p-2.5 dark:bg-transparent dark:placeholder-gray-400 dark:text-white dark:focus:!border-[#EFB81C] dark:focus:outline-none"
                            onChange={(e) => setReceiverId(e.target.value)}
                            onFocus={() => setShowErrors(false)}
                        />
                    </>
                )}
                <p className="text-[#81858c] text-sm mt-6 mb-1">Amount</p>
                <input
                    type="number"
                    step="0.01"
                    placeholder="Minimal 0.01"
                    className="bg-[#f3f5f7] mb-4 h-14 rounded-[10px] text-gray-900 text-sm focus:ring-1 focus:ring-[#EFB81C] focus:border-[#EFB81C] dark:border-[#EFB81C] focus:outline-none block w-full p-2.5 dark:bg-transparent dark:placeholder-gray-400 dark:text-white dark:focus:!border-[#EFB81C] dark:focus:outline-none"
                    onChange={(e) => setNum(e.target.value)}
                    onFocus={() => setShowErrors(false)}
                />
                <div className="flex justify-end w-full">
                    <ContinueBtn
                        onClick={(e) => {
                            e.preventDefault();
                            // without coin no action
                            if (coin === "") {
                                setShowErrors(true);
                                toast.error("Coin is Required.");
                                return;
                            }

                            if (num === "") {
                                setShowErrors(true);
                                toast.error("Amount is required.");
                                return;
                            }
                            if (num && parseFloat(num) < 0.01) {
                                setShowErrors(true);
                                toast.error("Amount must be at least 0.01.");
                                return;
                            }
                            if (!address && !receiver_id) {
                                setShowErrors(true);
                                toast.error(
                                    "Either email address or receiver ID is required."
                                );
                                return;
                            }

                            //setShowErrors(true);
                            // validateForm();
                            //if (isFormValid) {
                            setShowModal(true);
                            //}
                        }}
                        // disabled={!isFormValid}
                    >
                        Continue
                    </ContinueBtn>
                </div>

                {showG2FT && (
                    <G2faOtpVerification
                        username={email}
                        onClose={() => setShowG2FT(false)}
                        onSuccess={handle2faSuccess}
                    />
                )}

                <Modal
                    title="Fund Transfer"
                    visible={showModal}
                    onCancel={() => setShowModal(false)}
                    footer={null}
                    // centered
                >
                    <div className="flex justify-center">
                        <div
                            className="rounded-lg dark:bg-zinc-900"
                            style={{ maxWidth: "700px", width: "100%" }}
                        >
                            {/* Email Instruction */}
                            <div className="flex items-center gap-2 mb-4 text-gray-500">
                                <IoMailOutline className="text-lg" />
                                <h6 className="text-sm">
                                    A code will be sent to{" "}
                                    <span className="font-semibold text-black dark:text-white">
                                        {email}
                                    </span>
                                </h6>
                            </div>

                            {/* OTP Input */}
                            <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 rounded-md py-2 px-4 mb-4">
                                <input
                                    type="text"
                                    placeholder="Enter OTP"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    className="w-full bg-transparent text-black dark:text-white font-medium focus:outline-none border-none"
                                    aria-label="Enter OTP"
                                />
                                <button
                                    className={`text-yellow-500 font-medium text-sm ${
                                        isButtonDisabled
                                            ? "cursor-not-allowed opacity-50"
                                            : "hover:underline"
                                    } w-64 py-2`}
                                    onClick={handleSendCode}
                                    disabled={isButtonDisabled}
                                    aria-label={
                                        isButtonDisabled
                                            ? `Resend OTP in ${timer} seconds`
                                            : "Send Verification Code"
                                    }
                                >
                                    {isButtonDisabled
                                        ? `Resend (${timer}s)`
                                        : "Send Verification Code"}
                                </button>
                            </div>

                            {/* Pay Password Input */}
                            <div className="mb-4">
                                <div className="flex items-center gap-2 text-gray-500 mb-2">
                                    <TbPasswordFingerprint className="text-lg" />
                                    <p className="text-sm">Pay Password</p>
                                    <a
                                        href="#"
                                        className="ml-auto text-blue-500 hover:underline"
                                    >
                                        {authStatus.paypassword === false && (
                                            <Link
                                                to={{
                                                    pathname:
                                                        "/user/account/security",
                                                    state: {
                                                        gftOTP: false,
                                                    },
                                                }}
                                            >
                                                Fund Password
                                            </Link>
                                        )}
                                    </a>
                                </div>
                                <input
                                    type="password"
                                    value={payPassword}
                                    onChange={(e) =>
                                        setPayPassword(e.target.value)
                                    }
                                    className="w-full bg-gray-100 dark:bg-gray-800 text-black dark:text-white font-medium focus:outline-none border-none rounded-md py-2 px-4"
                                    placeholder="Enter Pay Password"
                                    aria-label="Enter Pay Password"
                                />
                            </div>

                            {/* Submit Button */}
                            <button
                                type="submit"
                                onClick={handleSubmit} // Replace with actual function
                                className="w-full py-2 bg-yellow-500 hover:bg-yellow-600 transition-colors rounded-md text-black font-semibold"
                                aria-label="Proceed to Next Step"
                                disabled={
                                    isLoading ||
                                    authStatus.paypassword === false
                                        ? true
                                        : false
                                }
                            >
                                {isLoading ? "Submitting" : "Next Step"}
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="Transaction Success"
                    visible={showSuccessModal}
                    onCancel={() => setShowSuccessModal(false)}
                    footer={null}
                >
                    <div className="p-4 rounded-xl">
                        <div className="flex justify-center items-center">
                            <IoMdCheckmarkCircle className="text-[80px] text-[#00e297] bg-[#DFF7F0] p-1 rounded-full" />
                        </div>
                        <h2 className="text-lg text-center font-bold mt-2">
                            Recipient Amount
                        </h2>
                        <p className="text-2xl text-center font-bold uppercase">
                            {transactionDetails.currency}
                        </p>
                        <p className="text-center mb-4 mt-1">
                            The Recipient can check his balance in the funding
                            wallet.
                        </p>
                        <div className="bg-[#EFB81C] bg-opacity-20 rounded-md p-3">
                            <p className="font-semibold">
                                Visit the Buycex Pay homepage for more payment
                                services
                            </p>
                            <p className="text-[#EFB81C] font-semibold text-sm">
                                View Now
                            </p>
                        </div>
                        <div className="my-6 flex flex-col gap-2">
                            <div className="flex w-full justify-between text-sm font-medium text-[#707a8a]">
                                <p>Total Paid</p>
                                <p className="uppercase">
                                    {transactionDetails.amount}{" "}
                                    {transactionDetails.currency}
                                </p>
                            </div>
                            <div className="flex w-full justify-between text-sm font-medium text-[#707a8a]">
                                <p>Receiver</p>
                                <p>{transactionDetails.receiver}</p>
                            </div>
                            <div className="flex w-full justify-between text-sm font-medium text-[#707a8a]">
                                <p>Send Mode</p>
                                {/* Correctly display "Email" when payMethod is "email" */}
                                <p>
                                    {transactionDetails.method === "email"
                                        ? "Email Address"
                                        : transactionDetails.method === "payid"
                                        ? "User ID"
                                        : ""}
                                </p>
                            </div>
                            <div className="flex w-full justify-between text-sm font-medium text-[#707a8a]">
                                <p>Time Created</p>
                                <p>{transactionDetails.transactionTime}</p>
                            </div>
                        </div>

                        <button
                            onClick={() => setShowSuccessModal(false)}
                            className="mt-4 flex bg-[#EFB81C] w-full py-2 px-4 rounded-xl items-center justify-center font-semibold "
                        >
                            Ok
                        </button>
                    </div>
                </Modal>
            </form>
        </div>
    );
};

export default InternalTransfer;
