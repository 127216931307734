import React, { useEffect, useState } from "react";
import "./earn.css";
import TopCard from "./TopCard";
import MainTable from "./MainTable";
import SmallRightSideTable from "./SmallRightSideTable";
import StackTable from "./StackTable";
import "../../components/home-page-sections/hero-section/./HeroSection.scss";

const heroImages = [HeroImageOne, HeroImageTwo, HeroImageThird];
import HeroImageOne from "assets/img/Aboutsection4.png";
import HeroImageTwo from "assets/img/algo-orders.png";
import HeroImageThird from "assets/img/Aboutsection3.png";
import { FaCopy } from 'react-icons/fa';
import BcxTokenSection from "./BcxTokenSection";
import MiningCoins from "views/crypto/CryptoMining/MiningCoins";
import { GiMining } from "react-icons/gi";
import { Link } from "react-router-dom/cjs/react-router-dom";
const EarnOverview = () => {
    const [activeTable, setActiveTable] = useState('staking');

    const handleTableSwitch = (table) => {
        setActiveTable(table);
    };
    
    const flippingWords = [
        "Maximize",
        "Elevate",
        "Amplify",
        "Unlock"
      ];
    const [currentWord, setCurrentWord] = useState("Accelerate");
    const [flipState, setFlipState] = useState("flip-enter");
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageTransitionState, setImageTransitionState] = useState("fade-in");
  
    useEffect(() => {
      const wordInterval = setInterval(() => {
          setFlipState("flip-exit");
  
          setTimeout(() => {
              setCurrentWord((prev) => {
                  const nextIndex =
                      (flippingWords.indexOf(prev) + 1) %
                      flippingWords.length;
                  return flippingWords[nextIndex];
              });
              setFlipState("flip-enter");
          }, 600);
      }, 3000);
  
      return () => clearInterval(wordInterval);
  }, []);
  
  useEffect(() => {
      const imageInterval = setInterval(() => {
          setImageTransitionState("fade-out");
  
          setTimeout(() => {
              setCurrentImageIndex((prevIndex) => {
                  return (prevIndex + 1) % heroImages.length;
              });
              setImageTransitionState("fade-in");
          }, 600);
      }, 3000);
  
      return () => clearInterval(imageInterval);
  }, []);
    return (
<>
        <section className="hero-section-black flex relative"> {/* Added mt-12 for top margin */}
        <div className="hero-section__wrapper bg-black text-white py-12 container flex items-center justify-between gap-8 w-full max-h-[80vh] m-auto max-md:flex-col">
          {/* Left Section */}
          <div className="hero-title-wrapper flex flex-col gap-6 w-[60%] mb-16">
            <h1 className="hero-title flex flex-col gap-3 text-[48px] font-[600] leading-tight">
              <span className="text-[52px] font-[900] text-[#EFB81C]"> BuyCex Earn</span>
         <p className="text-[#adb1b8] text-[26px] font-medium">
                                Don't Just HODL — Grow Your Crypto Holdings The
                                Smart Way
                            </p>
                          </h1>
            <p className="hero-description -mt-2"> {/* Negative margin to further reduce gap */}
              <span className={`flip-text ${flipState}`}>{currentWord}</span>{"  "}
              <span> Your Crypto Potential with BuyCex Earn</span>
            </p>
      
            <div className="hero-btn-wrap flex items-center gap-4 mt-2 mb-12">
              <button className="bg-[#EFB81C] text-white py-1 px-4 rounded-md">
              My Earnings
              </button>
              <button className="border border-[#EFB81C] hover:bg-[#EFB81C] text-[#EFB81C] hover:text-white py-1 px-4 rounded-md">
                Vote History 
              </button>
            </div>
          </div>
      
          {/* Right Section: Hero Image */}
      
    <div className="stats-wrapper flex flex-col items-center p-10 rounded-full border-4 border-[#EFB81C] w-[40%] h-full">
  {/* Stat Item 1 */}
  <div className="stat-item text-center flex flex-col justify-center mb-6">
    <p className="text-6xl font-bold text-[#EFB81C]"> $000.0%</p>
    <p className="text-xl text-gray-400">      Total Equity </p>
    <p className="text-base text-gray-500">(Earn)</p>
  </div>

  {/* Circular Separator */}
  <div className="border-b border-gray-600 w-full mb-6"></div>

  {/* Stat Item 2 */}
  <div className="stat-item text-center flex flex-col justify-center">
    <p className="text-6xl font-bold text-[#EFB81C]">16.49%</p>
    <p className="text-xl text-gray-400">  Daily Yields</p>
  </div>
</div>
        </div>
      </section>
      <div className="bg-[#EFB81C] h-9">

      </div>
      
        <div className="bg-[#f5f7fa]">
            <div className="earn-overview">
      

                <div className="container pt-8 pb-8 !px-10">
                    {/* top cards  */}
                    <div className="flex justify-between gap-4 ">
                        {/* first  */}
                        <TopCard newbtn="true" />
                        <TopCard />
                        <TopCard />
                    </div>
                    {/* small arrow link section  */}
                    <div className="flex mt-8 gap-4 justify-between">
                        <Link to='/announcements' className='flex-1'> 
                        <div className="p-6 bg-[#FFF] rounded-[12px] flex items-center justify-between text-[#121214] h-[56px] border-[1px] border-solid border-[#f5f7fa] cursor-pointer">
                            <div className="flex gap-4 items-center">
                                <div>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12 0.379804H12.0004C18.4181 0.379804 23.6206 5.58225 23.6206 11.9998V12.0002C23.6206 18.4178 18.4181 23.6202 12.0004 23.6202H12C5.58232 23.6202 0.379804 18.4178 0.379804 12.0002V11.9998C0.379804 5.58225 5.58232 0.379804 12 0.379804Z"
                                            fill="black"
                                            stroke="black"
                                            stroke-width="0.759609"
                                        ></path>
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M10.0751 1.77362C5.25092 2.67046 1.59827 6.9014 1.59827 11.9855C1.59827 17.0695 5.25092 21.3005 10.0751 22.1973V20.5503C6.14832 19.6783 3.21205 16.1748 3.21205 11.9855C3.21205 7.7962 6.14832 4.2927 10.0751 3.42069V1.77362ZM13.9133 3.42456V1.77685C18.729 2.68058 22.3729 6.90749 22.3729 11.9855C22.3729 17.0635 18.729 21.2904 13.9133 22.1941V20.5464C17.8315 19.668 20.7591 16.1686 20.7591 11.9855C20.7591 7.80236 17.8315 4.303 13.9133 3.42456Z"
                                            fill="white"
                                        ></path>
                                        <path
                                            d="M13.4163 11.6499C14.1824 11.7959 14.7767 12.0775 15.1991 12.4947C15.6214 12.9015 15.8326 13.423 15.8326 14.0592V15.0604C15.8326 15.8218 15.5576 16.4372 15.0075 16.9065C14.4575 17.3654 13.7355 17.5949 12.8416 17.5949H12.5322V19.1906H11.4714V17.5949H11.1472C10.5579 17.5949 10.0373 17.4645 9.58544 17.2037C9.1336 16.9326 8.77999 16.5571 8.5246 16.0773C8.27903 15.5871 8.15625 15.0239 8.15625 14.3877H9.21709C9.21709 15.0344 9.3939 15.5611 9.74752 15.9678C10.111 16.3641 10.5874 16.5623 11.1767 16.5623H12.8122C13.3917 16.5623 13.8583 16.4267 14.2119 16.1556C14.5655 15.874 14.7423 15.5089 14.7423 15.0604V14.0592C14.7423 13.7046 14.6097 13.4021 14.3445 13.1518C14.0891 12.9015 13.7355 12.7398 13.2837 12.6668L10.6316 12.1975C9.88503 12.0619 9.3055 11.7803 8.89295 11.3527C8.4804 10.9251 8.27412 10.3879 8.27412 9.74129V8.89648C8.27412 8.13511 8.53933 7.53019 9.06975 7.08171C9.61 6.62281 10.3221 6.39335 11.2062 6.39335H11.4714V4.79761H12.5322V6.39335H12.8711C13.706 6.39335 14.3789 6.66452 14.8897 7.20687C15.4004 7.73878 15.6558 8.44279 15.6558 9.31888H14.595C14.595 8.74525 14.4378 8.28634 14.1235 7.94216C13.8092 7.59798 13.3917 7.42589 12.8711 7.42589H11.2062C10.6463 7.42589 10.1994 7.56148 9.86539 7.83265C9.53142 8.09339 9.36443 8.448 9.36443 8.89648V9.74129C9.36443 10.1063 9.48722 10.414 9.73278 10.6643C9.98817 10.9146 10.3369 11.0815 10.7789 11.1649L13.4163 11.6499Z"
                                            fill="white"
                                        ></path>
                                    </svg>
                                </div>
                                <div className="text-[14px] text-[#121214] font-medium">
                                    BuyCex Dual Asset: HMSTR Now Supported!
                                </div>
                            </div>
                            <div>
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 1024 1024"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className=" text-[#81858c] w-4 h-4"
                                >
                                    <path
                                        fill="currentColor"
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M353.835 225.835a42.667 42.667 0 0 1 60.33 0l256 256a42.667 42.667 0 0 1 0 60.33l-256 256a42.667 42.667 0 0 1-60.33-60.33L579.669 512 353.835 286.165a42.667 42.667 0 0 1 0-60.33z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        </Link>
                        <Link to='/announcements' className='flex-1'> 
                        <div className=" p-6 bg-[#FFF] rounded-[12px] flex items-center justify-between text-[#121214] h-[56px] border-[1px] border-solid border-[#f5f7fa] cursor-pointer">
                            <div className="flex gap-4 items-center">
                                <div>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12 0.379804H12.0004C18.4181 0.379804 23.6206 5.58225 23.6206 11.9998V12.0002C23.6206 18.4178 18.4181 23.6202 12.0004 23.6202H12C5.58232 23.6202 0.379804 18.4178 0.379804 12.0002V11.9998C0.379804 5.58225 5.58232 0.379804 12 0.379804Z"
                                            fill="black"
                                            stroke="black"
                                            stroke-width="0.759609"
                                        ></path>
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M10.0751 1.77362C5.25092 2.67046 1.59827 6.9014 1.59827 11.9855C1.59827 17.0695 5.25092 21.3005 10.0751 22.1973V20.5503C6.14832 19.6783 3.21205 16.1748 3.21205 11.9855C3.21205 7.7962 6.14832 4.2927 10.0751 3.42069V1.77362ZM13.9133 3.42456V1.77685C18.729 2.68058 22.3729 6.90749 22.3729 11.9855C22.3729 17.0635 18.729 21.2904 13.9133 22.1941V20.5464C17.8315 19.668 20.7591 16.1686 20.7591 11.9855C20.7591 7.80236 17.8315 4.303 13.9133 3.42456Z"
                                            fill="white"
                                        ></path>
                                        <path
                                            d="M13.4163 11.6499C14.1824 11.7959 14.7767 12.0775 15.1991 12.4947C15.6214 12.9015 15.8326 13.423 15.8326 14.0592V15.0604C15.8326 15.8218 15.5576 16.4372 15.0075 16.9065C14.4575 17.3654 13.7355 17.5949 12.8416 17.5949H12.5322V19.1906H11.4714V17.5949H11.1472C10.5579 17.5949 10.0373 17.4645 9.58544 17.2037C9.1336 16.9326 8.77999 16.5571 8.5246 16.0773C8.27903 15.5871 8.15625 15.0239 8.15625 14.3877H9.21709C9.21709 15.0344 9.3939 15.5611 9.74752 15.9678C10.111 16.3641 10.5874 16.5623 11.1767 16.5623H12.8122C13.3917 16.5623 13.8583 16.4267 14.2119 16.1556C14.5655 15.874 14.7423 15.5089 14.7423 15.0604V14.0592C14.7423 13.7046 14.6097 13.4021 14.3445 13.1518C14.0891 12.9015 13.7355 12.7398 13.2837 12.6668L10.6316 12.1975C9.88503 12.0619 9.3055 11.7803 8.89295 11.3527C8.4804 10.9251 8.27412 10.3879 8.27412 9.74129V8.89648C8.27412 8.13511 8.53933 7.53019 9.06975 7.08171C9.61 6.62281 10.3221 6.39335 11.2062 6.39335H11.4714V4.79761H12.5322V6.39335H12.8711C13.706 6.39335 14.3789 6.66452 14.8897 7.20687C15.4004 7.73878 15.6558 8.44279 15.6558 9.31888H14.595C14.595 8.74525 14.4378 8.28634 14.1235 7.94216C13.8092 7.59798 13.3917 7.42589 12.8711 7.42589H11.2062C10.6463 7.42589 10.1994 7.56148 9.86539 7.83265C9.53142 8.09339 9.36443 8.448 9.36443 8.89648V9.74129C9.36443 10.1063 9.48722 10.414 9.73278 10.6643C9.98817 10.9146 10.3369 11.0815 10.7789 11.1649L13.4163 11.6499Z"
                                            fill="white"
                                        ></path>
                                    </svg>
                                </div>
                                <div className="text-[14px] text-[#121214] font-medium">
                                    HODL USDe to Enjoy Up to 20% APR!
                                </div>
                            </div>
                            <div>
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 1024 1024"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className=" text-[#81858c] w-4 h-4"
                                >
                                    <path
                                        fill="currentColor"
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M353.835 225.835a42.667 42.667 0 0 1 60.33 0l256 256a42.667 42.667 0 0 1 0 60.33l-256 256a42.667 42.667 0 0 1-60.33-60.33L579.669 512 353.835 286.165a42.667 42.667 0 0 1 0-60.33z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <BcxTokenSection/>
                    <div className="mt-6 flex gap-4 ">
                        <button onClick={() => handleTableSwitch('staking')} className="bg-[#EFB81C] rounded-[8px] text-[12px] w-[100px] h-[32px] text-center text-[#000] font-medium flex items-center justify-center ">
                            Staking
                        </button>
                        <button onClick={() => handleTableSwitch('stakingLogs')} className="bg-[#EFB81C] rounded-[8px] text-[12px] w-[100px] h-[32px] text-center text-[#000] font-medium flex items-center justify-center ">
                            Staking Logs
                        </button>
                    </div>
                    {/* main table section  */}
                    <div className="mt-6 flex justify-between gap-4 ">
                        <div  className="flex-1">
                        {activeTable === 'staking' ? <MainTable /> : <StackTable />}
                        </div>
                        {/* <div className="w-[300px]">
                            <SmallRightSideTable />
                        </div> */}
                    </div>
                    <div className="bg-[#FFF] rounded-[16px] shadow-lg mt-8 p-4">
                        <div className="flex justify-between w-full py-3">
                                                    <h2 className="text-[#121214] font-semibold text-[24px]">Mining Coins</h2>
                                                    <Link to='/crypto/mining'>
                                                    <button className="bg-[#EFB81C] px-4 rounded-[8px] text-[12px] h-[32px] text-center text-[#000] font-medium flex items-center justify-center ">
                                                        {/* <GiMining/> */}
                                                        Mining Dashboard
                                                    </button>
                                                    </Link>
                                                </div>
                        <MiningCoins/>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default EarnOverview;
