import Repository from "./Repository";
const EndPoint = "/Staking/index";
const createStaking = "/Staking/createStaking"
const Staking = "/Staking/logs"
const EarnStats = "/earn/summarystats"
const userproduct = "/Earn/userProductList"
const accountsummary = "/earn/accountsummary"
const productlist = "/earn/productlist"
const accountSummaryStats = "/Earn/accountSummary"
const saveuserproduct = "/earn/saveuserproduct"
const Toggle = "/Earn/toggleAutoDesuct"
const Redeem = "/Earn/redeemUserProduct"

export default {
    // Function for getting Assets data
    earnStaking(userId, token) {
        return Repository.get(`${EndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    saveuserproduct(userid, token,payload) {
        return Repository.post(`${saveuserproduct}`, payload,{
            headers: {
                "Content-Type": "multipart/form-data",
                token: token,
                id: userid,
            },
        });
    },
    Toggle(userid, token,payload) {
        return Repository.post(`${Toggle}`, payload,{
            headers: {
                "Content-Type": "multipart/form-data",
                token: token,
                id: userid,
            },
        });
    },
    Redeem(userid, token,payload) {
        return Repository.post(`${Redeem}`, payload,{
            headers: {
                "Content-Type": "multipart/form-data",
                token: token,
                id: userid,
            },
        });
    },
    productlist(userId, token) {
        return Repository.get(`${productlist}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    accountSummaryStats(userId, token) {
        return Repository.get(`${accountSummaryStats}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    fetchEarnStats(userId, token) {
        return Repository.get(`${EarnStats}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    fetchuserproduct(userId, token) {
        return Repository.get(`${userproduct}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    accountsummary(userId, token) {
        return Repository.get(`${accountsummary}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    Staking(userId, token) {
        return Repository.get(`${Staking}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    createStaking(userid, token,payload) {
        return Repository.post(`${createStaking}`, payload,{
            headers: {
                "Content-Type": "multipart/form-data",
                token: token,
                id: userid,
            },
        });
    },
};
