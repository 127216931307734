import {
    FETCH_USER_PAY_ID_REQUEST,
    FETCH_USER_PAY_ID_SUCCESS,
    FETCH_USER_PAY_ID_FAILURE,
  } from "../types";
  
  const initialState = {
    loading: false,
    data: null,
    error: null,
  };
  
  const userPayIdReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USER_PAY_ID_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_USER_PAY_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case FETCH_USER_PAY_ID_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default userPayIdReducer;
  