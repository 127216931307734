import React, { useEffect, useState } from 'react';
import { BsRocketTakeoff } from 'react-icons/bs';
import { FaChevronRight } from 'react-icons/fa';
import MiningCoins from './MiningCoins';
import Pool from './Pool';
import { GiMining } from 'react-icons/gi';

function CryptoMining() {
    const flippingWords = [
        "Maximize",
        "Elevate",
        "Amplify",
        "Unlock"
    ];
    const [currentWord, setCurrentWord] = useState("Accelerate");
    const [flipState, setFlipState] = useState("flip-enter");
    useEffect(() => {
        const wordInterval = setInterval(() => {
            setFlipState("flip-exit");

            setTimeout(() => {
                setCurrentWord((prev) => {
                    const nextIndex =
                        (flippingWords.indexOf(prev) + 1) %
                        flippingWords.length;
                    return flippingWords[nextIndex];
                });
                setFlipState("flip-enter");
            }, 600);
        }, 3000);

        return () => clearInterval(wordInterval);
    }, []);
    return (
        <div>
            <section className='hero-section-black bg-black h-96'>
                <div className='hero-section__wrapper container flex-col flex gap-3 py-5'>
                    <div className='flex gap-2 font-semibold py-3'>
                        <p className='text-3xl text-[#EFB81C]'>Buycex</p><p className='text-3xl text-white'>Mining</p>
                    </div>
                    <p className='uppercase text-xl font-semibold text-white'>Supports new merged mining</p>
                    <div className='flex w-[70%] justify-between items-center'>
                    <h1 className='uppercase text-4xl font-bold text-white'>Pepecoin & junkcoin!</h1>
                    <button className='font-semibold text-2xl border-2 rounded-full border-[#EFB81C] text-[#EFB81C] px-4 py-2'>
                    Start Mining Now!
                </button>
                    </div>
                    <p className='text-2xl text-[#81858c] font-semibold'>Mine LTC and Earn Rewards in LTC, DODGE, BELLS, LKY, JKC & PEP</p>
                    <p className="hero-description -mt-2 font-semibold">
                        <span className={`flip-text ${flipState}`}>{currentWord}</span>{"  "}
                        <span className='text-white'> Your Crypto Potential with BuyCex Mining</span>
                    </p>
                </div>
            </section>
            <div className='flex gap-12 py-8 mb-16 container'>
                <div className='border p-6 flex h-28 rounded-xl flex-1'>
                    <div className='flex items-center justify-between w-full'>
                        <div className='flex gap-10 items-center'>
                            <BsRocketTakeoff fill='#EFB81C' size={40} />
                            <div className='flex flex-col h-14 justify-between'>
                                <p className='font-semibold'>BTC Transaction Accelerator</p>
                                <p className='text-[#81858c] text-sm'>Accelerate your transaction now</p>
                            </div>
                        </div>
                        <FaChevronRight />
                    </div>

                </div>
                <div className='border p-6 flex h-28 rounded-xl flex-1'>
                    <div className='flex items-center justify-between w-full'>
                        <div className='flex gap-10 items-center'>
                            <BsRocketTakeoff fill='#EFB81C' size={40} />
                            <div className='flex flex-col h-14 justify-between'>
                                <p className='font-semibold'>Ordinals Inscribe</p>
                                <p className='text-[#81858c] text-sm'>Inscribe anything you want on bitcoin</p>
                            </div>
                        </div>
                        <FaChevronRight />
                    </div>

                </div>
            </div>
            <div className='container'>
                <div className="flex justify-between w-full py-3">
                    <p className="text-3xl font-bold">Mining Coins</p>
                    <button className="flex gap-2 items-center px-4 rounded-md bg-[#EFB81C] text-sm font-medium">
                        <GiMining />
                        Mining Dashboard
                    </button>
                </div>
                <MiningCoins />
                <Pool />
            </div>
        </div>
    );
}

export default CryptoMining;
