import { RepositoryFactory } from "repository/RepositoryFactory";
import {
    FETCH_BCX_FINANCE_HISTORY_REQUEST,
    FETCH_BCX_FINANCE_HISTORY_SUCCESS,
    FETCH_BCX_FINANCE_HISTORY_FAILURE,
    GET_CONVERT_BCX_REQUEST,
    GET_CONVERT_BCX_SUCCESS,
    GET_CONVERT_BCX_FAILURE,
} from "../types";
import toast from "react-hot-toast";

const bcxRepository = RepositoryFactory.get("bcx");
export const getBCXFinanceHistory = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_BCX_FINANCE_HISTORY_REQUEST });
    try {
        const response = await bcxRepository.records(userId, token);
        const bcxFinanceHistory = response.data.data;
        if (response.data.status === 1) {
            dispatch({
                type: FETCH_BCX_FINANCE_HISTORY_SUCCESS,
                payload: bcxFinanceHistory,
            });
        } else {
            dispatch({
                type: FETCH_BCX_FINANCE_HISTORY_FAILURE,
                payload: bcxFinanceHistory,
            });
        }
    } catch (error) {
        dispatch({
            type: FETCH_BCX_FINANCE_HISTORY_FAILURE,
            payload: error.message,
        });
    }
};
export const getConvertToBCX =
    (userId, token, param, onCancel) => async (dispatch) => {
        dispatch({ type: GET_CONVERT_BCX_REQUEST });
        try {
            const response = await bcxRepository.convertToBcx(
                userId,
                token,
                param
            );
            const converyedBcx = response.data.data;
            if (response.data.status === 1) {
                toast.success("Convert to BCX successfully");
                onCancel();
                dispatch({
                    type: GET_CONVERT_BCX_SUCCESS,
                    payload: converyedBcx,
                });
            } else {
                toast.error(response.data.data);
                dispatch({
                    type: GET_CONVERT_BCX_FAILURE,
                    payload: converyedBcx,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_CONVERT_BCX_FAILURE,
                payload: error.message,
            });
        }
    };
