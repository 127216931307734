import React, { useState } from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import CurrentOrders from './CurrentOrders'
import OrderHistory from './OrderHistory'

function MyOrder() {
    const [activeComponent, setActiveComponent] = useState('Current')
    return (
        <div className='container'>
            <div className='flex gap-1 items-center py-3'>
                <p className='text-gray-400 text-sm'>Pre-List</p>
                <MdKeyboardArrowRight size={14} />
                <p className='text-sm'>Orders</p>
            </div>

            <button className={`text-sm h-10 mr-7 ${activeComponent === 'Current' ? 'text-black border-b-2 border-black' : 'text-gray-400'}`} onClick={() => setActiveComponent('Current')}>
                Current Orders
            </button>
            <button className={`text-sm h-10 ${activeComponent === 'OrderHistory' ? 'text-black border-b-2 border-black' : 'text-gray-400'}`} onClick={() => setActiveComponent('OrderHistory')}>
                Order History
            </button>
            {activeComponent === 'Current' && <CurrentOrders />}
            {activeComponent === 'OrderHistory' && <OrderHistory />}
        </div>
    )
}

export default MyOrder