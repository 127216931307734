import React, { useEffect, useState } from "react";
import UserInfo from "./UserInfo";
import EstimatedBalance from "./EstimatedBalance";
import MainTable from "./MainTable/MainTable";
import { fetchAuthStatus } from "store/actions/userSecuritySettingsAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
    fetchUserDashboard,
    fetchUserProfile,
} from "store/actions/userProfileAction";
function Dashboard() {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;

    const verified = useSelector(
        (state) => state.authCheck.authStatus.idcardauth
    );

    const { userDashboard } = useSelector((state) => state.userDashboard);

    const handleAuthCheck = () => {
        if (!userId || !token) {
            history.push("/login");
        } else {
            dispatch(fetchAuthStatus(userId, token));
        }
    };

    useEffect(() => {
        handleAuthCheck();
        
    }, [userId, token, history, dispatch]);

    useEffect(() => {
        dispatch(fetchUserProfile(userId, token));
        dispatch(fetchUserDashboard(userId, token));
    }, [dispatch, userId, token, history]);

    return (
        <div className="flex px-0 lg:px-3">
            <div className="flex flex-col w-full !p-0 max-w-[1400px] mx-auto">
                <UserInfo />
                <EstimatedBalance data={userDashboard} />
                <MainTable />
            </div>
        </div>
    );
}

export default Dashboard;
