import {
    FETCH_LATEST_PROFIT_REQUEST,
    FETCH_LATEST_PROFIT_SUCCESS,
    FETCH_LATEST_PROFIT_FAILURE,
} from "../types";

const initialState = {
    loading: false,
    latestProfit: null,
    error: null,
};

const latestProfitReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LATEST_PROFIT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_LATEST_PROFIT_SUCCESS:
            return {
                ...state,
                loading: false,
                latestProfit: action.payload,
            };
        case FETCH_LATEST_PROFIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default latestProfitReducer;
