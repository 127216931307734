import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import SingleRow from "./SIngleRow";
import { useDispatch, useSelector } from "react-redux";
import { productlist } from "store/actions/EarnStaking";

const DPSTable = () => {
    const [data, setData] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(productlist(userID, userToken));
                const fetchedData = response?.data?.data || [];
                setData(fetchedData);
            } catch (error) {
                console.error("Error fetching staking data:", error.message);
            }
        };

        if (userID && userToken) {
            fetchData();
        }
    }, [dispatch, userID, userToken]);

    const filteredData = searchQuery
        ? data.filter((item) =>
              item.coin?.toLowerCase().includes(searchQuery.toLowerCase())
          )
        : data;

    const itemsPerPage = 10;
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filteredData.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredData.length;
        setItemOffset(newOffset);
    };

    return (
        <div
            style={{ boxShadow: "0 4px 24px rgba(0,0,0,.06)" }}
            className="bg-[#fff] rounded-[16px] p-4 earn-overview"
        >
            <div className="flex justify-between">
                <div>
                    <h2 className="text-[#121214] font-semibold text-[24px]">
                        All Products
                    </h2>
                </div>
                <div className="w-[180px] bg-[#f3f5f7] rounded-[100px] h-[32px] text-[#56565b] text-[12px] flex items-center gap-1">
                    <div className="flex items-center justify-center px-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 16 16"
                            className="products_searchIcon__bsyqk"
                        >
                            <path
                                fill="#81858C"
                                fillRule="evenodd"
                                d="M2 7.25a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0ZM7.25.5a6.75 6.75 0 1 0 4.213 12.024l2.757 2.756a.75.75 0 1 0 1.06-1.06l-2.756-2.757A6.75 6.75 0 0 0 7.25.5Z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </div>
                    <input
                        type="text"
                        className="bg-transparent"
                        placeholder="Search coin name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>
            <div className="p-3 mt-2 flex justify-between gap-4 border-b-[1px] border-solid border-[#f3f5f7]">
                <div className="text-[#81858c] text-[12px] flex-1">Coins</div>
                <div className="text-[#81858c] text-[12px] flex-1">Monthly</div>
                <div className="text-[#81858c] text-[12px] flex-1">Weekly</div>
                <div className="text-[#81858c] text-[12px] flex-1">
                    Duration
                </div>
                <div className="text-[#81858c] text-[12px] flex-1">Amount</div>
                <div className="text-[#81858c] text-[12px] flex-1 text-right">
                    Action
                </div>
            </div>
            <SingleRow currentItems={currentItems} />
            <ReactPaginate
                breakLabel="..."
                nextLabel=" >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                className="flex gap-2 mt-4 items-center justify-center"
                pageClassName="items"
                previousClassName="items"
                nextClassName="items"
                activeClassName="items active"
            />
        </div>
    );
};

export default DPSTable;
