import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import WithdrawalRoutingNavbar from "components/withdrawalRoutingNavbar";
import FaQs from "components/FAQs";
import { NavLink } from "react-router-dom";
import withdrawKey from "assets/img/Withdraw-key.png";
import { AiOutlineClose } from "react-icons/ai";
import { ImNotification } from "react-icons/im";
import InternalTransfer from "components/cryptoWithdraw/InternalTransfer";
import ContinueBtn from "components/cryptoWithdraw/ContinueBtn";
import CoinSelect from "components/cryptoWithdraw/CoinSelectWithdraw";
import NetworkSelectDropdown from "components/cryptoWithdraw/NetworkSelectWithdraw";
import { FaCheck, FaArrowRight } from "react-icons/fa";
import toast from "react-hot-toast";
import { upCryptoWithdrawal } from "store/actions/WithdrawAction";
import CryptoWithdrawalRecord from "./cryptowithdrawalRecord";
import CryptoEmailVerificationModal from "./cryptoEmailVerificationModal";
import FAQSection from "./cryptoFaqs";
import { fetchAssetsData } from "store/actions/AssetsUserAction";
import TransactionDetailsModal from "./TransactionDetailsModal";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { CgSoftwareDownload } from "react-icons/cg";
import CryptoRecentTable from "./CryptoRecentTable";
function CryptoWithdraw() {
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    const [coins, setCoins] = useState([]);
    const [selectedCoin, setSelectedCoin] = useState(null);
    const [networks, setNetworks] = useState([]);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [withdrawalRecords, setWithdrawalRecords] = useState([]);
    const [withdrawAddress, setWithdrawAddress] = useState("");
    const [withdrawAmount, setWithdrawAmount] = useState("");
    const [fundPassword, setFundPassword] = useState("");
    const [g2faOtp, setG2faOtp] = useState("");
    const [otp, setOtp] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [balance, setBalance] = useState("");
    const [limit, setLimit] = useState("");
    const [feesPercent, setFeesPercent] = useState("");
    const [feesFlat, setFeesFlat] = useState("");
    const history = useHistory();
    const [showOnChainWithdrawal, setShowOnChainWithdrawal] = useState(true);
    const [isEmailVerificationModalVisible, setIsEmailVerificationModalVisible] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const [withdrawResponse, setWithdrawResponse] = useState("");
    const dispatch = useDispatch();
    const { assetsData, loading: assetsLoading } = useSelector((state) => state.assetsData || {});
  
    const handleEmailVerificationSuccess = () => {
        setIsEmailVerificationModalVisible(false);


    };

    const handleEmailVerificationClose = () => {
        setIsEmailVerificationModalVisible(false);
    };
    const handleOnChainWithdrawalClick = () => {
        setShowOnChainWithdrawal(true);
    };
    useEffect(() => {
        if (!ID || !TOKEN) {
            history.push('/withdraw');
            return;
        }
        dispatch(fetchAssetsData(ID, TOKEN, "spot"));
    }, [dispatch, ID, TOKEN, history]);
    useEffect(() => {
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
            return;
        }
        if (assetsData?.cryptoList) {
            const coinsData = [];

            // Process cryptoList
            Object.keys(assetsData.cryptoList).forEach((coinKey) => {
                const coin = assetsData.cryptoList[coinKey];



                coinsData.push({
                    value: coinKey.toUpperCase(),
                    label: coinKey.toUpperCase(),
                    icon: `${coin.img}`,

                });
            });

            // Set the coins data to state
            setCoins(coinsData);
        }
    }, [assetsData]);
    useEffect(() => {
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
            return;
        }
    
        if (selectedCoin) {
            fetch(
                `https://api.buycex.com/Wallet/cryptodeposit?coin=${selectedCoin.value.toLowerCase()}`,
                {
                    headers: {
                        TOKEN: TOKEN,
                        ID: ID,
                    },
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data && data.data) {
                        // Extract the network data
                        const networks = Object.keys(data.data.network).map((key) => {
                            const coin = data.data.network[key];
                            return { value: coin.name, label: coin.title };
                        });
    
                        // Update the networks state
                        setNetworks(networks);
                    } else {
                        console.log("No data available in response");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [TOKEN, ID, selectedCoin, history]);
    
    useEffect(() => {
        if (!TOKEN || !ID) {
            history.push("/login");
            return;
        }

        if (selectedCoin && TOKEN && ID) {
            fetch(
                `https://api.buycex.com/Crypto/coinbalance/coin/${selectedCoin.value}`,
                {
                    headers: {
                        TOKEN: TOKEN,
                        ID: ID,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {

                    console.log("Data for api ", data)
                    if (data && data.data) {
                       
                        setWithdrawAmount("");
                        setWithdrawAddress("");
                        setBalance(data.data.total);
                        setLimit(data.data.zhehe);
                        setFeesPercent(data.data.fees_percent);
                        setFeesFlat(data.data.fees_flat);

                    } else {
                        console.log("No data available");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [selectedCoin, TOKEN, ID, history]);

    const handleImageClick = (coin) => {
        setSelectedNetwork(null);
        setSelectedCoin(coin);
    };

    const handleCoinChange = (selectedOption) => {
        setSelectedCoin(selectedOption);
    };

    const handleNetworkChange = (selectedOption) => {
        setSelectedNetwork(selectedOption);
    };

    // Check if both coin network and withdrawal address are selected before continuing
    const handleButtonClick = () => {
        if (!selectedCoin) {
            toast.error("Please select a Coin");
            return;
        }
        if (!selectedNetwork) {
            toast.error("Please select a network.");
            return;
        }

        if (!withdrawAddress) {
            toast.error("Please provide a withdrawal address.");
            return;
        }
        if (!withdrawAmount) {
            toast.error("Please provide a withdrawal amount.");
            return;
        }
        if (!withdrawAmount || isNaN(withdrawAmount) || withdrawAmount <= 0) {
            toast.error("Enter a valid amount for transfer.");
            return;
        }
        if (selectedCoin.value === "LTC" && !withdrawAddress.startsWith("L")) {
            toast.error("Invalid withdrawal address format for LTC.");
            return;
        }

        if (selectedCoin.value === "TRX") {
            if (!withdrawAddress.startsWith("TP")) {
                toast.error("Invalid withdrawal address format for TRX.");
                return;
            }

            if (withdrawAddress.length !== 34) {
                toast.error("Withdrawal address for TRX must be 34 characters long.");
                return;
            }
        }

        if (selectedCoin.value === "XRP") {
            if (!withdrawAddress.startsWith("r")) {
                toast.error("Invalid withdrawal address format for XRP.");
                return;
            }

            if (withdrawAddress.length !== 34) {
                toast.error("Withdrawal address for XRP must be 34 characters long.");
                return;
            }

            if (!destinationTag) {
                toast.error("Provide a destination tag for XRP.");
                return;
            }
        }

        if (selectedCoin.value === "TUSDT") {
            if (!withdrawAddress.startsWith("TP")) {
                toast.error("Invalid withdrawal address format for TUSDT.");
                return;
            }

            if (withdrawAddress.length !== 34) {
                toast.error("Withdrawal address for TUSDT must be 34 characters long.");
                return;
            }
        }


        setIsEmailVerificationModalVisible(true);

    };

    const handleWithdraw = async () => {

        if (!withdrawAmount || isNaN(withdrawAmount) || withdrawAmount <= 0) {
            toast.error("Enter a valid amount for transfer.");
            return;
        }


        if (!otp) {
            toast.error("Enter the OTP you received via email.");
            return;
        }


        if (!withdrawAddress) {
            toast.error("Please provide a withdrawal address.");
            return;
        }

        if (selectedCoin.value === "LTC" && !withdrawAddress.startsWith("L")) {
            toast.error("Invalid withdrawal address format for LTC.");
            return;
        }

        if (selectedCoin.value === "TRX") {
            if (!withdrawAddress.startsWith("TP")) {
                toast.error("Invalid withdrawal address format for TRX.");
                return;
            }

            if (withdrawAddress.length !== 34) {
                toast.error("Withdrawal address for TRX must be 34 characters long.");
                return;
            }
        }

        if (selectedCoin.value === "XRP") {
            if (!withdrawAddress.startsWith("r")) {
                toast.error("Invalid withdrawal address format for XRP.");
                return;
            }

            if (withdrawAddress.length !== 34) {
                toast.error("Withdrawal address for XRP must be 34 characters long.");
                return;
            }

            if (!destinationTag) {
                toast.error("Provide a destination tag for XRP.");
                return;
            }
        }

        if (selectedCoin.value === "TUSDT") {
            if (!withdrawAddress.startsWith("TP")) {
                toast.error("Invalid withdrawal address format for TUSDT.");
                return;
            }

            if (withdrawAddress.length !== 34) {
                toast.error("Withdrawal address for TUSDT must be 34 characters long.");
                return;
            }
        }


        if (!fundPassword) {
            toast.error("Provide your transaction password.");
            return;
        }


        if (!selectedCoin || !selectedCoin.value) {
            toast.error("Select a currency for withdrawal.");
            return;
        }


        toast.loading("Processing withdrawal...");
        try {
            const cryptoWithdrawalInputs = {
                coin: selectedCoin.value.toLowerCase(),
                network: selectedCoin.value.toLowerCase(),
                num: withdrawAmount,
                paypassword: fundPassword,
                cellphone_verify: otp, 
                addr: withdrawAddress,
                dest_tag: "ww" | "",
                gacode: g2faOtp,
            };

            console.log("Crypto Withdrawal Inputs:", cryptoWithdrawalInputs);
            const response = await dispatch(
                upCryptoWithdrawal(TOKEN, ID, cryptoWithdrawalInputs)
            );

            console.log("Crypto Withdrawal response:", response);
            toast.dismiss();
            if (response?.data?.status === 1) {
                toast.success(response.data.info || "Crypto withdrawal processed successfully.");
                setWithdrawResponse(response?.data?.data);
                setModalVisible(true);
                setWithdrawAddress("");
                setWithdrawAmount("");
                setG2faOtp("");
                setOtp("");
                setFundPassword("");
            } else {
                toast.error(`Error: ${response?.data?.info || "Something went wrong."}`);
            }
        } catch (error) {
            toast.dismiss(); // Dismiss loading
            console.error("Error:", error);
            toast.error("An error occurred while processing the withdrawal.");
        }
    };
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate API call
        setTimeout(() => {
            setLoading(false);
        }, 800); // simulate 2 seconds loading time
    }, []);

    return (
        <>
            <section className="dark:bg-black">
                <div className="mt-6">
                    <WithdrawalRoutingNavbar />
                </div>
                <div className="gap-20 container w-100">
                    <div className="flex mt-4 justify-between w-full">
                        <div className="flex justify-between w-full mt-[10 px] md:mt-5">
                            <div className="flex justify-between w-[53%]">
                                <b className="font-semibold text-[22px] md:text-3xl">
                                    Withdraw
                                </b>
                                <Link
                                to="/crypto/internal-pay">
                                <p className="mt-3 self-start text-xs bg-[#22c55e] bg-opacity-10 text-[#22c55e] font-medium px-2 py-1 rounded-md">Send By Id/Email</p>
                                </Link>
                            </div>
                            <Link
                                to="/crypto/deposit"
                                className="flex justify-end"
                            >
                                <button className="self-end rounded-md font-semibold mb-4 bg-[#EFB81C] text-white py-2 flex items-center gap-1 px-4">
                                    Deposit
                                    <FaArrowRight />
                                </button>
                            </Link>
                        </div>
                    </div>

                    <section className="flex flex-col w-full gap-10 lg:flex-row">
                        <section className="w-full flex lg:w-1/2 lg:min-w-[53%]">
                            {/* {message && (
                                <div className="mt-4 text-lg text-center text-red-500">
                                    {message}
                                </div>
                            )} */}

                            <div className="flex border rounded-[20px] pt-5 w-full pb-8 dark:bg-[#1A191B] self-start">
                                <div className="flex flex-col w-[10%] mt-1 items-center gap-8">
                                    <CgSoftwareDownload size={28} />
                                    <div
                                        className="w-[10%]"
                                    // style={{ width: "8%" }}
                                    >
                                        <div className="flex flex-col items-center">
                                            {/* First Icon with Kite Shape Background */}
                                            <div
                                                className={`relative flex items-center justify-center w-6 h-6 ${selectedCoin
                                                    ? "bg-black"
                                                    : "bg-black"
                                                    } text-white`}
                                                style={{
                                                    clipPath:
                                                        "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
                                                }}
                                            >
                                                <span className="absolute transform text-md font-semibold">
                                                    {selectedCoin ? (
                                                        <FaCheck />
                                                    ) : (
                                                        "1"
                                                    )}
                                                </span>
                                            </div>

                                            {/* Vertical Line */}
                                            <div
                                                className={`w-0.5 h-[105px] ${selectedCoin
                                                    ? "bg-black"
                                                    : "bg-gray-100"
                                                    }`}
                                            ></div>

                                            {/* Second Icon with Kite Shape Background */}
                                            <div className="flex items-center justify-center w-6 h-6">
                                                <div
                                                    className={`relative flex items-center justify-center w-6 h-6 ${selectedCoin
                                                        ? "bg-black"
                                                        : "bg-gray-100"
                                                        } text-white`}
                                                    style={{
                                                        clipPath:
                                                            "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
                                                    }}
                                                >
                                                    <span className="absolute transform text-md font-semibold">
                                                        {withdrawAddress ? (
                                                            <FaCheck />
                                                        ) : (
                                                            <span
                                                                className={`${selectedCoin
                                                                    ? "text-white"
                                                                    : "text-gray-400"
                                                                    }`}
                                                            >
                                                                2
                                                            </span>
                                                        )}
                                                    </span>
                                                </div>
                                            </div>

                                            {/* Vertical Line */}
                                            <div
                                                className={`w-0.5 ${selectedCoin ? 'h-[143px]' : 'h-[60px]'}  ${withdrawAddress
                                                    ? "bg-black"
                                                    : "bg-gray-100"
                                                    }`}
                                            ></div>

                                            {/* Third Icon with Kite Shape Background */}
                                            <div className="flex items-center justify-center w-6 h-6">
                                                <div
                                                    className={`relative flex items-center justify-center w-6 h-6 ${withdrawAddress
                                                        ? "bg-black"
                                                        : "bg-gray-100"
                                                        } text-white`}
                                                    style={{
                                                        clipPath:
                                                            "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
                                                    }}
                                                >
                                                    <span className="absolute transform text-md font-semibold">
                                                        {withdrawAmount ? (
                                                            <FaCheck />
                                                        ) : (
                                                            <span
                                                                className={`${withdrawAddress
                                                                    ? "text-white"
                                                                    : "!text-gray-400"
                                                                    }`}
                                                            >
                                                                3
                                                            </span>
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="w-5/6 px-3">
                                    <div className="flex flex-col lg:flex-row w-full gap-6 justify-between">
                                        <div className="w-full">
                                            <CoinSelect
                                                coins={coins}
                                                selectedCrypto={selectedCoin}
                                                setSelectedCrypto={setSelectedCoin}
                                                handleImageClick={handleImageClick}
                                            />
                                        </div>
                                    </div>
                                    <form>

                                        <div className="flex flex-col lg:flex-row w-full mt-5 gap-6 justify-between">
                                            <div
                                                className="flex gap-5 w-full text-base font-medium"
                                                style={{ height: "2.75rem" }}
                                            >
                                                <label className="block text-[20px] font-medium text-gray-900 dark:text-white mt-[1rem]">
                                                    <label
                                                        className={`${selectedCoin
                                                            ? "text-gray-900"
                                                            : "text-gray-400"
                                                            }`}
                                                    >
                                                        Enter Address
                                                    </label>
                                                </label>
                                            </div>
                                        </div>

                                        {showOnChainWithdrawal && (
                                            <>
                                                <div className="flex flex-col lg:flex-row w-full mt-4 gap-6 justify-between">
                                                    {selectedCoin && (
                                                        <div className="w-full mb-6">
                                                            <input
                                                                type="text"
                                                                className={`flex items-center bg-[#f3f5f7] text-sm w-full justify-between ${selectedCoin ? 'border border-black placeholder:text-[#81858c] hover:ring-1 ring-[#EFB81C] hover:border-[#EFB81C] font-semibold' : 'border'} rounded-lg px-4 py-4 cursor-pointer bg-white dark:bg-gray-800 dark:text-white`}
                                                                placeholder="Withdrawal address"
                                                                value={withdrawAddress}
                                                                onChange={(e) =>
                                                                    setWithdrawAddress(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <NetworkSelectDropdown
                                                    networks={networks}
                                                    selectedCoin={selectedCoin}
                                                    selectedNetwork={selectedNetwork}
                                                    setSelectedNetwork={setSelectedNetwork}
                                                />
                                                <div className="flex flex-col lg:flex-row w-full gap-6 justify-between">
                                                    <div className="w-full">
                                                        <label className="block mb-4 text-[20px] font-medium text-gray-900 dark:text-white mt-[1rem]">
                                                            <label
                                                                className={`${selectedNetwork
                                                                    ? "text-gray-900"
                                                                    : "text-gray-400"
                                                                    }`}
                                                            >
                                                                Withdrawable Amount
                                                            </label>
                                                        </label>
                                                        {selectedNetwork && (
                                                            <input
                                                                type="number"
                                                                className={`flex items-center bg-[#f3f5f7] text-sm w-full justify-between ${selectedNetwork
                                                                        ? 'border border-black placeholder:text-[#81858c] hover:ring-1 ring-[#EFB81C] hover:border-[#EFB81C]'
                                                                        : 'border'
                                                                    } rounded-lg px-4 py-4 cursor-pointer bg-white dark:bg-gray-800 dark:text-white`}
                                                                placeholder="Withdrawable amount"
                                                                value={withdrawAmount}
                                                                onChange={(e) => setWithdrawAmount(e.target.value)}
                                                                required
                                                            />

                                                        )}
                                                    </div>
                                                </div>
                                                {selectedNetwork && (
                                                    <div className="flex justify-between w-full mt-2">

                                                        <p className="text-sm text-[#81858c]">Available Withdraw</p>
                                                        <p className="text-sm">{balance} {selectedCoin.value} </p>
                                                    </div>
                                                )}
                                                {/* {selectedCoin && (
                                                    <div className="mt-6 w-full">
                                                        <label className="text-sm font-medium text-[#81858c] mb-2 block">
                                                            Selected Coin Details
                                                        </label>
                                                        <div className="bg-[#FFF7D6] p-5 rounded-[10px] border border-[#EFB81C] shadow-md">
                                                            <div className="text-lg font-semibold text-gray-800 mb-3">
                                                                Balance ({selectedCoin.value}): {balance}
                                                            </div>
                                                            <div className="text-base font-medium text-gray-600">
                                                                Fees ({selectedCoin.value}): {feesFlat}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )} */}


                                                <div className="flex justify-between w-full mt-6">
                                                    {selectedNetwork && (
                                                        <div>
                                                            <p className="text-sm text-[#81858c]">Receive amount</p>
                                                            <p className="text-lg font-bold">{withdrawAmount || 0} {selectedCoin.value}</p>
                                                            <p className="text-sm text-[#81858c]">Network Fee {feesFlat} {selectedCoin.value}</p>
                                                        </div>
                                                    )}
                                                    {withdrawAmount && (
                                                        <ContinueBtn
                                                            onClick={handleButtonClick}
                                                        >
                                                            Continue
                                                        </ContinueBtn>
                                                    )}

                                                </div>

                                                <CryptoEmailVerificationModal
                                                    isVisible={isEmailVerificationModalVisible}
                                                    onClose={handleEmailVerificationClose}
                                                    onSuccess={handleEmailVerificationSuccess}
                                                    otp={otp}
                                                    setOtp={setOtp}
                                                    g2faOtp={g2faOtp}
                                                    setG2faOtp={setG2faOtp}
                                                    fundPassword={fundPassword}
                                                    setFundPassword={setFundPassword}
                                                    handleWithdraw={handleWithdraw}
                                                />

                                                <TransactionDetailsModal
                                                    isVisible={isModalVisible}
                                                    onClose={() => {
                                                        setModalVisible(false);
                                                        setWithdrawResponse("");
                                                    }}
                                                    withdrawResponse={withdrawResponse}

                                                />

                                            </>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </section>
                        {/* <FAQSection /> */}
                        <CryptoRecentTable selectedCoin={selectedCoin} TOKEN={TOKEN} ID={ID} />
                    </section>
                </div>
                {/* <CryptoWithdrawalRecord selectedCoin={selectedCoin} TOKEN={TOKEN} ID={ID} /> */}

                <div className="container">
                    <FaQs />
                </div>
            </section>
        </>
    );
}

export default CryptoWithdraw;