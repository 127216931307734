import React, { useState } from "react";
import QRCode from "qrcode.react";
import Modal from "react-modal";
import { TiTick } from "react-icons/ti";
import { toast } from "react-hot-toast";
import { IoCopySharp, IoInformationCircleSharp } from "react-icons/io5";

// Set the app element for accessibility
Modal.setAppElement("#root");

const DepositAddress = ({
    selectedNetwork,
    selectedCrypto,
    withdrawalAddress,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(withdrawalAddress);
        setIsCopied(true);
        toast.success("Deposit Address copied successfully!");
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };
    const [darkMode, setDarkMode] = useState(false);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    return (
        <>
            {selectedNetwork && selectedCrypto && (
                <div>
                    <div className="flex items-center space-x-2 ">
                        <div className="border w-full border-gray-300 text-gray-900 text-sm rounded-lg p-6 dark:bg-transparent dark:text-white dark:border-gray-600">
                            <div className="flex justify-between items-center">
                            <div className="flex gap-8 items-center">
                            <QRCode value={withdrawalAddress} size={100} />
                                <div className="flex flex-col gap-2">
                                    <span className="text-sm font-medium text-gray-600">Address</span>
                                    <span className="truncate font-medium text-gray-800 text-[11px] sm:text-sm">
                                    {withdrawalAddress}
                                </span>
                                </div>
                            </div>
                                <div className="flex w-12 gap-3">
                                    {!isCopied ? (
                                        <IoCopySharp
                                            size={20}
                                            className="text-gray-500 hover:text-gray-700 cursor-pointer"
                                            onClick={copyToClipboard}
                                        />
                                    ) : (
                                        <TiTick className="text-green-500" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-2 bg-transparent dark:bg-black">
                        <div className="flex justify-between text-xs sm:text-sm mt-3">
                            <div><p className="text-[11px] sm:text-sm text-gray-500">Minimun Deposit</p></div>
                            <div>
                                <p className="text-[11px] sm:text-sm font-medium">More than 0.01 USDT</p>
                            </div>
                        </div>
                        <div className="flex justify-between text-xs sm:text-sm mt-3">
                            <div className="flex items-center gap-1"><p className="text-[11px] sm:text-sm text-gray-500">Wallet Selected</p><IoInformationCircleSharp className="text-gray-400"/></div>
                            <div>
                                <p className="text-[11px] sm:text-sm font-medium">Spot Wallet</p>
                            </div>
                        </div>
                    </div>
                     <p className="text-sm text-gray-500 px-2">
                Make sure the network you choose for the deposit matches the withdrawal network or your assets may be lost.
            </p>
                </div>
            )}
        </>
    );
};

export default DepositAddress;