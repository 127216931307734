import { DeleteOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import { FaPlus } from 'react-icons/fa'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { delbank, getbank } from "store/actions/userSecuritySettingsAction";
import { useDispatch, useSelector } from "react-redux";
import { delpm } from "store/actions/p2pActions";
import { CgLoadbar } from 'react-icons/cg'
import { AiOutlineFileSearch } from 'react-icons/ai';

function PaymentProfile() {
    const paymentMethods = [
        { name: "Bank Transfer" },
        { name: "Cash Deposit" },
        { name: "Paypal" },
        { name: "UPI" },
    ];

    const [selectedMethods, setSelectedMethods] = useState("Bank Transfer");
    const [bankDetails, setBankDetails] = useState(null);
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;
    const dispatch = useDispatch();

    const fetchBankDetails = () => {
        getbank(userId, token, (response) => {
            console.log("API response:", response);
            if (response?.data?.userBankList) {
                setBankDetails(response.data);
                console.log("Updated bankDetails:", response.data);
            } else {
                console.error("API response data missing or empty.");
            }
        });
    };

    useEffect(() => {
        let isMounted = true;

        if (userId && token) {
            getbank(userId, token, (response) => {
                if (isMounted && response?.data) {
                    setBankDetails(response.data);
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [userId, token]);

    const handleMethodChange = (method) => {
        setSelectedMethods(method);
        fetchBankDetails(userId, token);
    };

    const handleDelete = (bankId) => {
        const payload = {
            bank_id: bankId,
        };
        dispatch(delbank(userId, token, payload));
        setTimeout(() => {
            fetchBankDetails(userId, token);
        }, 1000);
    };

    const handleDelet = (methodId) => {
        const payload = {
            id: methodId,
        };
        dispatch(delpm(userId, token, payload));
        setTimeout(() => {
            fetchBankDetails(userId, token);
        }, 1000);
    };
    return (
        <div className="w-full px-0 lg:px-3 mt-10">
            <span className="text-[20px] font-bold">Payment Methods</span>
            <div className="flex flex-col md:flex-col lg:flex-row">
                <div className="w-full lg:w-[75%] md:w-full">
                    <p className="text-[14px] w-full md:w-full lg:w-[75%] text-[#707A8A] pb-10 pt-3">
                        P2P payment methods: When you sell cryptocurrencies, the payment method added will be displayed to buyer
                        as options to accept payment, please ensure that the account owner’s name is consistent with your verified
                        name on Buycex. You can add up to 20 payment methods.
                    </p>
                </div>
                <div className="relative w-full lg:w-1/4">
                    <Link to='/trade/p2p/payment/bank'>
                        <button
                            className="w-full flex items-center gap-2 justify-center bg-[#F1F1F1] rounded-md text-sm h-[45px] px-3 py-3 font-semibold"
                        >
                            <FaPlus /> Add a payment method
                        </button>
                    </Link>
                </div>
            </div>

            <div className="my-4">
                {paymentMethods.map((item) => (
                    <button
                        key={item.name}
                        className={`p-2 px-5 ${selectedMethods === item.name
                            ? "border-b-2 border-[#EFB81C]"
                            : "border-b-2 border-gray-200"
                            }`}
                        onClick={() => handleMethodChange(item.name)}
                    >
                        {item.name}
                    </button>
                ))}
            </div>
            {selectedMethods === "Bank Transfer" &&
                (bankDetails?.userBankList?.length > 0 ? (
                    <div className="mt-3 pt-3">
                        {bankDetails?.userBankList?.map((bank) => (
                            <div
                                key={bank?.id}
                                className={`bg-white border rounded-lg mb-4 flex justify-between items-center ${selectedMethods === bank.id
                                    ? "border-yellow-500 border-2"
                                    : ""
                                    }`}
                            // onClick={() => {
                            //     setSelectedMethods(bank);
                            //     onSelect(bank);
                            // }}
                            >
                                <div className='w-full'>
                                    <div className='flex items-center justify-between w-full rounded-t-lg bg-[#f5f5f5] py-1.5'>
                                        <div className="flex items-center px-5">
                                            <CgLoadbar className="rotate-90 text-[#EFB81C]" />
                                            <p className="font-semibold ml-2">{bank?.bank}</p>
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(bank.id);
                                            }}
                                            className="cursor-pointer px-4"
                                        >
                                            <span className='text-[#EFB81C] text-sm font-semibold'>Delete</span>
                                        </div>
                                    </div>
                                    <div className='px-6 py-4 flex justify-between w-[100%]'>
                                        <div className='flex flex-col gap-4 flex-1'>
                                            <p className='text-[#707A8A]'>Name</p>
                                            <p className="uppercase font-medium text-[16px] text-[#333]">
                                                {bank?.name ||
                                                    "Bank Name Not Available"}
                                            </p>
                                        </div>
                                        <div className='flex flex-col gap-4 flex-1'>
                                            <p className='text-[#707A8A]'>Phone Number</p>
                                            <p className="text-[16px] font-medium text-[#333]">
                                                +{bankDetails?.cellphone || "Phone no. Not Available"}
                                            </p>

                                        </div>
                                        <div className='flex flex-col gap-4 flex-1'>
                                            <p className='text-[#707A8A]'>Account No</p>
                                            <p className="text-[16px] font-medium text-[#333]">
                                                {bank?.bankcard}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="h-52 flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                        <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
                        <p className="mt-3 text-[#474D57]">You have not added any payment methods</p>
                    </div>
                </div> 
                ))}

            {selectedMethods !== "Bank Transfer" && (
                <div className="mt-3 pt-3">
                    {bankDetails?.userPaymedthod
                        ?.filter((method) => {
                            const selectedMethodName =
                                String(selectedMethods).toLowerCase();
                            return (
                                method.method.toLowerCase() ===
                                (selectedMethodName === "cash deposit"
                                    ? "cdm"
                                    : selectedMethodName)
                            );
                        })
                        .map((method) => (
                            <div
                                key={method.id}
                                className={`border rounded-lg mb-4 flex justify-between items-center ${selectedMethods === method.id
                                    ? "border-yellow-500 border-2"
                                    : ""
                                    }`}
                            // onClick={() => {
                            //     setSelectedMethods(method);
                            //     onSelect(method);
                            // }}
                            >
                                <div className='w-full'>
                                    <div className='flex items-center justify-between w-full rounded-t-lg bg-[#f5f5f5] py-1.5'>
                                        <div className="flex items-center px-5">
                                            <CgLoadbar className="rotate-90 text-[#EFB81C]" />
                                            <p className="font-semibold ml-2">
                                                {method.method === "cdm"
                                                    ? "Cash Deposit"
                                                    : method.method === "paypal"
                                                        ? "Paypal"
                                                        : method.method === "upi"
                                                            ? "UPI"
                                                            : method.method}
                                            </p>
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelet(method.id);
                                            }}
                                            className="cursor-pointer px-4"
                                        >
                                            <span className='text-[#EFB81C] text-sm font-semibold'>Delete</span>
                                        </div>
                                    </div>
                                    <div className='px-6 py-4'>
                                        <div className='flex flex-col gap-4 flex-1'>
                                            <p className='text-[#707A8A]'>Account No</p>
                                            <p className="text-[16px] font-medium text-[#333]">
                                                {method.information}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                </div>
            )}
            {bankDetails?.userPaymedthod?.filter((method) => {
                const selectedMethodName = String(selectedMethods).toLowerCase();
                return (
                    method.method.toLowerCase() ===
                    (selectedMethodName === "cash deposit" ? "cdm" : selectedMethodName)
                );
            }).length === 0 && selectedMethods !== "Bank Transfer" && (
                    <div className="h-52 flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                        <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
                        <p className="mt-3 text-[#474D57]">You have not added any payment methods</p>
                    </div>
                </div> 
                )}

        </div>
    )
}

export default PaymentProfile