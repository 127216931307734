import React, { useState } from 'react';
import { FaBitcoin } from 'react-icons/fa';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import SubscribePopup from './subscribePopup';

function EarnProduct() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    
    const showModal = () => {
        setIsPopupOpen(true);
    }

    const handleCancel = () => {
        setIsPopupOpen(false);
    }

    const handleOk = () => {
        setIsPopupOpen(false)
    }

    const products = [
        {
            id: 1,
            icon: <FaBitcoin fill="#f6921a" size={20} />,
            name: 'BTC',
            rate: '12.16%',
            max: 'Max',
            type: 'Flexible',
        },
        {
            id: 2,
            icon: <RiMoneyDollarCircleFill fill="#2775ca" size={20} />,
            name: 'USDC',
            rate: '12.16%',
            max: 'Max',
            type: 'Flexible',
        },
        {
            id: 3,
            icon: <FaBitcoin fill="#ffbf00" size={20} />,
            name: 'BNB',
            rate: '12.16%',
            max: 'Max',
            type: 'Flexible',
        },
        {
            id: 4,
            icon: <RiMoneyDollarCircleFill fill="#31a4d5" size={20} />,
            name: 'USDT',
            rate: '12.16%',
            max: 'Max',
            type: 'Flexible',
        },
    ];

    return (
        <>
            <div className="my-16 container">
                <p className="text-3xl font-semibold">All products</p>
                <p className="font-medium text-[16px] py-4">Simple Earn</p>
                {products.map((product) => (
                    <div
                        key={product.id}
                        className="flex justify-between w-full border-b mt-5 py-2"
                    >
                        <div className="flex flex-1 gap-2 items-center">
                            {product.icon}
                            <p className="font-medium">{product.name}</p>
                        </div>
                        <div className="flex gap-2 flex-1 items-center">
                            <p className="text-[#30bd86] font-medium">{product.rate}</p>
                            <p className="font-medium text-gray-600">{product.max}</p>
                        </div>
                        <div className="flex gap-2 flex-1 items-center">
                            <p className="font-medium text-gray-600">{product.type}</p>
                        </div>
                        <button onClick={showModal} className="bg-[#EFB81C] rounded-md px-4 py-2 font-medium text-sm">
                            Subscribe
                        </button>
                        <SubscribePopup 
                        isPopupOpen={isPopupOpen}
                        onOk={handleCancel}
                        onCancel={handleOk}
                        />
                    </div>
                ))}
            </div>
        </>
    );
}

export default EarnProduct;
