import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast';
import { BiLinkAlt, BiSolidCopy } from 'react-icons/bi'
import { ImNotification } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchWithdrawals } from "store/actions/WithdrawAction";

function CryptoRecentTable({ selectedCoin, TOKEN, ID }) {
    const [withdrawalRecords, setWithdrawalRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const recordsPerPage = 5;

    const { withdrawalHistory, error } = useSelector((state) => state.withdrawalHistory);
    useEffect(() => {
        if (!TOKEN || !ID) {
            history.push("/login");
            return;
        }

        if (TOKEN && ID) {
            setLoading(true);
            dispatch(fetchWithdrawals(TOKEN, ID))
                .then((response) => {

                    if (response.status == 1) {

                        console.log("Response data for the coin",response.data)
                        setWithdrawalRecords(response.data);

                    } else {
                        console.log("No record found")
                    }

                })
                .catch((error) => {
                    console.error("Error fetching withdrawal records:", error);
                })
                .finally(() => setLoading(false));
        }
    }, [TOKEN, ID, dispatch, history]);

    const SkeletonRow = () => (
        <tr>
            <td className="px-4 py-3 text-center">
                <div className="w-4 h-4 m-auto bg-gray-300 rounded-full animate-pulse"></div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="w-24 h-4 m-auto bg-gray-300 rounded animate-pulse"></div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="w-16 h-4 m-auto bg-gray-300 rounded animate-pulse"></div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="w-32 h-4 m-auto bg-gray-300 rounded animate-pulse"></div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="w-40 h-4 m-auto bg-gray-300 rounded animate-pulse"></div>
            </td>
        </tr>
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginatedRecords = withdrawalRecords.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
    );

    const totalPages = Math.ceil(withdrawalRecords.length / recordsPerPage);

    return (
        <section className='w-full md:w-1/2 self-start'>
            <div className="font-semibold text-[22px] mt-5 md:text-xl mb-6">Recent Withdraw</div>
            {loading ? (
                Array.from({ length: recordsPerPage }).map((_, index) => (
                    <SkeletonRow key={index} />
                )
                )
            ) : paginatedRecords.length === 0 ? (
                <tr>
                    <td
                        colSpan="5"
                        className="py-4 text-center"
                    >
                        <div className="flex items-center justify-center gap-2">
                            <div>
                                <ImNotification className="text-black dark:text-white" />
                            </div>
                            <div className="text-black dark:text-white">
                                No Recent Withdraw
                            </div>
                        </div>
                    </td>
                </tr>
            ) : (
                paginatedRecords.map((record, index) => (
                    <div className='py-3' key={index}>
                        <div className="flex items-center gap-2 py-2">
                            <img
                                src="https://api.buycex.com/Upload/coin/65f98a1338aff.png"
                                className="w-5 h-5 mt-1"
                            />
                            <p className="text-md font-medium uppercase">
                                {record.num} {record.coinname}
                            </p>
                            <p>
                                {record.status ===
                                    "1" ? (
                                    <p className="text-xs bg-[#22c55e] bg-opacity-10 text-[#22c55e] font-medium px-2 py-1 rounded-md">Completed</p>
                                ) : (
                                    <p className="text-xs bg-[#Ef4444] bg-opacity-10 text-[#Ef4444] font-medium px-2 py-1 rounded-md">Failed</p>
                                )}
                            </p>
                        </div>
                        <div className="flex justify-between w-full">
                            <div className="flex gap-2">
                                <p className="text-sm text-[#81858c]">Date</p>
                                <p className="text-sm">{new Date(
                                    parseInt(
                                        record.addtime
                                    ) * 1000
                                )
                                    .toISOString()
                                    .slice(0, 19)
                                    .replace("T", " ")}</p>
                            </div>
                            <div className="flex gap-2">
                                <p className="text-sm text-[#81858c]">Network</p>
                                <p className="text-sm uppercase">{record.coinname}</p>
                            </div>
                            <div className="flex gap-2">
                                <p className="text-sm text-[#81858c]">TxID</p>
                                <p className="flex items-center gap-2">
                                    <p className="text-sm">{record.txid?.length > 8
                                        ? record.txid.slice(0, 4) + "..." + record.txid.slice(-4)
                                        : record.txid}</p>
                                    <BiLinkAlt fill="#81858c" />
                                    <BiSolidCopy
                                        fill="#81858c"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            navigator.clipboard.writeText(record.txid);
                                            toast.success("TxID copied to clipboard!");
                                        }}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                ))
            )}

            {/* Pagination Controls */}
            <div className="flex justify-center gap-2 mt-4">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        className={`px-3 py-1 text-sm font-medium rounded-md ${currentPage === index + 1
                                ? "bg-[#EFB81C] text-white"
                                : "bg-gray-200 text-gray-700"
                            }`}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </section>
    )
}

export default CryptoRecentTable