import {
    FETCH_USER_PAY_ID_REQUEST,
    FETCH_USER_PAY_ID_SUCCESS,
    FETCH_USER_PAY_ID_FAILURE,
  } from "../types";
  import { userPayId } from "../../repository/UserPayIdRepository";
  
  export const fetchUserPayId = (TOKEN, ID) => async (dispatch) => {
    dispatch({ type: FETCH_USER_PAY_ID_REQUEST });
  
    try {
      const response = await userPayId(TOKEN, ID);
      dispatch({
        type: FETCH_USER_PAY_ID_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_USER_PAY_ID_FAILURE,
        payload: error.message || "Failed to fetch UserPayId",
      });
    }
  };
  