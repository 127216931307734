import React from 'react'

function MainTable() {
  return (
    <div className='p-3 sm:p-6 rounded-2xl border'>
      <button className="px-2 py-2 rounded-md font-medium text-sm text-ellipsis whitespace-nowrap bg-[#f5f5f5]">Balance</button>
      <div className='flex min-w-[600px] my-8'>
        <div className='flex items-center gap-1 w-1/5'>
          <p className='text-xs text-[#929AA5]'>Token</p>
        </div>
        <div className='flex items-center justify-end gap-1 w-1/5'>
          <p className='text-xs text-[#929AA5]'>Amount</p>
        </div>
        <div className='flex items-center justify-end gap-1 w-1/5'>
          <p className='text-xs text-[#929AA5]'>Price</p>
        </div>
        <div className='flex items-center justify-end gap-1 w-1/5'>
          <p className='text-xs text-[#929AA5]'>Total</p>
        </div>
        <div className='flex justify-end gap-1 w-1/5'>
          <p className='text-xs text-[#929AA5]'>Action</p>
        </div>
      </div>
      <div className='flex min-w-[600px] my-8'>
        <div className='flex items-center gap-1 w-1/5'>
          <p className='text-sm font-semibold'>BCX</p>
        </div>
        <div className='flex items-center justify-end gap-1 w-1/5'>
          <p className='text-sm font-semibold'>25.00002454</p>
        </div>
        <div className='flex items-center justify-end gap-1 w-1/5'>
          <p className='text-sm font-semibold'>10 USDT</p>
        </div>
        <div className='flex items-center justify-end gap-1 w-1/5'>
          <p className='text-sm font-semibold'>25.00002454</p>
        </div>
        <div className='flex justify-end gap-1 w-1/5'>
          <p className='text-sm font-semibold rounded-md bg-[#EFB81C] px-2 py-1'>Convert to USDT</p>
        </div>
      </div>
    </div>
  )
}

export default MainTable