import React, { useState } from "react";
import TotalValue from "./TotalValue";
import MainTable from "./MainTable";

function CryptoDps() {
    const [buttonType, setButtonType] = useState("");
    return (
        <div className="max-w-[1400px] mx-auto !p-0">
            <div className="px-0 lg:px-3">
                <TotalValue onButtonClick={setButtonType} />
                <MainTable buttonType={buttonType} />
            </div>
        </div>
    );
}

export default CryptoDps;
