import bcxTokenRepository from "repository/bcxTokenRepository";

import {
    FETCH_LATEST_PROFIT_REQUEST,
    FETCH_LATEST_PROFIT_SUCCESS,
    FETCH_LATEST_PROFIT_FAILURE,
} from "../types";

export const getLatestProfit = () => async (dispatch) => {
    dispatch({ type: FETCH_LATEST_PROFIT_REQUEST });
    try {
        const response = await bcxTokenRepository.fetchLatestProfit();
        const latestProfit = response.data.data;
        if (response.data.status === 1) {
            dispatch({
                type: FETCH_LATEST_PROFIT_SUCCESS,
                payload: latestProfit,
            });
        } else {
            dispatch({
                type: FETCH_LATEST_PROFIT_FAILURE,
                payload: latestProfit,
            });
        }
    } catch (error) {
        dispatch({ type: FETCH_LATEST_PROFIT_FAILURE, payload: error.message });
    }
};
