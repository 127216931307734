import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { fetchUserProfile } from "store/actions/userProfileAction";
import {
    BsUpload,
    BsSpeedometer2,
    BsEyeFill,
    BsDownload,
    BsEyeSlashFill,
    BsSafe2,
    BsCardChecklist,
    BsGift,
    BsPeople,
    BsLifePreserver,
    BsBoxArrowRight,
    BsPatchCheck,
    BsGem,
} from "react-icons/bs";
import { IoCashSharp, IoSettingsOutline } from "react-icons/io5";

import { FaCopy } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import DefaultUserIcon from "assets/img/user/buycex-default-user-profile-img.png";
import { logout } from "store/actions/authAction";
import { Tooltip } from "react-tooltip";
import toast from "react-hot-toast";
import { FaCircleCheck } from "react-icons/fa6";
import { RiErrorWarningFill } from "react-icons/ri";
import { RiVipDiamondFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { GoHomeFill } from "react-icons/go";
import { ImHistory, ImUsers } from "react-icons/im";
import { GrMoney } from "react-icons/gr";

const UserAssetMenu = () => {
    const history = useHistory();
    const [isAssetsHidden, setIsAssetsHidden] = useState(true);
    const dispatch = useDispatch();

    const menuList = [
        {
            id: 1,
            name: "Home",
            title: "Home",
            icon: <GoHomeFill size={16} />,
            path: "/user/dashboard",
        },
        {
            id: 2,
            name: "Assets",
            title: "Assets",
            icon: <IoCashSharp size={16} />, 
            path: "/user/asset/spot-overview",
        },
        {
            id: 3,
            name: "Earn",
            title: "Earn",
            icon: <BsCardChecklist size={16} />, 
            path: "/user/earn/bcx-token",
        },
        {
            id: 4,
            name: "History",
            title: "History",
            icon: <ImHistory size={16} />, 
            path: "/user/history/spot-history",
        },
        {
            id: 5,
            name: "Referral",
            title: "Referral",
            icon: <ImUsers size={16} />,
            path: "/referral",
        },
        {
            id: 6,
            name: "Help Center",
            title: "Help Center",
            icon: <BsLifePreserver size={16} />,
            path: "/help-center-list",
        },
        {
            id: 7,
            name: "Reward Hub",
            title: "Reward Hub",
            icon: <GrMoney size={16} />,
            path: "/user/account/rewards-hub",
        },
        {
            id: 8,
            name: "Settings",
            title: "Help Center",
            icon: <IoSettingsOutline size={16} />,
            path: "/user/settings",
        },
    ];
    const { userProfile } = useSelector((state) => state.userInfo);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const token = useSelector((state) => state.authUser?.user?.TOKEN);
    const username = userProfile?.username;

    useEffect(() => {
        if (userID && token) {
            dispatch(fetchUserProfile(userID, token));
        }
    }, [dispatch, userID, token]);

    // User for verified or not
    const notVerified =
        !userProfile?.idcardimg1 ||
        !userProfile?.idcardimg2 ||
        !userProfile?.idcardinfo;

    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.dismiss("success-toast");
            toast.success("UID Copied to Clipboard", { id: "success-toast" });
        } catch (err) {
            toast.dismiss("error-toast");
            toast.error("Failed to copy UID: ", err, { id: "error-toast" });
        }
    };

    const redirectDeposit = () => {
        history.push("/crypto/deposit");
    };

    const redirectWithdraw = () => {
        history.push("/crypto/withdraw");
    };

    const redirectAssetsOverview = () => {
        history.push("/user/assets/spot");
    };

    const toggleAssetsVisibility = () => {
        setIsAssetsHidden(!isAssetsHidden);
    };
    const handleLogout = () => {
        dispatch(logout());
        history.push("/login");
    };

    const redirectAccountOverview = () => {
        history.push("/user/account/overview");
    };

    const maskedUsername = username
        ? `${username.slice(0, 5)}***${username.slice(-2)}`
        : "";

    return (
        <div className="header__user-assets-menu-wrapper rounded-b-md text-white bg-[#17181e] absolute top-[34px] right-[0px] w-[250px] h-max z-[999]">
            <div className="w-full h-max flex flex-col">
                <div className="flex flex-col">
                    {/* User Profile and Details */}
                    <div className="flex gap-3 items-center px-3 pb-2 relative">
                        <button
                            type="button"
                            onClick={redirectAccountOverview}
                            className="w-max overflow-hidden rounded-full max-w-12 max-h-12"
                        >
                            {/* <img
                                src={DefaultUserIcon}
                                alt="User icon"
                                className="w-full h-auto rounded-full"
                                loading="lazy"
                            /> */}
                        </button>
                        {/* User details => username and UID */}
                        <div className="user-side-menu__user-status-wrapper flex flex-col gap-0.5 w-full">
                            <button
                                type="button"
                                onClick={redirectAccountOverview}
                                className="text-[18px] font-bold w-max flex items-center gap-2"
                            >
                                <span className="w-full text-lg mt-2 user-status-wrapper__username capitalize">
                                    {maskedUsername}
                                </span>
                            </button>
                            <div className="flex mt-4 gap-3">
                            <span
                                    className={`user-status-wrapper__verification-checker `}
                                    data-tooltip-id="verification-status"
                                    data-tooltip-place="top"
                                    data-tooltip-content={
                                        notVerified
                                            ? "Unverified"
                                            : "Verified User"
                                    }
                                >
                                    {notVerified ? (
                                        <div className="flex items-center gap-1 bg-[#383a3d] rounded-full px-2 py-0.5"><RiErrorWarningFill  fill="#FF0000" size={16} /><span className="text-xs font-medium !text-[#FF0000]">Unverified</span></div>
                                    ) : (
                                        <div className="flex items-center gap-1 bg-[#383a3d] rounded-full px-2 py-0.5"><FaCircleCheck
                                            size={16}
                                            fill="#03a66d"
                                        /><span className="text-xs font-medium !text-[#03a66d]">Verified User</span></div>
                                    )}
                                </span>
                                <Tooltip id="verification-status" />
                                <div className="flex items-center gap-1 bg-[#383a3d] rounded-full px-2 py-0.5">
                                <RiVipDiamondFill className="text-[#EFB81C]"/>
                                <span>Regular User</span>
                                </div>
                            </div>
                            <div className="flex mt-1 items-center gap-1 w-1/2 bg-[#383a3d] rounded-full px-2 py-0.5">
                                <FaXTwitter/>
                                <span>Link Twitter</span>
                                </div>
                            {/* <div className="flex items-center justify-between w-full">
                                <p className="user-status-wrapper__uid flex gap-1.5 items-center ] w-max">
                                    <span
                                        style={{
                                            opacity: "0.6",
                                            fontSize: "12px",
                                        }}
                                    >
                                        UID: {userID}
                                    </span>
                                    <button
                                        type="button"
                                        onClick={() => handleCopy(userID)}
                                    >
                                        <FaCopy className="cursor-pointer text-[12px]" />
                                    </button>
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* User Assets Navigation Menu */}
                <div className="user-side-menu__menu-wrapper">
                    <ul className="user-side-menu__menu-list flex flex-col gap-0 border-gray-600 border-b-[0.5px] px-3 py-2">
                        {menuList.map((data) => (
                            <li
                                key={data.id}
                                className="flex items-center gap-3"
                            >
                                <Link
                                    to={data.path}
                                    title={data.title}
                                    rel="alternate noopener noreferrer"
                                    className="w-full hover:bg-[#383a3d] hover:text-[#EFB81C] flex items-center gap-3 px-3 py-[13px] rounded-md opacity-80 hover:opacity-100"
                                >
                                    <span>{data.icon}</span>
                                    <span className="font-semibold text-[14px]">
                                        {data.name}
                                    </span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <ul className="user-side-menu__menu-list">
                        <li className="menu-list__item rounded-md px-3 flex items-center gap-3">
                            <button
                                type="button"
                                onClick={handleLogout}
                                className="menu-list__logout-btn text-md text-white w-full flex items-center gap-2 px-3 py-[13px] rounded-md border border-transparent opacity-80 hover:opacity-100"
                            >
                                <span>
                                    <BsBoxArrowRight />
                                </span>
                                <span className="menu-item__list-name font-bold text-[14px]">
                                    Logout
                                </span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default UserAssetMenu;
