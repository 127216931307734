import React, { useState, useEffect } from "react";
import Image from "../../../assets/img/earn/usdt.png";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
    accountsummary,
    fetchEarnStats,
    saveuserproduct,
} from "store/actions/EarnStaking";

const SingleRowItem = ({ item }) => {
    const userid = useSelector((state) => state.authUser?.user?.ID);
    const token = useSelector((state) => state.authUser?.user?.TOKEN);
    const dispatch = useDispatch();

    const parseJSONSafely = (jsonString) => {
        try {
            return JSON.parse(jsonString);
        } catch {
            return [];
        }
    };

    const parseDuration = (duration) => {
        try {
            if (duration.startsWith("[") && duration.endsWith("]")) {
                return JSON.parse(duration);
            }
            if (duration.startsWith('"[') && duration.endsWith(']"')) {
                return JSON.parse(JSON.parse(duration));
            }
            return [JSON.parse(duration)];
        } catch {
            return [];
        }
    };

    const monthlyDeposits = parseJSONSafely(item.monthly_deposit);
    const weeklyDeposits = parseJSONSafely(item.weekly_deposit);
    const durations = parseDuration(item.duration);

    const [selectedMonthlyDeposit, setSelectedMonthlyDeposit] = useState(0);
    const [selectedWeeklyDeposit, setSelectedWeeklyDeposit] = useState(10);
    const [selectedDuration, setSelectedDuration] = useState(1);
    const [calculatedAmount, setCalculatedAmount] = useState(0);

    useEffect(() => {
        const totalWeeksInYear = 52;
        const totalMonthsInYear = 12;

        const amount =
            selectedWeeklyDeposit > 0
                ? selectedDuration * totalWeeksInYear * selectedWeeklyDeposit
                : selectedDuration * totalMonthsInYear * selectedMonthlyDeposit;

        setCalculatedAmount(amount);
    }, [selectedDuration, selectedWeeklyDeposit, selectedMonthlyDeposit]);

    const handleSubmit = async () => {
        if (
            (selectedMonthlyDeposit > 0 && selectedWeeklyDeposit > 0) ||
            (selectedMonthlyDeposit === 0 && selectedWeeklyDeposit === 0)
        ) {
            toast.error(
                "Please select either a monthly deposit or a weekly deposit!"
            );
            return;
        }

        if (selectedDuration === "0") {
            toast.error("Please select duration time period!");
            return;
        }

        const payload = {
            product_id: item.id,
            coin: item.coin,
            duration: selectedDuration,
            weekly_deposit: selectedWeeklyDeposit,
            monthly_deposit: selectedMonthlyDeposit,
            amount: calculatedAmount,
            auto_deduct: 1,
        };

        try {
            const response = await dispatch(
                saveuserproduct(userid, token, payload)
            );

            if (response?.status === 200) {
                await dispatch(fetchEarnStats(userid, token));
                await dispatch(accountsummary(userid, token));

                setSelectedMonthlyDeposit(0);
                setSelectedWeeklyDeposit(10);
                setSelectedDuration(1);
            } else {
                console.error(
                    "Error in saveuserproduct:",
                    response?.data?.message
                );
            }
        } catch (error) {
            console.error("Error during subscription:", error);
        }
    };

    return (
        <div
            key={item.id}
            className="p-3 px-4 min-h-[56px] py-4 border-b-[1px] border-solid border-[#f3f5f7]"
        >
            <div className="h-[56px] flex items-center justify-between gap-4 cursor-pointer">
                <div className="flex-1 flex items-center gap-[10px]">
                    <img
                        src={Image}
                        alt={item.coin}
                        className="w-[28px] h-[28px]"
                    />
                    <p className="text-[16px] text-[#121214] font-medium">
                        {item.coin.toUpperCase()}
                    </p>
                </div>
                <div className="flex-1 flex items-center text-[16px] text-[#20b26c] font-medium">
                    <select
                        name="monthly_deposit"
                        className="w-16 cursor-pointer"
                        value={selectedMonthlyDeposit}
                        onChange={(e) =>
                            setSelectedMonthlyDeposit(Number(e.target.value))
                        }
                    >
                        <option value={0}>0</option>
                        {monthlyDeposits.map((deposit, index) => (
                            <option key={index} value={deposit}>
                                {deposit}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex-1 flex items-center text-[16px] text-[#20b26c] font-medium">
                    <select
                        name="weekly_deposit"
                        className="w-16 cursor-pointer"
                        value={selectedWeeklyDeposit}
                        onChange={(e) =>
                            setSelectedWeeklyDeposit(Number(e.target.value))
                        }
                    >
                        <option value={0}>0</option>
                        {weeklyDeposits.map((deposit, index) => (
                            <option key={index} value={deposit}>
                                {deposit}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex-1 flex items-center text-[16px] text-[#121214] font-medium">
                    <select
                        name="duration"
                        className="w-24 cursor-pointer"
                        value={selectedDuration}
                        onChange={(e) =>
                            setSelectedDuration(Number(e.target.value))
                        }
                    >
                        <option value={0}>0</option>
                        {durations.map((duration, index) => (
                            <option key={index} value={duration}>
                                {duration} Year
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex-1 flex items-center text-[16px] text-[#121214] font-medium">
                    {calculatedAmount} {item.coin.toUpperCase()}
                </div>
                <div className="flex-1 items-center flex text-center justify-end">
                    <button
                        className="bg-[#EFB81C] flex items-center justify-center rounded-[8px] text-[12px] w-[100px] h-[32px] text-center text-[#000] font-semibold"
                        onClick={handleSubmit}
                    >
                        Subscribe
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SingleRowItem;
